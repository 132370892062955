import { createAction } from 'redux-actions';

import { getJobDetails, decorateJobTarget } from 'core/api/jobs';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receiveJobTargets = createAction('RECEIVE_JOB_TARGETS');
export const receiveJobDetails = createAction('RECEIVE_JOB_DETAILS');
export const requestJobDetails = createAction('REQUEST_JOB_DETAILS_ERRORS');
export const setFetchingJobDetails = createAction('SET_FETCHING_JOB_DETAILS');
export const handleError = createAction('HANDLE_ERROR');

export const fetchJobDetails = (jobInfo) => {
  return async (dispatch) => {
    dispatch(requestJobDetails());
    dispatch(setFetchingJobDetails());
    try {
      const { response } = await getJobDetails(jobInfo);
      const { targets, ...jobDetail } = response.message;
      if (jobDetail) {
        dispatch(receiveJobDetails(jobDetail));
      }

      if (targets) {
        dispatch(
          receiveJobTargets(
            targets.map((jobTarget) =>
              decorateJobTarget({
                jobTargetFromAPI: jobTarget,
              })
            )
          )
        );
      }
      return true;
    } catch (e) {
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_JOB_DETAILS, e),
        })
      );
      console.log(e);
      return false;
    }
  };
};
