import React, { useState, useEffect } from 'react';
import Modal from 'shared/styles/components/Modal';
import { connect } from 'react-redux';
import { addNotification } from 'core/redux/ui/actions';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { CardLabel, CardValue, CardRow } from 'shared/styles/components/JobDetailsCard';
import { Container, Title, CloseButton, ModalText } from 'shared/styles/components/Modal';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { ButtonWithSpinnerContainer } from 'shared/styles/components/Button';
import { ButtonSpinnerSmall } from 'shared/styles/components/Spinner';
import { getVsgInfoApi } from 'core/api/deviceOperations';
import { getFormattedDuid } from 'shared/utilities/general';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { GPS_STATUS, CORRECT_GPS_MODE, GPS_WORKING, GPS_UNKNOWN } from 'shared/constants/matrixOptions';
// use to show Popup for Account and Gps detail
export const ShowVsgInfo = ({ width, closeModal, vsgModalDetails }) => {
  const [loading, setLoading] = useState(true);
  const [gpsDetail, setgpsDetail] = useState('');
  const [accountDetail, setaccountDetail] = useState('');
  const handleCloseModal = () => {
    closeModal(false);
  };
  // get records on page load
  useEffect(async () => {
    setLoading(true);
    try {
      const { response } = await getVsgInfoApi(
        vsgModalDetails?.selectedAccount,
        vsgModalDetails?.selectedStack,
        vsgModalDetails?.deviceId,
        vsgModalDetails?.vehicleId,
        vsgModalDetails?.orgId
      );
      if (response.message) {
        let res = response.message;
        let accountDetail = res?.simInfo?.accountName ? res?.simInfo?.accountName : '-';
        let gpsDetail =
          res?.vehicleGpsDetail === null || res?.vehicleGpsDetail?.gpsMode === ''
            ? GPS_UNKNOWN
            : res?.vehicleGpsDetail?.gpsMode === CORRECT_GPS_MODE
            ? GPS_WORKING
            : GPS_STATUS;
        setgpsDetail(gpsDetail);
        setaccountDetail(accountDetail);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      showNotification(e);
    }
  }, []);
  return (
    <Modal>
      <Container autoHeight maxWidth={width ? width : 100}>
        <CloseButton
          onClick={() => {
            closeModal(false);
          }}
        >
          <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
        </CloseButton>
        <Title style={styles.title}>Account and GPS Detail</Title>
        <ModalText border>
          <>
            {!loading ? (
              <>
                {' '}
                <CardRow>
                  <CardLabel vsgLabel>
                    <span>VSG Registration Id</span>
                  </CardLabel>
                  <CardValue>{getFormattedDuid(vsgModalDetails?.deviceId)}</CardValue>
                </CardRow>
                <CardRow>
                  <CardLabel vsgLabel>
                    <span>Account Name</span>
                  </CardLabel>
                  <CardValue>{accountDetail}</CardValue>
                </CardRow>
                <CardRow>
                  <CardLabel vsgLabel>
                    <span>GPS Status</span>
                  </CardLabel>
                  <CardValue>{gpsDetail}</CardValue>
                </CardRow>
              </>
            ) : (
              <ButtonWithSpinnerContainer widthFull>
                <ButtonSpinnerSmall />
              </ButtonWithSpinnerContainer>
            )}{' '}
          </>
        </ModalText>
      </Container>
      <div style={styles.overlay} onClick={handleCloseModal}></div>
    </Modal>
  );
};

const vsgDetail = ({ width, closeModal, VsgModalDetails }) => {
  return <ShowVsgInfo width={width} closeModal={closeModal} vsgModalDetails={VsgModalDetails} />;
};

const styles = {
  title: {
    marginBottom: '20px',
    wordBreak: 'break-all',
  },
  icon: {
    marginRight: '10px',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 0.2,
    backgroundColor: '#000000',
    overflow: 'auto',
  },
};

export default connect((dispatch) => ({
  showNotification: (e) =>
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ACCOUNT_GPS, e),
      })
    ),
}))(vsgDetail);
