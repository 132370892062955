import { handleActions } from 'redux-actions';
import {
  receiveRulesExecuted,
  receiveFailedJobExecutionTotalCount,
  requestRulesExecuted,
  requestFailedJobExecutionTotalCount,
  handleError,
} from './actions';

const defaultState = {
  rulesExecuted: {},
  failedJobExecutionTotalCount: {},
  isError: false,
};

const iotMatrix = handleActions(
  {
    [receiveRulesExecuted]: (state, { payload: rulesExecuted }) => ({
      ...state,
      rulesExecuted: rulesExecuted,
    }),
    [receiveFailedJobExecutionTotalCount]: (state, { payload: failedJobExecutionTotalCount }) => ({
      ...state,
      failedJobExecutionTotalCount: failedJobExecutionTotalCount,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [requestRulesExecuted]: (state) => ({
      ...state,
      rulesExecuted: {},
      isError: false,
    }),
    [requestFailedJobExecutionTotalCount]: (state) => ({
      ...state,
      failedJobExecutionTotalCount: {},
      isError: false,
    }),
  },
  defaultState
);

export default iotMatrix;
