/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependencies
 */
import colors from 'shared/constants/colors';

export default styled.table`
  font-size: 14px;
  color: #8a909c;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .checkbox {
    margin-right: 16px;
  }

  tr {
    &:last-child td {
      border-bottom: 0 none;
    }

    &:hover {
      background: none;
    }
  }

  th {
    background: none;
    padding: 0 0 7px 0;
    border-bottom: 1px solid rgba(138, 144, 156, 0.15);
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: #8a909c;
  }

  td {
    padding: 31px 0 25px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.15);

    .svg-icon {
      margin-right: 12px;
    }

    &.name {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.midnight};
    }

    span.success {
      color: ${colors.midnight};
    }

    span.danger {
      color: ${colors.cherry};
    }
  }

  .with-icon {
    .svg-icon {
      margin-right: 0;
      margin-left: 8px;
    }

    &.warning {
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

export const SortArrowSpan = styled.span`
  margin-left: 10px;
  opacity: 0;
  ${(props) =>
    props.isSorted &&
    `
    opacity: 1;
	`};
`;
