import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import useAPIData from 'shared/hooks/useAPIData';

import PageListWrapper from 'shared/styles/components/PageList';
import SerializedDevices from './serializedDeviceList';
import { clearAndRestoreSerial } from 'core/redux/serializedDevice/action';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { setPageSize } from 'core/redux/ui/actions';

const serializedDevices = ({ serialDevices, isFetchingSerialDevices, clearAndRestoreSerial }) => {
  const dispatch = useDispatch();
  const serialLoaded = useAPIData({
    fetchAPIData: () => clearAndRestoreSerial(),
    dependencies: [],
  });

  // reset page size on route change
  useEffect(() => {
    return () => {
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
    };
  }, []);

  return (
    <PageListWrapper>
      <SerializedDevices
        serialDevices={serialDevices}
        isFetchingSerialDevices={isFetchingSerialDevices}
        clearAndRestoreSerial={clearAndRestoreSerial}
      />

      {!serialLoaded ? <LoadingOverlay /> : null}
    </PageListWrapper>
  );
};

export default connect(
  (state) => ({
    serialDevices: state.serialDevice.serialDevices,
    isFetchingSerialDevices: state.serialDevice.isFetchingSerialDevices,
    isError: state.serialDevice.isError,
  }),
  (dispatch) => ({
    clearAndRestoreSerial: () => dispatch(clearAndRestoreSerial()),
  })
)(serializedDevices);
