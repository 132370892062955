/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependencies
 */
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import { hexWithOpacity } from 'shared/utilities/colors';

const { haze, sky, blueberry, cotton, cherry } = colors;

export default styled.button`
  display: inline-block;
  padding: 10px 31px;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${blueberry};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  color: ${blueberry};
  background: ${cotton};
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  min-width: 128px;
  margin-right: 10px;
  &:hover {
    background: ${blueberry};
    color: #fff;
  }

  ${(props) =>
    props.withRightSpacer &&
    `
		margin-right: 15px;
  `}

  ${(props) =>
    props.center &&
    `
    display: block;
    margin: 0px auto 30px auto;
    text-align: center;
		width: 180px;
  `}

	${(props) =>
    props.redLight &&
    `
		border-color: ${hexWithOpacity(blueberry, 0.5)};
		color: ${hexWithOpacity(blueberry, 0.5)};
	`}

	${(props) =>
    props.medium &&
    `
		padding: 10px 31px;
	`}

	${(props) =>
    props.mediumAlt &&
    `
		min-width: 128px;
		padding: 10px 31px;
		text-align: center;
	`}

${(props) =>
    props.refresh &&
    `
		min-width: 128px;
		padding: 10px 31px;
    text-align: center;
    font-size: 11px;
	`}
  
	${(props) =>
    props.default &&
    `
		border-radius: 21px;
		border: 1px solid ${hexWithOpacity(haze, 0.3)};
		font-size: 12px;
		font-weight: 800;
		color: ${haze};

		&:hover {
			background: ${haze};
    }
  `}
  
  ${(props) =>
    props.submittingWithSpinner &&
    `
    background: ${blueberry};
    color: ${blueberry};
    cursor: not-allowed;
    text-align: center;
    padding: 10px 31px;
    min-width: 128px;
    font-size: 11px;
    &:hover {
			color: ${blueberry};
    }
  `}
  
  ${(props) =>
    props.loginButton &&
    `
    width: 340px;
    padding: 20px 15px;
    border: none;
    border-radius: 30px;
    background: ${colors.midnight};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: ${cotton};
    transition: background 0.3s;

    @media ${device.large} {
      width: 190px;
      padding: 9px 15px;
      border-radius: 17px;
      font-size: 11px;
      border: none;
    }

    &:hover {
      background: ${sky};
    }
  `}

  ${(props) =>
    props.loginWithSpinner &&
    `
    width: 340px;
    border-radius: 30px;
    background: ${blueberry};
    color: ${blueberry};
    cursor: not-allowed;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 15px;
    border: none;

    @media ${device.large} {
      width: 190px;
      padding: 9px 15px;
      border-radius: 17px;
      font-size: 11px;
      border: none;
    }
    
    &:hover {
      color: ${blueberry};
    `}
    ${(props) =>
    props.submittingWithSpinnerModal &&
    `
    background: ${blueberry};
    color: ${blueberry};
    cursor: not-allowed;
    text-align: center;
    padding: 10px 31px;
    min-width: 180px;
    font-size: 12px;
    &:hover {
      color: ${blueberry};
    }
  `}
  ${(props) =>
    props.formConfirm &&
    `
    padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${blueberry};
    border-color: ${blueberry};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;
    min-width: 180px;

    &:hover {
      background-color: ${blueberry};
      color: white;
    }
  `}
`;
export const ButtonWithSpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  ${(props) =>
    props.widthFull &&
    `
    width: 100%;
    height: 63.78px;
  `}
`;

export const LoadingText = styled.div`
  font-size: 12px;
  margin-right: 10px;
`;

export const ActionButtonText = styled.span`
  text-decoration: none;
`;

export const TextButton = styled.button`
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  height: 20px;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.midnight};

  @media ${device.large} {
    font-size: 11px;
  }

  &:hover {
    color: ${colors.charcoal};
    cursor: pointer;
  }
`;

export const HeaderButtonDiv = styled.div`
  display: flex;
  ${(props) =>
    props.cancel &&
    `
    width: 128px;
      
  `}
`;

export const ReportDropdown = styled.div`
  .custom-dropdown {
    display: inline-block;
    text-decoration: none;
    border-radius: 21px;
    border: 2px solid ${blueberry};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.59px;
    color: ${blueberry};
    background: ${cotton};
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    min-width: 128px;
    margin-left: 10px;
    &:hover {
      background: ${blueberry};
      color: #fff;
      border: 2px solid ${blueberry};
    }
    &:active {
      border: 2px solid ${blueberry};
      background: ${blueberry};
    }
    &::after {
      margin-left: 10px;
    }
    &:active:focus-visible {
      box-shadow: unset;
    }
  }
  .dropdown-menu {
    transform: translate3d(10px, 42px, 0px) !important;
  }
  .dropdown-item {
    padding: 10px 15px;
    display: flex;
    .d-icon {
      margin-right: 10px;
    }
    &:active {
      background: gray;
      color: #fff;
      .d-icon path {
        stroke: #fff;
      }
    }
  }
`;

export const Button = styled.button`
  position: relative;
  display: inline-block;
  padding: 10px 31px;
  border: none;
  border-radius: 21px;
  cursor: pointer;
  background-color: ${blueberry};
  color: ${cotton};
  font-weight: 800;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  ${(props) =>
    props.formConfirm &&
    `
		padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${blueberry};
    border-color: ${blueberry};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;

    &:hover {
      background-color: ${blueberry};
      color: white;
    }
    `}

  ${(props) =>
    props.formCancel &&
    `
		padding: 10px 31px;
    background-color: #FFF;
    font-size: 12px;
    color: ${haze};
    border-color: ${haze};
    border-width: 2px;
    border-style: solid;
    transition: background .3s, color .3s;

    &:hover {
      background-color: ${haze};
      color: ${cotton};
    }
    `};

  ${(props) =>
    props.exitButton &&
    `
    padding: 8px 20px;
    margin-right:10px;
    font-size: 12px;
    color: ${cotton};
    background-color: ${cherry};
    display:none;
    `}
  ${(props) =>
    props.exitButtonIsVisible &&
    `
    display:block;
    `}
`;

export const ButtonLink = styled.button`
  display: inline-block;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${blueberry};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  color: ${blueberry};
  background: ${cotton};
  cursor: pointer;
  transition: background 0.3s ease 0s, color 0.3s ease 0s;
  margin-right: 10px;
  min-width: 128px;
  padding: 10px 31px;
  text-align: center;
  font-size: 11px;
  .link {
    text-decoration: none;
  }
  &:hover {
    background: ${blueberry};
    color: #fff;
    border: 2px solid ${blueberry};
  }
  &:active {
    border: 2px solid ${blueberry};
    background: ${blueberry};
  }
`;
