import styled, { css } from 'styled-components';
import colors from 'shared/constants/colors';

export const Badge = styled.div`
  box-sizing: border-box;
  line-height: 15px;
  height: 20px;
  width: 80px;
  color: ${colors.haze};
  border: 2px solid ${colors.haze};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;

  ${(props) =>
    props.active &&
    `
    color: ${colors.cotton};
    background: ${colors.midnight};
    border: 2px solid ${colors.midnight};
  `}

  ${(props) =>
    props.unavailable &&
    `
    color: ${colors.cotton};
    background: ${colors.haze};
    border: 2px solid ${colors.haze};
  `}

${(props) =>
    props.na &&
    `
    color: ${colors.cotton};
    background: ${colors.midnight};
    border: 2px solid ${colors.midnight};
  `}

${(props) =>
    props.maintenanceDue &&
    `
    color: ${colors.cotton};
    background: ${colors.haze};
    border: 2px solid ${colors.haze};
    width: 100px;
  `}

  ${(props) =>
    props.checkEngine &&
    `
    color: ${colors.cotton};
    background: ${colors.cherry};
    border: 2px solid ${colors.cherry};
    text-transform: uppercase;
    font-weight:700;
    width: 100px;
  `}

${(props) =>
    props.checkEngineSuppressed &&
    `
    color: ${colors.cotton};
    background: ${colors.midnight};
    border: 2px solid ${colors.midnight};
    text-transform: uppercase;
    font-weight:700;
    width: 100px;
  `}
`;

export default Badge;
