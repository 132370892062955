import React, { useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';

import ConfirmCloseModal from 'shared/modals/ConfirmCloseModal';

export default ({ children, onRequestClose }) => {
  const modalPortal = useRef();
  const [requestCloseConfirmation, setRequestCloseConfirmation] = useState(false);
  const [closeConfirmationMessage, setCloseConfirmationMessage] = useState('');
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const handleRequestClose = useCallback(
    (e) => {
      if (requestCloseConfirmation) {
        setConfirmationModalActive(true);
        return null;
      }
      onRequestClose();
    },
    [requestCloseConfirmation]
  );
  const handleRequestCloseConfirmation = useCallback((e) => setConfirmationModalActive(false));

  return createPortal(
    <div ref={modalPortal} style={styles.portal}>
      {[
        confirmationModalActive ? (
          <ConfirmCloseModal
            message={closeConfirmationMessage}
            onRequestClose={handleRequestCloseConfirmation}
            onConfirm={onRequestClose}
          />
        ) : null,
        React.cloneElement(children, {
          handleRequestClose,
          setRequestCloseConfirmation,
          setCloseConfirmationMessage,
        }),
        <div
          style={
            confirmationModalActive
              ? {
                  ...styles.overlay,
                  zIndex: 2,
                }
              : styles.overlay
          }
          onClick={handleRequestClose}
          key=""
        />,
      ]}
    </div>,
    document.body
  );
};

const styles = {
  portal: {
    display: 'flex',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 99999,
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 0.2,
    backgroundColor: '#000000',
    overflow: 'auto',
  },
};
