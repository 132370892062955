import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

import { getLoginRedirectUrl } from 'shared/utilities/url';

const LoginForm = ({ loggingIn }) => {
  useEffect(() => {
    if (!loggingIn) {
      submitHandler();
    }
  }, []);

  const submitHandler = () => {
    window.location.href = getLoginRedirectUrl();
  };

  return loggingIn ? <LoadingOverlay /> : <>{submitHandler()}</>;
};

const Login = connect((state) => ({
  loggingIn: state.user.loggingIn,
}))(LoginForm);

export default Login;
