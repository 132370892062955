import { createAction } from 'redux-actions';

import {
  getDynamoSystemErrors,
  getDynamoUserErrors,
  getConsumedReadCapacityUnits,
  getConsumedWriteCapacityUnits,
  getThrottledRequests,
} from 'core/api/dynamoMatrix';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receiveSystemErrors = createAction('RECEIVE_SYSTEM_ERRORS');
export const receiveUserErrors = createAction('RECEIVE_USER_ERRORS');
export const receiveReadCapacityUnits = createAction('RECEIVE_READ_CAPACITY_UNITS');
export const receiveWriteCapacityUnits = createAction('RECEIVE_WRITE_CAPACITY_UNITS');
export const receiveThrottledRequests = createAction('RECEIVE_THROTTLED_REQUESTS');

export const requestSystemErrors = createAction('REQUEST_SYSTEM_ERRORS');
export const requestUserErrors = createAction('REQUEST_USER_ERRORS');
export const requestReadCapacityUnits = createAction('REQUEST_READ_CAPACITY_UNITS');
export const requestWriteCapacityUnits = createAction('REQUEST_WRITE_CAPACITY_UNITS');
export const requestThrottledRequests = createAction('REQUEST_THROTTLED_REQUESTS');
export const handleError = createAction('HANDLE_ERROR');
export const setStackSelection = createAction('DYNAMO_STACK_SELECTION');
export const setStackAccount = createAction('DYNAMO_STACK_ACCOUNT');
export const resetOptions = createAction('RESET_OPTIONS');

// to fetch the dynamo db system error base 64 data
export const fetchDynamoSystemErrors = (params) => {
  return async (dispatch) => {
    dispatch(requestSystemErrors());
    try {
      const { response } = await getDynamoSystemErrors(params);
      dispatch(receiveSystemErrors(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DYNAMO_SYSTEM_ERRORS, e),
        })
      );
      return false;
    }
  };
};

// to fetch the dynamo db user error base 64 data
export const fetchDynamoUserErrors = (params) => {
  return async (dispatch) => {
    dispatch(requestUserErrors());
    try {
      const { response } = await getDynamoUserErrors(params);
      dispatch(receiveUserErrors(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DYNAMO_USER_ERRORS, e),
        })
      );
      return false;
    }
  };
};

// to fetch the read capacity base 64 data
export const fetchReadCapacityUnits = (params) => {
  return async (dispatch) => {
    dispatch(requestReadCapacityUnits());
    try {
      const { response } = await getConsumedReadCapacityUnits(params);
      dispatch(receiveReadCapacityUnits(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_READ_CAP_UNITS, e),
        })
      );
      return false;
    }
  };
};

// to fetch the write capacity base 64 data
export const fetchWriteCapacityUnits = (params) => {
  return async (dispatch) => {
    dispatch(requestWriteCapacityUnits());
    try {
      const { response } = await getConsumedWriteCapacityUnits(params);
      dispatch(receiveWriteCapacityUnits(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_WRITE_CAP_UNITS, e),
        })
      );
      return false;
    }
  };
};

// to fetch the throttled request base 64 data
export const fetchThrottledRequests = (params) => {
  return async (dispatch) => {
    dispatch(requestWriteCapacityUnits());
    try {
      const { response } = await getThrottledRequests(params);
      dispatch(receiveThrottledRequests(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_THROTTLED_REQ, e),
        })
      );
      return false;
    }
  };
};
// set state when selecting Stack
export const stackSelection = (selection) => {
  return async (dispatch) => {
    return dispatch(setStackSelection(selection));
  };
};

// set state when selecting Account
export const stackAccount = (selection) => {
  return async (dispatch) => {
    dispatch(setStackAccount(selection));
  };
};

// reset states to its default on changing page.
export const resetEnvStackOptions = () => {
  return async (dispatch) => {
    dispatch(resetOptions());
  };
};
