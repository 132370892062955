/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const CardLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  color: ${colors.haze};
  min-width: max-content;
  ${(props) =>
    props.orgLabel &&
    `
    &::after{
        content: ':'
    }
    span{
      min-width: 94px;
      display: inline-block;
    }
	`};
  ${(props) =>
    props.vsgLabel &&
    `
    &::after{
        content: ':'
    }
    span{
      min-width: 125px;
      display: inline-block;
    }
	`};
`;
export const CardValue = styled.label`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.haze};
  word-break: break-all;
`;

export const CardRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  ${(props) =>
    props.header &&
    `
		margin-bottom:0;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;
