import { apiCall } from 'core/api/API';
import {
  systemErrors,
  userErrors,
  consumedReadCapacityUnits,
  consumedWriteCapacityUnits,
  throttledRequests,
} from 'shared/constants/api';

export const getDynamoSystemErrors = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: systemErrors,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getDynamoUserErrors = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userErrors,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getConsumedReadCapacityUnits = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: consumedReadCapacityUnits,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getConsumedWriteCapacityUnits = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: consumedWriteCapacityUnits,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getThrottledRequests = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: throttledRequests,
    queryStringParameters: params,
    method: 'get',
  });
};
