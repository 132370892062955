import { handleActions } from 'redux-actions';
import {
  receiveInvocations,
  receiveDuration,
  receiveThrottles,
  receiveErrors,
  requestInvocations,
  requestDuration,
  requestThrottles,
  requestErrors,
  handleError,
  setStackAccount,
  setStackSelection,
  resetOptions,
} from './actions';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

const defaultState = {
  invocations: {},
  duration: {},
  throttles: {},
  errors: {},
  isError: false,
  stackSelection: STACK_DEFAULT_OPTION.dev,
  selectedAccount: ENV_DEFAULT_OPTION,
};

const lambdaMatrix = handleActions(
  {
    [receiveInvocations]: (state, { payload: invocations }) => ({
      ...state,
      invocations: invocations,
    }),
    [receiveDuration]: (state, { payload: duration }) => ({
      ...state,
      duration: duration,
    }),
    [receiveThrottles]: (state, { payload: throttles }) => ({
      ...state,
      throttles: throttles,
    }),
    [receiveErrors]: (state, { payload: errors }) => ({
      ...state,
      errors: errors,
    }),
    [requestInvocations]: (state) => ({
      ...state,
      invocations: {},
      isError: false,
    }),
    [requestDuration]: (state) => ({
      ...state,
      duration: {},
      isError: false,
    }),
    [requestThrottles]: (state) => ({
      ...state,
      throttles: {},
      isError: false,
    }),
    [requestErrors]: (state) => ({
      ...state,
      errors: {},
      isError: false,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [setStackSelection]: (state, { payload }) => ({
      ...state,
      stackSelection: payload,
      isError: false,
    }),
    [setStackAccount]: (state, { payload }) => ({
      ...state,
      selectedAccount: payload,
      isError: false,
    }),
    [resetOptions]: (state) => ({
      ...state,
      selectedAccount: defaultState.selectedAccount,
      stackSelection: defaultState.stackSelection,
      initialPageLoad: false,
    }),
  },
  defaultState
);

export default lambdaMatrix;
