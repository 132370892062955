import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PageListWrapper from 'shared/styles/components/PageList';
import JobList from './jobList';
import { addNotification, setPageSize } from 'core/redux/ui/actions';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { ENV_DEFAULT_OPTION } from 'shared/constants/matrixOptions';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';

const jobs = ({ showNotification }) => {
  const [initialPageLoad, setInitialPageLoad] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(ENV_DEFAULT_OPTION);
  const dispatch = useDispatch();
  // reset page size on route change
  useEffect(() => {
    return () => {
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
    };
  }, []);

  return (
    <PageListWrapper>
      <JobList
        key={selectedAccount.value}
        initialPageLoad={initialPageLoad}
        setInitialLoads={(state) => setInitialPageLoad(state)}
        showNotification={showNotification}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
    </PageListWrapper>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showNotification: (e) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_JOBS, e),
        })
      ),
  })
)(jobs);
