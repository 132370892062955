export const VIEW_OPTIONS = [
  {
    label: 'Line',
    value: 'timeSeries',
  },
  {
    label: 'Bar',
    value: 'bar',
  },
  {
    label: 'Pie',
    value: 'pie',
  },
];
export const STAT_OPTIONS = [
  {
    label: 'Sum',
    value: 'Sum',
  },
  {
    label: 'Average',
    value: 'Average',
  },
  {
    label: 'Minimum',
    value: 'Minimum',
  },
  {
    label: 'Maximum',
    value: 'Maximum',
  },
];
export const PERIOD_OPTIONS = [
  {
    label: '1 Second',
    value: 1,
  },
  {
    label: '5 Seconds',
    value: 5,
  },
  {
    label: '10 Seconds',
    value: 10,
  },
  {
    label: '1 Minute',
    value: 60,
  },
  {
    label: '5 Minutes',
    value: 300,
  },
  {
    label: '15 Minutes',
    value: 900,
  },
  {
    label: '1 Hour',
    value: 3600,
  },
  {
    label: '6 Hours',
    value: 21600,
  },
  {
    label: '1 Days',
    value: 86400,
  },
  {
    label: '7 Days',
    value: 604800,
  },
  {
    label: '30 Days',
    value: 2592000,
  },
];

export const TIMEZONE_OPTIONS = [
  { label: 'Local timezone', value: 'local' },
  { label: 'UTC timezone', value: 'utc' },
];

export const TIMEZONE_DEFAULT_OPTION = { label: 'Local timezone', value: 'local' };
export const ENV_DEFAULT_OPTION = { label: 'Dev', value: 'Dev', envName: 'Dev' };
export const STACK_DEFAULT_OPTION = {
  prod: { label: 'Prod', value: 'WCP-Prod-TablesStack-GYEAZTIW3Z3Z' },
  dev: { label: 'Dev', value: 'WCP-Dev-TablesStack-U33WDC6EMRFR' },
};
export const VIEW_DEFAULT_OPTION = { label: 'Line', value: 'timeSeries' };
export const STAT_DEFAULT_OPTION = { label: 'Sum', value: 'Sum' };
export const PERIOD_DEFAULT_OPTION = { label: '5 Minutes', value: 300 };
export const jsonFormat = 'json';
export const imageFormat = 'image';
export const UTC_TIME = '+0000';
export const DEV = 'dev';
export const PROD = 'prod';
export const GPS_WORKING = 'GPS working';
export const GPS_UNKNOWN = 'Unknown';
export const GPS_STATUS = 'Searching for GPS signal…';
export const CORRECT_GPS_MODE = 3;
