import { apiCall } from 'core/api/API';
import { rulesExecuted, failedJobExecutionTotalCount } from 'shared/constants/api';

export const getRulesExecuted = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: rulesExecuted,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getFailedJobExecutionTotalCount = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: failedJobExecutionTotalCount,
    queryStringParameters: params,
    method: 'get',
  });
};
