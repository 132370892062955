import { getNotificationId } from 'core/widgets/alerts/Notifier';

export const LEVELS = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const createNotification = (level, title, m, dismiss) => {
  // when the error is a type error, extract the type error message
  const message = m instanceof TypeError ? m.message : m;
  console.log(getNotificationId());
  console.log(message);
  return {
    uid: getNotificationId(),
    title,
    message,
    level,
    dismissible: 'button',
    // default to a 10 second autoDismiss time
    // autoDimiss === 0 => notification remains until manually dismissed
    autoDismiss: dismiss || 10,
  };
};
