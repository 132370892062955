import { apiCall } from 'core/api/API';
import { envList } from 'shared/constants/api';

export const getEnvList = async () => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: envList,
    method: 'get',
  });
};
