/**
 * @ External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const RouterNavLink = ({ to, activeStyle, className, exact, strict, isActive, location, onClick, children }) => {
  return (
    <NavLink
      to={to}
      activeClassName={'selected'}
      activeStyle={activeStyle}
      className={className}
      exact={exact}
      strict={strict}
      isActive={isActive}
      location={location}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
RouterNavLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  className: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  isActive: PropTypes.func,
  location: PropTypes.object,
  onClick: PropTypes.func,
};

export default RouterNavLink;
