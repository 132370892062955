import { shadow, device, devices, reports, FIRMWAREREPO_URL, reportsData } from 'shared/constants/api';
import { getFormattedDuid } from 'shared/utilities/general';
import { apiCall } from 'core/api/API';

export const decorateDevice = ({ deviceFromAPI }) => {
  deviceFromAPI.searchableDeviceName = deviceFromAPI?.deviceId ? getFormattedDuid(deviceFromAPI?.deviceId) : '-';
  deviceFromAPI.tooltipDeviceName = deviceFromAPI?.deviceId ? getFormattedDuid(deviceFromAPI?.deviceId) : '-';
  deviceFromAPI.rawVSGID = deviceFromAPI?.deviceId ? deviceFromAPI?.deviceId : '-';
  deviceFromAPI.searchableEnv = deviceFromAPI.environment;
  deviceFromAPI.searcVehicleName = deviceFromAPI?.vehicleDetails.label ? deviceFromAPI?.vehicleDetails?.label : '-';
  deviceFromAPI.sortVehicleName = deviceFromAPI?.vehicleDetails.label ? deviceFromAPI?.vehicleDetails?.label : '-';
  deviceFromAPI.searchVehicleType = deviceFromAPI?.vehicleDetails?.vehicle_type
    ? deviceFromAPI?.vehicleDetails?.vehicle_type
    : '-';
  deviceFromAPI.searchVehicleYear = deviceFromAPI?.vehicleDetails?.mfg_year
    ? deviceFromAPI?.vehicleDetails?.mfg_year
    : '-';
  deviceFromAPI.searchVehicleMake = deviceFromAPI?.vehicleDetails?.make ? deviceFromAPI?.vehicleDetails?.make : '-';
  deviceFromAPI.searchVehicleModel = deviceFromAPI?.vehicleDetails?.model ? deviceFromAPI?.vehicleDetails?.model : '-';
  deviceFromAPI.searchVehicleLicense = deviceFromAPI?.vehicleDetails?.license_plate
    ? deviceFromAPI?.vehicleDetails?.license_plate
    : '-';
  // HAAS search and sort column data
  deviceFromAPI.searchHaasEnabled = deviceFromAPI?.vehicleDetails?.haas_enabled;
  deviceFromAPI.sortHaasEnabled = deviceFromAPI.searchHaasEnabled ? 1 : 0;
  // GTT search and sort column data
  deviceFromAPI.searchGttEnabled = deviceFromAPI?.vehicleDetails?.gtt_enabled
    ? JSON.parse(deviceFromAPI?.vehicleDetails?.gtt_enabled)
    : false;
  deviceFromAPI.sortGttEnabled = deviceFromAPI.searchGttEnabled ? 1 : 0;

  deviceFromAPI.searchFirmVersion = deviceFromAPI?.shadowInfo?.firmware?.current?.version
    ? deviceFromAPI?.shadowInfo?.firmware?.current?.version
    : '-';
  deviceFromAPI.searchFirmState = deviceFromAPI?.shadowInfo?.firmware?.state
    ? deviceFromAPI?.shadowInfo?.firmware?.state
    : '-';
  deviceFromAPI.searchLockStatus = deviceFromAPI?.securityLock ? deviceFromAPI?.securityLock : '-';
  deviceFromAPI.searchableOrganizationName = deviceFromAPI?.orgDetails[0]?.organization_name
    ? deviceFromAPI?.orgDetails[0]?.organization_name
    : '-';
  deviceFromAPI.searchAccountName = deviceFromAPI?.simInfo?.accountName ? deviceFromAPI?.simInfo?.accountName : '-';
  deviceFromAPI.vehicleLicense = deviceFromAPI?.vehicleDetails?.license_plate;
  deviceFromAPI.vehicleModel = deviceFromAPI?.vehicleDetails?.model;
  deviceFromAPI.vehicleMake = deviceFromAPI?.vehicleDetails?.make;
  deviceFromAPI.vehicleYear = deviceFromAPI?.vehicleDetails?.mfg_year;
  deviceFromAPI.vehicleType = deviceFromAPI?.vehicleDetails?.vehicle_type;
  deviceFromAPI.firmVersion = deviceFromAPI?.shadowInfo?.firmware?.current?.version;
  deviceFromAPI.firmState = deviceFromAPI?.shadowInfo?.firmware?.state;
  deviceFromAPI.lockStatus = deviceFromAPI?.securityLock;
  deviceFromAPI.deviceOrganizationName = deviceFromAPI?.orgDetails[0]?.organization_name;
  return deviceFromAPI;
};

export const getDevices = async (pageSize, lastEvaluatedKey, organizationId, awsAccount, stack, deviceId) => {
  let queryStringParameters;
  if (awsAccount && !organizationId && deviceId) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      deviceId: deviceId,
    };
  } else if (awsAccount && organizationId && deviceId) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      organizationId: organizationId,
      deviceId: deviceId,
    };
  } else if (awsAccount && organizationId && !lastEvaluatedKey) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      organizationId: organizationId,
    };
  } else if (awsAccount && lastEvaluatedKey) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      organizationId: lastEvaluatedKey.organization_id,
      vehicleId: lastEvaluatedKey.vehicle_id,
      env: awsAccount,
      tableStackName: stack,
    };
  }

  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: devices,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};
export const changeEnv = async (deviceId, envDetails, idTokenKey) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${device}/${deviceId}/env`,
    method: 'put',
    body: { envDetails: envDetails, idToken: idTokenKey },
  });
};

export const getDeviceShadow = async (deviceDetails) => {
  const awsAccount = { env: deviceDetails.awsAccount };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${device}/${encodeURIComponent(deviceDetails.deviceId)}/shadow`,
    queryStringParameters: awsAccount,
    method: 'get',
  });
};

// api call to firmware repo to get product data
export const getFirmwareProducts = async (productArray) => {
  const productIds = productArray.toString();
  const productIdParams = '[' + productIds + ']';
  const response = await fetch(
    FIRMWAREREPO_URL +
      new URLSearchParams({
        productId: productIdParams,
        latestOnly: true,
      })
  );
  const { json } = response;
  return json.bind(response)();
};

export const getDeviceSimInfo = async (deviceID) => {
  let newDeviceId = deviceID;
  if (deviceID && deviceID.length >= 32) {
    newDeviceId = deviceID;
  } else {
    newDeviceId = '00000009-' + newDeviceId;
  }

  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${device}/${encodeURIComponent(newDeviceId)}/simInfo`,
    method: 'get',
  });
};

export const resetDevice = async (deviceDetails) => {
  let queryStringParameters = { env: deviceDetails.awsAccount, tableStackName: deviceDetails.stack.value };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/device/vehiclereset',
    method: 'post',
    body: {
      vehicleId: deviceDetails?.device?.vehicleDetails.vehicle_id,
      organization_id: deviceDetails?.device?.vehicleDetails.organization_id,
      organizationName: deviceDetails?.device?.orgDetails[0]?.organization_name
        ? deviceDetails?.device?.orgDetails[0]?.organization_name
        : '-',
    },
    queryStringParameters: queryStringParameters,
  });
};

export const getVsgInfoApi = async (selectedAccount, selectedStack, deviceId, vehicleId, orgId) => {
  const queryStringParameters = {
    env: selectedAccount.value,
    tableStackName: selectedStack.value,
    vehicleId: vehicleId,
    organizationId: orgId,
  };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${device}/${deviceId}/additionalInfo`,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};

// send report in email
export const sendReportApi = async (reqData, reportType) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${reports}/${reportType}`,
    method: 'post',
    body: reqData,
  });
};

// get list of report
export const getDeviceReport = async (deviceDetails) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${reportsData}`,
    method: 'get',
  });
};
