/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import Select from 'react-select';
import theme from 'shared/styles/global/theme';
import device from 'shared/constants/sizing';
import Icon from '../../../assets/svg/check.svg';
import React from 'react';

/**
 * @ Internal Dependencies
 */
import colors from 'shared/constants/colors';

import caretDownDark from 'assets/images/caret-down-dark@2x.png';

export const SelectMenu = styled.div`
  display: flex;
  align-items: center;
  .show-label {
    font-size: 14px;
  }
`;
export default styled(Select)`
  height: 20px;
  margin-bottom: 15px;
  @media ${device.large} {
    margin-bottom: 8px;
  }
  & .Select-placeholder {
    line-height: 35px;
    @media ${device.large} {
      line-height: 18px;
    }
  }

  & > .Select-control .Select-value {
    line-height: 25px;
    @media ${device.large} {
    }
  }

  & .Select-input:focus {
    background: transparent;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background: transparent;
    border-color: rgba(138, 144, 156, 0.32);
  }

  &.is-open > .Select-control {
    background: transparent;
  }

  &.is-open .Select-arrow-zone {
    transform: rotate(180deg);
  }

  &.is-focused > .Select-control {
    background: transparent;
    border: 1px solid rgba(138, 144, 156, 0.32);
    border-color: rgba(138, 144, 156, 0.32);
  }

  & .Select-control {
    background: transparent;
    border: 1px solid rgba(138, 144, 156, 0.32);
    border-radius: 0;
    border-color: rgba(138, 144, 156, 0.32);
  }
  & .Select-control:hover {
    box-shadow: none;
  }

  &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  &.has-value.Select--single.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
    font-weight: 700;
    color: ${colors.midnight};
  }

  & .Select-value-label,
  & .Select-placeholder {
    font-size: 12px;
    font-weight: 700;
  }

  & .Select-arrow-zone {
    width: 35px;
    background-image: url(${caretDownDark});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 5px;
    transition: transform 0.3s;
    margin-top: 5px;
    @media ${device.large} {
      margin-top: 0px;
    }
  }

  & .Select-arrow-zone .Select-arrow {
    display: none;
  }
  .Select-clear {
    display: inline-block;
    font-size: 30px;
    line-height: 1;
    margin-right: 20px;
    @media ${device.large} {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  ${(props) =>
    props.field &&
    `
		& .Select-control {
			position: relative;
			border: none;
			
		}

		&.is-focused > .Select-control {
			border: none;
		}

		& .Select-placeholder {
			padding: 0;
			font-family: ${theme.font};
			font-weight: 400;
      color: #8a909c;
      height: 36px;
      border-bottom: 1px solid rgba(138, 144, 156, 0.25);
      font-size: 20px;
      @media ${device.large} {
        height: 24px;
        font-size: 12px;
      }
    }
    
    

		& .Select-arrow-zone {
			position: absolute;
			right: 0;
			top: 5px;
			width: 9px;
			height: 9px;
      z-index: 100;
      margin-top: 8px;
      @media ${device.large} {
        margin-top:0px;
      }
		}

		& .Select-menu-outer {
      z-index: 100;
      margin-top:10px;
      @media ${device.large} {
        margin-top:0px;
      }
      
		}

    
    &.is-disabled > .Select-control  {
      background-color: ${colors.cotton};
      opacity: 0.5;
      display: flex;
      align-items: center;

      .Select-value-label {
        padding: 0 8px;
      }
    }


		& > .Select-control .Select-value {
			padding: 0;
			font-family: ${theme.font};
			font-weight: 400 important;
      color: #8a909c;
      border-bottom: 1px solid rgba(138, 144, 156, 0.25);
      height: 30px;
      margin-top:5px;
      @media ${device.large} {
        margin-top:0px;
        height: 23px;
    }

			.Select-value-label {
				padding: 0 ;
				font-family: ${theme.font} !important;
				font-size: 22px !important;

        @media ${device.large} {
          font-size: 14px !important;
        }
			}
		}
	`};
`;

export const PaginationSelect = styled(Select).attrs((props) => ({
  onChange: props.onChange,
  placeholder: props.placeholder,
  name: props.name,
  id: props.id,
}))`
  & > .Select-control .Select-value {
    line-height: 17px;
    font-family: Muli, sans-serif;
    margin-right: 10px;
    padding-right: 15px;
  }

  & .Select-control {
    height: 20px;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  & .Select-multi-value-wrapper {
    height: 20px;
  }

  & .Select-input:focus {
    background: transparent;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  &.is-open > .Select-control {
    background: transparent;
  }

  &.is-open .Select-arrow-zone {
    transform: rotate(180deg);
  }

  &.is-focused > .Select-control {
    background: transparent;
  }

  & .Select-control:hover {
    box-shadow: none;
  }

  & .Select-value-label,
  & .Select-placeholder {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.haze};
  }

  & .Select-arrow-zone {
    width: 50px;
    background-image: url(${caretDownDark});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 5px;
    transition: transform 0.3s;
  }

  & .Select-arrow-zone .Select-arrow {
    display: none;
  }

  &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  &.has-value.Select--single.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
    color: ${colors.haze};
  }

  & .Select-option {
    font-family: Muli, sans-serif;
    font-weight: 700;
    color: ${colors.haze};
  }
`;

export const MatrixSelect = styled(Select).attrs((props) => ({
  onChange: props.onChange,
  placeholder: props.placeholder,
  name: props.name,
  id: props.id,
}))`
  & > .Select-control .Select-value {
    line-height: 17px;
    font-family: Muli, sans-serif;
    margin-right: 10px;
    padding-right: 15px;
  }

  & .Select-control {
    height: 20px;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  & .Select-multi-value-wrapper {
    height: 20px;
    width: 100px;
  }

  & .Select-input:focus {
    background: transparent;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  &.is-open > .Select-control {
    background: transparent;
  }

  &.is-open .Select-arrow-zone {
    transform: rotate(180deg);
  }

  &.is-focused > .Select-control {
    background: transparent;
  }

  & .Select-control:hover {
    box-shadow: none;
  }

  & .Select-value-label,
  & .Select-placeholder {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.haze};
    margin-top: -9px;
  }

  & .Select-arrow-zone {
    width: 50px;
    background-image: url(${caretDownDark});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 5px;
    transition: transform 0.3s;
  }

  & .Select-arrow-zone .Select-arrow {
    display: none;
  }

  &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  &.has-value.Select--single.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
    color: ${colors.haze};
  }

  & .Select-option {
    font-family: Muli, sans-serif;
    font-weight: 700;
    color: ${colors.haze};
  }
`;

export const EnvSelect = styled(Select).attrs((props) => ({
  onChange: props.onChange,
  placeholder: props.placeholder,
  name: props.name,
  id: props.id,
}))`
  & > .Select-control .Select-value {
    line-height: 17px;
    font-family: Muli, sans-serif;
    margin-right: 10px;
    padding-right: 15px;
  }

  & .Select-control {
    height: 20px;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  & .Select-multi-value-wrapper {
    height: 20px;
    width: 150px;
  }

  & .Select-input:focus {
    background: transparent;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    background: transparent;
    border-bottom: 1px solid #ccc;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  &.is-open > .Select-control {
    background: transparent;
  }

  &.is-open .Select-arrow-zone {
    transform: rotate(180deg);
  }

  &.is-focused > .Select-control {
    background: transparent;
  }

  & .Select-control:hover {
    box-shadow: none;
  }

  & .Select-value-label,
  & .Select-placeholder {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
  }

  & .Select-arrow-zone {
    width: 50px;
    background-image: url(${caretDownDark});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 5px;
    transition: transform 0.3s;
  }

  & .Select-arrow-zone .Select-arrow {
    display: none;
  }

  &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  &.has-value.Select--single.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
    color: #666;
  }

  & .Select-option {
    font-family: Muli, sans-serif;
    color: #666;
  }
`;

export const ReactSelectDefaultStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: 'unset',
    height: '35px',
    borderRadius: 0,
    border: '1px solid lightgrey',
    color: 'hsl(0, 0%, 20%)',
    boxShadow: 'unset',
    '&:hover': {
      borderColor: 'gray',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 35,
    overflowY: 'auto',
    fontSize: '14px',
    lineHeight: '20px',
    top: '-1px',
  }),
  menu: (base, { selectProps }) => ({
    ...base,
    width: selectProps.isStack ? 'max-content' : '100%',
    minWidth: selectProps.isStack ? '90px' : undefined,
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: '0px',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
    backgroundColor: state.isSelected ? 'gray !important' : '',
  }),
};

export const ReactSelectOrgStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: 'unset',
    width: '310px',
    display: 'flex',
    height: '35px',
    borderRadius: 0,
    border: '1px solid lightgrey',
    color: 'hsl(0, 0%, 20%)',
    boxShadow: 'unset',
    '&:hover': {
      borderColor: 'gray',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 35,
    fontSize: '14px',
    lineHeight: '20px',
    alignItems: 'center',
    display: 'flex',
    top: '-1px',
    padding: '0 8px', // Add padding to the container
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
    backgroundColor: state.isSelected ? 'gray !important' : '',
    whiteSpace: 'normal', // Allow text to wrap
    overflowX: 'hidden', // Prevent horizontal scrolling
  }),
  menu: (provided) => ({
    ...provided,
    width: '310px', // Adjust the width of the options container
    maxWidth: 'unset', // Remove max-width restriction
    wordBreak: 'break-word',
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: 'auto', // Enable horizontal scrolling for menu
  }),
  singleValue: (provided, state) => ({
    ...provided,
    whiteSpace: 'nowrap',
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
};

export const ReactPaginationStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 0,
    height: '35px',
    minHeight: 'unset',
    boxShadow: 'unset',
    borderRadius: 0,
    border: '1px solid lightgrey',
    color: 'hsl(0, 0%, 20%)',
    '&:hover': {
      borderColor: 'gray',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    overflowY: 'auto',
    fontSize: '14px',
    lineHeight: '16px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    top: '-1px',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
    backgroundColor: state.isSelected ? 'gray !important' : '',
  }),
};

export const StyledCheckbox = styled.input`
  appearance: none;
  min-width: 17px;
  height: 17px;
  border: 1px solid ${({ isSelected }) => (isSelected ? 'black' : '#999')};
  background-color: ${({ isSelected }) => (isSelected ? 'black' : 'transparent')};
  border-radius: 2px;
  position: relative;

  &:checked {
    background-color: black;
  }

  &:checked::after {
    content: '';
    background-image: url(${Icon});
    width: 17px;
    height: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
