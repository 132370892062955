import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { addNotification, setPageSize } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'shared/constants/messages';

import PageListWrapper from 'shared/styles/components/PageList';
import {
  user_Reset,
  setFetchingUsers,
  resetEnvStackOptions,
  setOrg,
  setUserNameSearchText,
} from 'core/redux/user/actions';
import UserList from './userList';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { orgList as organizationsList } from 'core/api/organizations';

const users = ({
  setInitialLoad,
  setFetchingUser,
  isFetchingUsers,
  showNotification,
  resetEnvStackOption,
  user_Reset,
}) => {
  const [initialPageLoad, setInitialPageLoad] = useState(false);
  const { selectedStack, selectedAccount } = useSelector((state) => state.user);
  const [organization, setOrganization] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(false);
  const [lastEvaluatedKeyOrg, setlastEvaluatedKeyOrg] = useState(false);
  const dispatch = useDispatch();

  // refresh user list page when organization option change if API call is already in progress for previous selection.
  const reloadComponent = (org) => {
    setOrganization(org);
  };

  // function for updating initial loader
  const resetInitialLoad = (state) => {
    setInitialPageLoad(state);
  };

  useEffect(() => {
    return () => {
      resetEnvStackOption();
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
      dispatch(setUserNameSearchText(''));
      dispatch(setOrg(null));
    };
  }, []);

  // fetch organization list
  const fetchOrgs = async (
    pageSize = PAGINATION_DEFAULT_OPTION.value,
    lastEvaluatedKey1,
    awsAccount = '',
    stack = '',
    type
  ) => {
    try {
      setIsFetchingOrgs(true);
      const { response } = await organizationsList(pageSize, lastEvaluatedKey1, awsAccount, stack);
      if (response.message) {
        let temp = [];
        response.message.map((orgs) =>
          temp.push({
            label: orgs.organization_name,
            value: orgs.organization_id,
          })
        );
        setOrgs((previous) => previous.concat(temp));
        response?.lastEvaluatedKey ? setlastEvaluatedKeyOrg(response?.lastEvaluatedKey || false) : null;
        response.lastEvaluatedKey ? setIsFetchingOrgs(true) : setIsFetchingOrgs(false);
        setInitialPageLoad(true);
      }
    } catch (e) {
      console.log(e);
      setIsFetchingOrgs(false);
      showNotification(e, 'org');
      setInitialPageLoad(true);
    }
  };

  // alphanumeric sort for organization list in dropdown
  useEffect(() => {
    function alphanumericSort(a, b) {
      return a.label.localeCompare(b.label, undefined, { numeric: true });
    }
    const records = orgs.sort(alphanumericSort);
    setOrgs(records);
  }, [orgs]);

  // check for lastEvaluatedKeyOrg state and fetch next set of records.
  useEffect(() => {
    if (lastEvaluatedKeyOrg) {
      fetchOrgs(undefined, lastEvaluatedKeyOrg, selectedAccount.value.toLowerCase(), selectedStack.value);
    }
    return () => false;
  }, [lastEvaluatedKeyOrg]);

  useEffect(() => {
    dispatch(setUserNameSearchText(''));
    fetchOrgs(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value);
  }, []);

  // on changing stack refresh org list with new API call
  const refreshOrgList = (account, stack) => {
    setOrgs([]);
    dispatch(setOrg(null));
    fetchOrgs(undefined, undefined, account.value.toLowerCase(), stack.value);
  };

  return (
    <PageListWrapper>
      <UserList
        key={selectedAccount?.value + selectedStack?.value + organization}
        setFetchingUsers={setFetchingUser}
        isFetchingUsers={isFetchingUsers}
        user_Reset={user_Reset}
        setInitialLoads={(state) => resetInitialLoad(state)}
        selectedStack={selectedStack}
        selectedAccount={selectedAccount}
        showNotification={showNotification}
        reloadComponent={(state) => reloadComponent(state)}
        orgs={orgs}
        isFetchingOrgs={isFetchingOrgs}
        refreshOrgList={(account, stack) => refreshOrgList(account, stack)}
      />
      {!initialPageLoad ? <LoadingOverlay /> : null}
    </PageListWrapper>
  );
};

export default connect(
  (state) => ({
    initialPageLoad: state.user.initialPageLoad,
    users: state.user.users,
    isFetchingUsers: state.user.isFetchingUsers,
    isError: state.user?.isError,
  }),
  (dispatch) => ({
    setFetchingUser: (status) => dispatch(setFetchingUsers(status)),
    resetEnvStackOption: () => dispatch(resetEnvStackOptions()),
    user_Reset: (userData) => dispatch(user_Reset(userData)),
    showNotification: (e) =>
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            type !== 'org' ? ERROR_MESSAGES.FETCH_USERS : ERROR_MESSAGES.FETCH_ORGS,
            e
          ),
        })
      ),
  })
)(users);
