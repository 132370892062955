/**
 * @ External Dependencies
 */
import { handleActions } from 'redux-actions';

/**
 * @ Actions
 */
import {
  toggleOverlay,
  hideOverlay,
  displayOverlay,
  toggleGlobalOverlay,
  toggle,
  addNotification,
  successAddNotification,
  successRemoveNotification,
  setPageSize,
} from './actions';
import { openSidebar, closeSidebar, toggleSidebar } from 'core/redux/sidebar/actions';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';

/**
 * @ Reducer
 */
const defaultState = {
  overlayIsVisible: false,
  globalOverlayIsVisible: false,
  isPageOverlayVisible: false,
  fullscreenMode: false,
  notificationsToAdd: [],
  notifications: [],
  pageSize: PAGINATION_DEFAULT_OPTION,
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const ui = handleActions(
  {
    [toggle]: (state, { payload: { selector } }) => ({
      ...state,
      [selector]: !state[selector],
    }),
    [closeSidebar]: (state) => ({
      ...state,
      overlayIsVisible: false,
    }),
    [hideOverlay]: (state) => ({
      ...state,
      overlayIsVisible: false,
    }),
    [openSidebar]: (state) => ({
      ...state,
      overlayIsVisible: true,
    }),
    [displayOverlay]: (state) => ({
      ...state,
      overlayIsVisible: true,
    }),
    [toggleOverlay]: (state) => ({
      ...state,
      overlayIsVisible: !state.overlayIsVisible,
    }),
    [toggleSidebar]: (state) => ({
      ...state,
      overlayIsVisible: !state.overlayIsVisible,
    }),
    [toggleGlobalOverlay]: (state) => ({
      ...state,
      globalOverlayIsVisible: !state.globalOverlayIsVisible,
    }),
    // add notification to list for adding via Notifier Component
    [addNotification]: (state, { payload: { notification } }) => ({
      ...state,
      notificationsToAdd: state.notificationsToAdd.concat([notification]),
    }),
    // upon successfully adding notification call this
    [successAddNotification]: (state, { payload: { notificationUid } }) => {
      return {
        ...state,
        notificationsToAdd: state.notificationsToAdd.filter((notification) => notification.uid !== notificationUid),
        notifications: state.notifications.concat([notificationUid]),
      };
    },
    // upon removing this call this
    [successRemoveNotification]: (state, { payload: { notificationUid } }) => {
      return {
        ...state,
        notifications: state.notifications.filter((uid) => uid !== notificationUid),
      };
    },
    [setPageSize]: (state, { payload }) => {
      return {
        ...state,
        pageSize: payload,
      };
    },
  },
  defaultState
);

export default ui;
