/**
 * @ External Dependecies
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import Notifier from 'core/widgets/alerts/Notifier';
import { addNotification } from 'core/redux/ui/actions';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

import theme from 'shared/styles/global/theme';
import Wrapper from 'shared/styles/components/Wrapper';
import { fetchEnvList, getLoggedInStatus, fetchTablesList } from 'core/redux/user/actions';

import Routes from 'core/components/navigation/Routes';
class App extends Component {
  state = {
    asyncDataLoaded: false,
  };

  async componentDidMount() {
    await this.props.getLoggedInStatus();
    this.props.loggedIn && this.props.getEnvOptions() && this.props.getTableOptions();
    this.setState({ asyncDataLoaded: true });
    window.onpopstate = this.onBackOrForwardButtonEvent;
  }

  onBackOrForwardButtonEvent = () => {};

  render() {
    const { checkingSession } = this.props;
    const { asyncDataLoaded } = this.state;

    return !checkingSession && asyncDataLoaded ? (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Routes />
          <Notifier />
        </Wrapper>
      </ThemeProvider>
    ) : (
      <LoadingOverlay externalStyles={{ overlay: { left: '0px' } }} />
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      overlayVisible: state.ui.globalOverlayIsVisible,
      checkingSession: state.user.checkingSession,
      loggedIn: state.user.loggedIn,
    }),
    (dispatch) => ({
      addNotification: (notification) => dispatch(addNotification({ notification })),
      getLoggedInStatus: async () => await dispatch(getLoggedInStatus()),
      getEnvOptions: () => dispatch(fetchEnvList()),
      getTableOptions: () => dispatch(fetchTablesList()),
    })
  )(App)
);
