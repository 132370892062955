import { createAction } from 'redux-actions';

import {
  getGateway5XXError,
  getGatewayCount,
  getGatewayIntegrationLatency,
  getGatewayLatency,
} from 'core/api/gatewayMatrix';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receive5XXError = createAction('RECEIVE_5XX_ERROR');
export const receiveCount = createAction('RECEIVE_COUNTS');
export const receiveIntegrationLatency = createAction('RECEIVE_INTEGRATION_LATENCY');
export const receiveLatency = createAction('RECEIVE_LATENCY');

export const request5XXError = createAction('REQUEST_5XX_ERROR');
export const requestCount = createAction('REQUEST_COUNTS');
export const requestIntegrationLatency = createAction('REQUEST_INTEGRATION_LATENCY');
export const requestLatency = createAction('REQUEST_LATENCY');
export const handleError = createAction('HANDLE_ERROR');

// function use to fetch API Gateway 5XXError metric data
export const fetchGateway5XXError = (params) => {
  return async (dispatch) => {
    dispatch(request5XXError());
    try {
      const { response } = await getGateway5XXError(params);
      dispatch(receive5XXError(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_GATEWAY_5XX_ERRORS, e),
        })
      );
      return false;
    }
  };
};

// function use to fetch API Gateway Count metric data
export const fetchGatewayCount = (params) => {
  return async (dispatch) => {
    dispatch(requestCount());
    try {
      const { response } = await getGatewayCount(params);
      dispatch(receiveCount(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_GATEWAY_COUNT, e),
        })
      );
      return false;
    }
  };
};

// function use to fetch API Gateway IntegrationLatency metric data
export const fetchGatewayIntegrationLatency = (params) => {
  return async (dispatch) => {
    dispatch(requestIntegrationLatency());
    try {
      const { response } = await getGatewayIntegrationLatency(params);
      dispatch(receiveIntegrationLatency(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_GATEWAY_INT_LAT, e),
        })
      );
      return false;
    }
  };
};

// function use to fetch API Gateway Latency metric data
export const fetchGatewayLatency = (params) => {
  return async (dispatch) => {
    dispatch(requestLatency());
    try {
      const { response } = await getGatewayLatency(params);
      dispatch(receiveLatency(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_GATEWAY_LAT, e),
        })
      );
      return false;
    }
  };
};
