import React, { useState, useEffect } from 'react';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText, ReportDropdown } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import Badge from 'shared/styles/components/Badge';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'shared/constants/messages';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { decorateOrgs, orgList as organizationsList } from 'core/api/organizations';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as ReactLogo } from '../../assets/svg/download.svg';
import { STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

// organization list table
const orgList = ({
  hideEnvDropdown,
  setInitialLoads,
  stackSelection,
  selectedStack,
  setStackAccount,
  selectedAccount,
  showNotification,
}) => {
  const [resetPageNo, setResetPageNo] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(false);

  // API call for fetching organization data.
  const fetchOrgs = async (
    pageSize = PAGINATION_DEFAULT_OPTION.value,
    lastEvaluatedKey1,
    awsAccount = '',
    stack = '',
    type
  ) => {
    try {
      setIsFetchingOrgs(true);
      const { response } = await organizationsList(pageSize, lastEvaluatedKey1, awsAccount, stack);
      if (response.message) {
        let temp = [];
        response.message.map((orgs) =>
          temp.push(
            decorateOrgs({
              orgFromAPI: orgs,
            })
          )
        );
        setInitialLoads(true);
        setOrgs((previous) => previous.concat(temp));
        response.lastEvaluatedKey ? setIsFetchingOrgs(true) : setIsFetchingOrgs(false);
        response?.lastEvaluatedKey ? setLastEvaluatedKey(response?.lastEvaluatedKey || false) : null;
      } else {
        setIsFetchingOrgs(false);
      }
    } catch (e) {
      console.log(e);
      setIsFetchingOrgs(false);
      setInitialLoads(true);
      showNotification(e);
    }
  };

  // check for lastEvaluatedKey state and fetch next set of records.
  useEffect(() => {
    if (lastEvaluatedKey) {
      fetchOrgs(undefined, lastEvaluatedKey, selectedAccount.value.toLowerCase(), selectedStack.value);
    }
    return () => false;
  }, [lastEvaluatedKey]);

  // get records on page load
  useEffect(() => {
    fetchOrgs(undefined, lastEvaluatedKey, selectedAccount.value.toLowerCase(), selectedStack.value);
  }, []);

  // on click refresh button.
  const refreshData = () => {
    setResetPageNo(!resetPageNo);
    setOrgs([]);
    fetchOrgs(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value);
  };

  // Env dropdown change handler
  const optionChangeHandler = (e) => {
    setStackAccount(e);
    stackSelection(STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
  };

  // stack dropdown change handler
  const stackChangeHandler = async (e) => {
    if (e.value !== selectedStack.value) {
      setOrgs([]);
      stackSelection(e);
    }
  };

  let columns = [
    {
      title: 'Organization',
      dataIndex: 'organization_name',
      searchIndex: 'searchOrgName',
      key: 'organization_name',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span
            title={'Organization: ' + (orgs?.organization_name ? orgs.organization_name : '-')}
            className="jobNameAndIcon"
          >
            <IconSvgComponent svgFileName={'organizations'} alt="Organization" />
            <TruncatedText organization_name>{orgs?.searchOrgName}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'CONTACT EMAIL',
      dataIndex: 'contact_email_id',
      searchIndex: 'searchEmail',
      key: 'contact_email_id',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Contact Email: ' + (orgs?.contact_email_id ? orgs.contact_email_id : '-')}>
            <TruncatedText contact_email_id>{orgs?.searchEmail}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Address',
      dataIndex: 'searchForAddress',
      searchIndex: 'searchAddress',
      key: 'searchForAddress',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Address: ' + (value ? value : '-')}>
            <TruncatedText address_1>{orgs?.searchAddress}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'State',
      dataIndex: 'address_state',
      searchIndex: 'searchAddressState',
      key: 'address_state',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'State: ' + (orgs?.address_state ? orgs.address_state : '-')}>
            <TruncatedText address_state>{orgs?.searchAddressState}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Customer Type',
      dataIndex: 'customer_type',
      searchIndex: 'searchCustomerType',
      key: 'customer_type',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Customer Type: ' + (orgs?.customer_type ? orgs?.customer_type : '-')}>
            <TruncatedText customer_type>{orgs?.searchCustomerType}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'contact_phone',
      searchIndex: 'searchContactPhone',
      key: 'contact_phone',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Contact Phone: ' + (orgs?.contact_phone ? orgs.contact_phone : '-')}>
            <TruncatedText contact_phone>{orgs?.searchContactPhone}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDateValue',
      searchIndex: 'searchStartDate',
      key: 'start_date',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Start Date: ' + (orgs?.startDate ? orgs?.startDate : '-')}>
            <TruncatedText start_date>{orgs?.searchStartDate}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDateValue',
      searchIndex: 'searchEndDate',
      key: 'end_date',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'End Date: ' + (orgs?.endDate ? orgs?.endDate : '-')}>
            <TruncatedText end_date>{orgs?.searchEndDate}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Vehicle count',
      dataIndex: 'vehicle_count',
      searchIndex: 'searchVehicleCount',
      key: 'vehicle_count',
      searchable: true,
      width: '10%',
      render(value, orgs) {
        return (
          <span title={'Vehicle Count: ' + (orgs?.vehicle_count ? orgs.vehicle_count : '-')}>
            <TruncatedText vehicle_count>{orgs?.searchVehicleCount}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'organization_active',
      searchIndex: 'searchOrganizationActive',
      key: 'organization_active',
      searchable: false,
      width: '10%',
      render: (value, orgs) => (
        <Badge active={orgs.searchOrganizationActive}>
          <span title={orgs.searchOrganizationActive ? 'Status Enabled' : 'Status Disabled'}>
            {orgs.searchOrganizationActive ? 'Enabled' : 'Disabled'}
          </span>
        </Badge>
      ),
    },
    {
      title: 'Haas',
      dataIndex: 'sortHaasEnabled',
      searchIndex: 'searchHaasEnabled' && 'searchHaasSetup',
      key: 'haas_enabled',
      searchable: false,
      width: '10%',
      render: (value, orgs) =>
        orgs?.searchHaasEnabled &&
        (orgs?.searchHaasSetup ? (
          <IconSvgComponent svgFileName={'check-success'} alt="HAAS Enabled" title="HAAS Integration Enabled" />
        ) : (
          <IconSvgComponent svgFileName={'check-pending'} alt="HAAS Pending" title="HAAS Integration Pending" />
        )),
    },
    {
      title: 'GTT',
      dataIndex: 'sortGttEnabled',
      key: 'gtt_enabled',
      searchable: false,
      width: '10%',
      render: (value, orgs) =>
        orgs?.searchGttEnabled &&
        (orgs?.searchGttSetup ? (
          <IconSvgComponent svgFileName={'check-success'} alt="GTT Enabled" title="GTT Integration Enabled" />
        ) : (
          <IconSvgComponent svgFileName={'check-pending'} alt="GTT Pending" title="GTT Integration Pending" />
        )),
    },
  ];

  return (
    <div>
      <PageListHead>
        <PageListTitle>Organizations</PageListTitle>
        <PageListActionButtonContainer>
          <LoadingText>{isFetchingOrgs ? SUCCESS_MESSAGES.FETCHING_RECORD : null}</LoadingText>
          <HeaderButtonDiv cancel>
            <ButtonWithLoader
              disabled={isFetchingOrgs}
              isLoading={isFetchingOrgs}
              confirmText={'Refresh'}
              loadingStyleProp={'submittingWithSpinner'}
              notLoadingStyleProp={'refresh'}
              clickHandler={refreshData}
            />
          </HeaderButtonDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      Total of {orgs?.length} organizations
      <SortableTable
        className="table-simple"
        columns={columns}
        tableData={orgs ? orgs : []}
        scroll={{ x: false, y: false }}
        rowKey={(record) => record.userId}
        refresh={resetPageNo}
        emptyText={ERROR_MESSAGES.EMPTY_TEXT}
        optionChangeHandler={optionChangeHandler}
        stackChangeHandler={stackChangeHandler}
        selectedAccount={selectedAccount}
        selectedStack={selectedStack}
        hideEnvDropdown={hideEnvDropdown}
        showStates={true}
      />
    </div>
  );
};

export default orgList;
