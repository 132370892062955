import React from 'react';
import TabView from 'shared/ui/tabs/TabView';
import { Tab, ShadowNav } from 'shared/styles/components/Tabs';
import DeviceShadow from '../components/deviceShadow';
import DeviceSimInfo from '../components/deviceSimInfo';
import { Link } from 'react-router-dom';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { BackButton } from 'shared/styles/components/BackButton';
import DeviceJobs from '../components/deviceJobs';
import { getFormattedDuid } from 'shared/utilities/general';

const deviceInfo = ({ deviceID, awsAccount }) => {
  return (
    <>
      <div className="deviceId">
        <Link to={'/device-operations'}>
          <BackButton>
            <IconSvgComponent svgFileName={'corner-up-left'} alt="Back" />
          </BackButton>
        </Link>
        <h4 style={{ marginLeft: '10px' }}>Device Name: {getFormattedDuid(deviceID)}</h4>
      </div>
      <React.Fragment>
        <TabView
          tabContainer={ShadowNav}
          tabs={[
            {
              tabElement: <Tab>Device Sim Information</Tab>,
              content: <DeviceSimInfo selectedDevice={deviceID} />,
            },
            {
              tabElement: <Tab>Device Shadow</Tab>,
              content: <DeviceShadow selectedDevice={deviceID} awsAccount={awsAccount} />,
            },
            {
              tabElement: <Tab>Device Jobs</Tab>,
              content: <DeviceJobs selectedDevice={deviceID} awsAccount={awsAccount} />,
            },
          ]}
        />
      </React.Fragment>
    </>
  );
};

export default deviceInfo;
