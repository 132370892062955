import React from 'react';
import useAPIData from 'shared/hooks/useAPIData';
import { fetchLoginDetails } from 'core/redux/user/actions';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

const loginDetails = ({ location, fetchLoginDetails, loggingIn }) => {
  const code = location.search.split('=')[1];
  useAPIData({
    fetchAPIData: () => fetchLoginDetails(code),
    dependencies: [code],
  });
  return loggingIn ? <LoadingOverlay /> : <Redirect to={'/dashboard'} />;
};

export default withRouter(
  connect(
    (state) => ({
      loggingIn: state.user.loggingIn,
    }),
    (dispatch) => ({
      fetchLoginDetails: (code) => {
        return dispatch(fetchLoginDetails(code));
      },
    })
  )(loginDetails)
);
