import { apiCall } from 'core/api/API';
import { gateway5xxError, gatewayCount, integrationLatency, latency } from 'shared/constants/api';

export const getGateway5XXError = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: gateway5xxError,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getGatewayCount = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: gatewayCount,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getGatewayIntegrationLatency = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: integrationLatency,
    queryStringParameters: params,
    method: 'get',
  });
};

export const getGatewayLatency = async (params) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: latency,
    queryStringParameters: params,
    method: 'get',
  });
};
