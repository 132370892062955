import { handleActions } from 'redux-actions';
import { receiveJobTargets, receiveJobDetails, setFetchingJobDetails, handleError, requestJobDetails } from './actions';

const defaultState = {
  targets: [],
  jobDetails: {},
  isFetchingJobDetails: false,
  isError: false,
};

const jobDetails = handleActions(
  {
    [setFetchingJobDetails]: (state) => ({
      ...state,
      isFetchingJobDetails: true,
      targets: [],
    }),
    [receiveJobDetails]: (state, { payload: jobDetails }) => ({
      ...state,
      isFetchingJobDetails: false,
      jobDetails: jobDetails,
    }),
    [requestJobDetails]: (state) => ({
      ...state,
      jobDetails: {},
      isFetchingJobDetails: false,
      isError: false,
    }),
    [receiveJobTargets]: (state, { payload: targets }) => ({
      ...state,
      isFetchingJobDetails: false,
      targets: targets,
    }),
    [handleError]: (state) => ({
      ...state,
      targets: [],
      jobDetails: {},
      isFetchingJobDetails: false,
      isError: true,
    }),
  },
  defaultState
);
export default jobDetails;
