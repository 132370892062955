import { createAction } from 'redux-actions';

import {
  getDeviceShadow,
  getDeviceSimInfo,
  getDevices,
  decorateDevice,
  changeEnv,
  getFirmwareProducts,
  resetDevice,
} from 'core/api/deviceOperations';
import { getDeviceJobs, decorateJob } from 'core/api/jobs';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { getIdToken, setShadow } from 'shared/utilities/localStore';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'shared/constants/messages';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

export const receiveDeviceShadow = createAction('RECEIVE_DEVICE_SHADOW');
export const requestDeviceShadow = createAction('REQUEST_DEVICE_SHADOW');
export const handleError = createAction('HANDLE_ERROR');
export const receiveDeviceSimInfo = createAction('RECEIVE_DEVICE_SIM_INFO');
export const requestDeviceSimInfo = createAction('REQUEST_DEVICE_SIM_INFO');

export const receiveDevices = createAction('RECEIVE_DEVICES');
export const requestDevices = createAction('REQUEST_DEVICES');
export const setFetchingdevices = createAction('SET_FETCHING_DEVICES');
export const requestEnvChange = createAction('REQUEST_ENV_CHANGE');
export const resetDevices = createAction('RESET_DEVICES');

export const requestJobs = createAction('REQUEST_JOBS');
export const receiveJobs = createAction('RECEIVE_JOBS');
export const rejectJobs = createAction('REJECT_JOBS');
export const setFetchingJobs = createAction('SET_FETCHING_JOBS');
export const resetJobs = createAction('RESET_JOBS');

export const setFetchingDevices = createAction('SET_FETCHING_DEVICES');
export const setInitialDeviceLoads = createAction('INITIAL_DEVICE_LOAD');
export const setStackSelection = createAction('DEVICE_STACK_SELECTION');
export const setStackAccount = createAction('DEVICE_STACK_ACCOUNT');
export const resetOptions = createAction('RESET_OPTIONS');

export const setOrg = createAction('SET_ORG');
export const setSearchTextGlobal = createAction('SET_SEARCH_TEXT');
export const setExternalSearch = createAction('SET_EXTERNAL_SEARCH');
export const setClearSearchText = createAction('SET_CLEAR_SEARCH_TEXT');

// calls when click on refresh button from device list page
export const clearAndRestoreDevices = (orgDetails) => {
  return async (dispatch) => {
    dispatch(setFetchingdevices(true));
    dispatch(resetDevices([]));
    return dispatch(fetchDevices(undefined, undefined, orgDetails.awsAccount, orgDetails.stack));
  };
};

// to do request of change in stack of the device
export const changeDeviceEnv = (device) => {
  return async (dispatch) => {
    dispatch(requestEnvChange(device.deviceId));
    try {
      const idToken = getIdToken();
      await changeEnv(device.deviceId, { from: device.environment, to: device.destEnv }, idToken);
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            SUCCESS_MESSAGES.CHANGES_DEVICE_ENV,
            SUCCESS_MESSAGES.CHANGE_DEVICE_ENV_SUB
          ),
        })
      );
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.CHANGES_DEVICE_ENV, e),
        })
      );
      return false;
    }
  };
};

// get all productId from shadow data
let productArray = [];
export const getProductArray = async (obj) => {
  if (obj?.productId) {
    productArray?.push(obj.productId);
  }
  for (let k in obj) {
    if (typeof obj[k] === 'object') {
      getProductArray(obj[k]);
    }
  }
  return productArray;
};

// update productId in shadow data
export const updateDeviceShadow = (obj, firmwareProducts) => {
  if (obj?.productId && firmwareProducts.length > 0) {
    const findProduct = firmwareProducts.find((element) => element.productId === obj.productId);
    if (findProduct) {
      obj.productId = findProduct.productName;
    }
  }
  for (let k in obj) {
    if (typeof obj[k] === 'object') {
      updateDeviceShadow(obj[k], firmwareProducts);
    }
  }
};

// to fetch the device shadow details
export const fetchDeviceShadow = (deviceDetails) => {
  return async (dispatch) => {
    dispatch(requestDeviceShadow());
    try {
      const { response } = await getDeviceShadow(deviceDetails);
      // get productId array
      await getProductArray(response.message.state);
      setShadow(response);
      let firmwareProducts = [];
      if (productArray.length > 0) {
        // get firmware products by making api call to firmware repo
        firmwareProducts = await getFirmwareProducts(productArray);
      }
      if (firmwareProducts?.length > 0) {
        updateDeviceShadow(response.message.state, firmwareProducts);
      }
      dispatch(receiveDeviceShadow(response.message));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DEVICE_SHADOW, e),
        })
      );
      return false;
    }
  };
};

// to fetch the device sim info details
export const fetchDeviceSimInfo = (deviceId) => {
  return async (dispatch) => {
    dispatch(requestDeviceSimInfo());
    try {
      const { response } = await getDeviceSimInfo(deviceId);
      dispatch(receiveDeviceSimInfo(response.message));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DEVICE_SIM_INFO, e),
        })
      );
      return false;
    }
  };
};

// to fetch the device job list
export const fetchDeviceJobs = (deviceId, pageSize = PAGINATION_DEFAULT_OPTION.value, nextToken, awsAccount = '') => {
  return async (dispatch) => {
    dispatch(requestJobs());
    try {
      const { response } = await getDeviceJobs(deviceId, pageSize, nextToken, awsAccount);
      dispatch(setFetchingJobs(response.nextToken ? true : false));
      if (response.message) {
        dispatch(
          receiveJobs(
            response.message.map((job) =>
              decorateJob({
                jobFromAPI: job,
              })
            )
          )
        );
        if (response.nextToken) {
          dispatch(fetchDeviceJobs(deviceId, PAGINATION_DEFAULT_OPTION.value, response.nextToken));
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectJobs(false));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DEVICE_JOBS, e),
        })
      );
      return false;
    }
  };
};

// calls when click on reset button from device list page
export const device_Reset = (deviceDetails) => {
  return async (dispatch) => {
    try {
      const { response } = await resetDevice(deviceDetails);
      if (response.message) {
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, SUCCESS_MESSAGES.VEHICLE_RESET_SUCCESS),
          })
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.VEHICLE_RESET_ERROR, e),
        })
      );
    }
  };
};

export const clearAndRestoreJobs = (deviceDetails) => {
  return async (dispatch) => {
    dispatch(setFetchingJobs(true));
    dispatch(resetJobs([]));
    return dispatch(fetchDeviceJobs(deviceDetails.deviceId, undefined, undefined, deviceDetails.awsAccount));
  };
};

// set initial loader state
export const setInitialLoads = (status) => {
  return async (dispatch) => {
    dispatch(setInitialOrgLoads(status));
  };
};

// set state while fetching records
export const setFetchingDevice = (status) => {
  return async (dispatch) => {
    dispatch(setFetchingDevices(status));
  };
};

// set state when selecting Stack
export const stackSelection = (selection) => {
  return async (dispatch) => {
    dispatch(setStackSelection(selection));
  };
};

// set state when selecting Account
export const stackAccount = (selection) => {
  return async (dispatch) => {
    dispatch(setStackAccount(selection));
  };
};

// reset states to its default on changing page.
export const resetEnvStackOptions = () => {
  return async (dispatch) => {
    dispatch(resetOptions());
  };
};
