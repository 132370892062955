import { apiCall } from 'core/api/API';
import { lambdaInvocations, lambdaDuration, lambdaThrottles, lambdaErrors } from 'shared/constants/api';

export const getLambdaInvocations = async (queryStringParameters) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: lambdaInvocations,
    queryStringParameters: queryStringParameters,
    method: 'get',
  });
};

export const getLambdaDuration = async (queryStringParameters) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: lambdaDuration,
    queryStringParameters: queryStringParameters,
    method: 'get',
  });
};

export const getLambdaThrottles = async (queryStringParameters) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: lambdaThrottles,
    queryStringParameters: queryStringParameters,
    method: 'get',
  });
};

export const getLambdaErrors = async (queryStringParameters) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: lambdaErrors,
    queryStringParameters: queryStringParameters,
    method: 'get',
  });
};
