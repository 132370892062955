/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import { Matrix } from 'shared/styles/components/matrixWrapper';

const matrix = ({ base64Data }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImageUrl(`data:image/jpeg;base64,${base64Data}`);
  }, [base64Data]);

  return <Matrix>{imageUrl ? <img src={imageUrl} alt="new" className="image" /> : null}</Matrix>;
};
export default matrix;
