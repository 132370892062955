/*
 * @ Local Storage
 */
import { localStore, authKey, shadow, stackTable } from 'shared/constants/localStore';

/*
 * @ Actions
 */

let logoutTimeout;

// Created timer for logout
const setAuthExpirationTimeout = (expiresIn) => {
  logoutTimeout = setTimeout(() => {}, expiresIn);
};

// Update the values stored in localstorage
export const updateAuthStore = ({ AccessToken, ExpiresIn, ExpiresAt, TokenType, RefreshToken, IdToken }) => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey));
    let nextExpiresAt = null;
    const currentTime = new Date().getTime();
    if (ExpiresAt) {
      nextExpiresAt = ExpiresAt;
      clearTimeout(logoutTimeout);
      if (ExpiresAt > currentTime) {
        setAuthExpirationTimeout(ExpiresAt - currentTime);
      }
    }
    if (!ExpiresAt && AccessToken && ExpiresIn) {
      nextExpiresAt = new Date().getTime() + ExpiresIn * 1000;
      clearTimeout(logoutTimeout);
      setAuthExpirationTimeout(ExpiresIn * 1000);
    }
    localStore.setItem(
      authKey,
      JSON.stringify({
        ...authState,
        AccessToken,
        ExpiresIn,
        ExpiresAt: nextExpiresAt,
        TokenType,
        RefreshToken: RefreshToken || getRefreshToken(),
        IdToken,
      })
    );
  }
};

// Store the stack table names in localstorage
export const setStackTable = (obj) => {
  if (localStore.getItem(stackTable)) {
    let temp = {};
    const stackName = getStackTable();
    if (Object.keys(stackName).includes(Object.keys(obj)[0])) {
      stackName[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]];
      localStore.setItem(stackTable, JSON.stringify(stackName));
    } else {
      temp = { ...stackName, ...obj };
      localStore.setItem(stackTable, JSON.stringify(temp));
    }
  } else {
    localStore.setItem(stackTable, JSON.stringify(obj));
  }
};

// Fetch the stack table names from localstorage
export const getStackTable = () => JSON.parse(localStore.getItem(stackTable));

// Store the shadow in localstorage
export const setShadow = (obj) => {
  if (localStore) {
    localStore.setItem(shadow, JSON.stringify(obj.message));
  }
};

// Fetch the shadow from localstorage
export const getShadow = () => JSON.parse(localStore.getItem(shadow));

// Fetch the data from localstorage
const getAuthState = () => JSON.parse(localStore.getItem(authKey));

// Remove the data from localstorage
export const clearAuthStore = () => {
  if (localStore) {
    localStore.setItem(authKey, JSON.stringify({}));
  }
};

// Get the Access token from local storage
export const getAccessToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken } = authState;
      return AccessToken;
    }
    return '';
  }
};

// Get the Refresh token from local storage
export const getRefreshToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { RefreshToken } = authState;
      return RefreshToken;
    }
    return '';
  }
};

// Get the Id token from local storage
export const getIdToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { IdToken } = authState;
      return IdToken;
    }
    return '';
  }
};

// Get the Expiry Time from local storage
export const getExpiryTime = () => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey)) || {};
    if (authState) {
      const { ExpiresAt } = authState;
      return ExpiresAt;
    }
    return 0;
  }
};

// Check user is logged in and have valid token or not
export const loginIsFresh = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken, IdToken, ExpiresAt } = authState;
      return AccessToken !== null && IdToken !== null && ExpiresAt > new Date().getTime();
    }
    return false;
  }
};

export const isExpired = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { ExpiresAt } = authState;
      const twentySeconds = 20000;
      return ExpiresAt <= new Date().getTime() + twentySeconds;
    }
    return false;
  }
};
