import { handleActions } from 'redux-actions';
import { receiveAddons, setFetchingAddons, rejectAddons, resetAddons, requestAddons } from './action';

const defaultState = {
  addons: [],
  isFetchingAddons: false,
  isError: false,
};

const addons = handleActions(
  {
    [setFetchingAddons]: (state, { payload: isFetchingAddons }) => ({
      ...state,
      isFetchingAddons: isFetchingAddons,
      isError: false,
    }),
    [receiveAddons]: (state, { payload: addons }) => {
      return {
        ...state,
        addons: state.addons.concat(addons),
      };
    },
    [resetAddons]: (state, { payload: addons }) => {
      return {
        ...state,
        addons: addons,
      };
    },
    [rejectAddons]: (state, { payload: isFetchingAddons }) => ({
      ...state,
      isFetchingAddons: isFetchingAddons,
      isError: true,
      addons: [],
    }),
  },
  defaultState
);

export default addons;
