import { handleActions } from 'redux-actions';
import {
  receiveSystemErrors,
  receiveUserErrors,
  receiveReadCapacityUnits,
  receiveWriteCapacityUnits,
  receiveThrottledRequests,
  requestSystemErrors,
  requestUserErrors,
  requestReadCapacityUnits,
  requestWriteCapacityUnits,
  requestThrottledRequests,
  handleError,
  setStackSelection,
  setStackAccount,
  resetOptions,
} from './actions';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

const defaultState = {
  systemErrors: {},
  userErrors: {},
  readCapacityUnits: {},
  writeCapacityUnits: {},
  ThrottledRequests: {},
  isError: false,
  stackSelection: STACK_DEFAULT_OPTION.dev,
  selectedAccount: ENV_DEFAULT_OPTION,
};

const dynamoMatrix = handleActions(
  {
    [receiveSystemErrors]: (state, { payload: systemErrors }) => ({
      ...state,
      systemErrors: systemErrors,
    }),
    [receiveUserErrors]: (state, { payload: userErrors }) => ({
      ...state,
      userErrors: userErrors,
    }),
    [receiveReadCapacityUnits]: (state, { payload: readCapacityUnits }) => ({
      ...state,
      readCapacityUnits: readCapacityUnits,
    }),
    [receiveWriteCapacityUnits]: (state, { payload: writeCapacityUnits }) => ({
      ...state,
      writeCapacityUnits: writeCapacityUnits,
    }),
    [receiveThrottledRequests]: (state, { payload: throttledRequests }) => ({
      ...state,
      throttledRequests: throttledRequests,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [requestSystemErrors]: (state) => ({
      ...state,
      systemErrors: {},
      isError: false,
    }),
    [requestUserErrors]: (state) => ({
      ...state,
      userErrors: {},
      isError: false,
    }),
    [requestReadCapacityUnits]: (state) => ({
      ...state,
      readCapacityUnits: {},
      isError: false,
    }),
    [requestWriteCapacityUnits]: (state) => ({
      ...state,
      writeCapacityUnits: {},
      isError: false,
    }),
    [requestThrottledRequests]: (state) => ({
      ...state,
      ThrottledRequests: {},
      isError: false,
    }),
    [setStackSelection]: (state, { payload }) => ({
      ...state,
      stackSelection: payload,
      isError: false,
    }),
    [setStackAccount]: (state, { payload }) => ({
      ...state,
      selectedAccount: payload,
      isError: false,
    }),
    [resetOptions]: (state) => ({
      ...state,
      selectedAccount: defaultState.selectedAccount,
      stackSelection: defaultState.stackSelection,
      initialPageLoad: false,
    }),
  },
  defaultState
);

export default dynamoMatrix;
