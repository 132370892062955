/**
 * @ Assets
 */
import 'rc-table/assets/index.css';
import './shared/styles/global/reset-styles';
import './shared/styles/global/base-styles';
import './index.css';

/**
 * @ External Dependecies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

/**
 * @ Internal Dependecies
 */
import store from './core/redux/store';

/**
 * @ Components
 */
import App from 'core/components/App';
import { GlobalBaseStyles } from './shared/styles/global/base-styles';
import { GlobalResetStyles } from './shared/styles/global/reset-styles';
import { GlobalTableStyles } from './shared/styles/global/table-styles';

const base = (
  <Provider store={store}>
    <GlobalResetStyles />
    <GlobalBaseStyles />
    <GlobalTableStyles />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(base, document.getElementById('root'));
reportWebVitals();
