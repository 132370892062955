/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'shared/constants/colors';
export const DownloadBtn = styled.div`
  display: flex;
  justify-content: end;
  .downloadBtn {
    padding: 0;
    margin-top: 0.5rem;
  }
  .downloadBtn a {
    text-decoration: none;
    font-size: 12px;
    padding: 10px 31px;
    display: block;
  }

  ${(props) =>
    props.disabled
      ? `
    button {
      opacity: 0.5;
      pointer-events: none;
      margin: 0;
    }
  `
      : ''}
`;
export const JsonTree = styled.div`
  display: flex;
  margin-top: 5px;
  .tree-grid {
    width: 50%;
    overflow: auto;
    min-height: 70px;
  }
  .variable-value div {
    color: ${colors.midnight} !important;
  }
  .collapsed-icon {
    svg {
      color: ${colors.midnight} !important;
    }
  }
  .expanded-icon {
    svg {
      color: ${colors.midnight} !important;
    }
  }
  .node-ellipsis {
    color: ${colors.midnight} !important;
  }

  .copy-icon {
    display: none;
  }

  span {
    color: ${colors.midnight} !important;
  }
`;

export const JsonExpandCollapse = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 11px;
  cursor: pointer;
  color: #1c1c1c;
  text-decoration: underline;
`;
