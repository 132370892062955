import { users } from 'shared/constants/api';
import { apiCall } from 'core/api/API';
import moment from 'moment';

// add user details keys for searching
export const decorateUser = ({ userFromAPI }) => {
  userFromAPI.searchableUserId = userFromAPI.userId ? userFromAPI.userId : '-';
  userFromAPI.searchableUserName = userFromAPI.Username ? userFromAPI.Username : '-';
  userFromAPI.searchableEmail = userFromAPI.email ? userFromAPI.email : '-';
  userFromAPI.searchableTitle = userFromAPI.user_title ? userFromAPI.user_title : '-';
  userFromAPI.searchableUserRole = userFromAPI.user_role ? userFromAPI.user_role : '-';
  userFromAPI.searchableOrganizationName =
    userFromAPI?.organization && userFromAPI.organization?.organization_name
      ? userFromAPI.organization?.organization_name
      : '-';
  userFromAPI.tooltipOrganizationName =
    userFromAPI?.organization && userFromAPI.organization?.organization_name
      ? userFromAPI.organization?.organization_name
      : '-';
  userFromAPI.searchableFirstName = userFromAPI.given_name ? userFromAPI.given_name : '-';
  userFromAPI.searchableLastName = userFromAPI.family_name ? userFromAPI.family_name : '-';
  userFromAPI.searchablePartAndTotal = `${parseInt(userFromAPI.part, 10)}/${parseInt(userFromAPI.total, 10)}`;
  userFromAPI.sortablePartAndTotal = `${parseInt(userFromAPI.part, 10)}/${parseInt(userFromAPI.total, 10)}`;
  userFromAPI.searchableStatus = userFromAPI.status;
  userFromAPI.user_title = userFromAPI.user_title ? userFromAPI.user_title : '-';
  userFromAPI.Username = userFromAPI.Username ? userFromAPI.Username : '-';
  userFromAPI.email = userFromAPI.email ? userFromAPI.email : '-';
  userFromAPI.user_role = userFromAPI.user_role ? userFromAPI.user_role : '-';
  userFromAPI.given_name = userFromAPI.given_name ? userFromAPI.given_name : '-';
  userFromAPI.family_name = userFromAPI.family_name ? userFromAPI.family_name : '-';
  userFromAPI.searchableLastLoginTime = userFromAPI?.last_login_time
    ? moment(userFromAPI?.last_login_time).format('MMMM Do YYYY, h:mm:ss a')
    : '-';
  userFromAPI.formattedLastLoginTime = userFromAPI?.last_login_time
    ? moment(userFromAPI?.last_login_time).format('MMMM Do YYYY, h:mm:ss a')
    : '-';
  return userFromAPI;
};

export const userList = async (pageSize, lastEvaluatedKey, awsAccount, stack, allUsers, organizationId, username) => {
  let queryStringParameters;
  if (lastEvaluatedKey && awsAccount && organizationId && !allUsers && !username) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      organization_id: lastEvaluatedKey.organization_id,
      username: lastEvaluatedKey.Username,
      env: awsAccount,
      tableStackName: stack,
    };
  } else if (lastEvaluatedKey && awsAccount && !organizationId && allUsers && !username) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      organization_id: lastEvaluatedKey.organization_id,
      username: lastEvaluatedKey.Username,
      env: awsAccount,
      tableStackName: stack,
      allUsers: true,
    };
  } else if (!lastEvaluatedKey && awsAccount && allUsers && !username) {
    queryStringParameters = { pageSize: `${pageSize}`, env: awsAccount, tableStackName: stack, allUsers: true };
  } else if (!lastEvaluatedKey && awsAccount && organizationId && !username) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      organization_id: organizationId,
    };
  } else if (!lastEvaluatedKey && awsAccount && organizationId && username) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      organization_id: organizationId,
      searchText: username,
    };
  } else if (!lastEvaluatedKey && awsAccount && !organizationId && username) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      env: awsAccount,
      tableStackName: stack,
      searchText: username,
    };
  }
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: users,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};

export const resetUser = async (userDetails) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/users/${userDetails.user.Username}/reset`,
    method: 'post',
    body: {
      env: userDetails.awsAccount,
      email: userDetails.user.email,
      phoneNumber: userDetails.user.phone_number,
      givenName: userDetails.user.given_name,
      familyName: userDetails.user.family_name,
      organizationId: userDetails.user.organization_id,
      organizationName: userDetails.user?.organization?.organization_name
        ? userDetails.user?.organization?.organization_name
        : '-',
      stackName: userDetails.stack.label,
      tableStackName: userDetails.stack.value,
    },
  });
};
