import { apiCall } from 'core/api/API';
import { refreshedToken } from 'shared/constants/api';

export const getRefreshedToken = async (refreshKey) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: refreshedToken,
    method: 'post',
    body: {
      refreshToken: refreshKey,
    },
  });
};
