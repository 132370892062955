/**
 * @ External Dependencies
 */
import { handleActions } from 'redux-actions';

/**
 * @ Actions
 */
import { openSidebar, closeSidebar, endCloseSidebarTransition, toggleSidebar } from './actions';

/**
 * @ Reducer
 */
const defaultState = {
  visible: false,
  openedOnClick: false,
  transitioning: false,
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const sidebar = handleActions(
  {
    [openSidebar]: (state) => ({
      ...state,
      visible: true,
      transitioning: true,
    }),

    [closeSidebar]: (state) => ({
      ...state,
      visible: false,
      transitioning: true,
    }),

    [endCloseSidebarTransition]: (state) => ({
      ...state,
      transitioning: false,
    }),

    [toggleSidebar]: (state) => ({
      ...state,
      visible: !state.visible,
      openedOnClick: !state.openedOnClick,
      transitioning: true,
    }),
  },
  defaultState
);

export default sidebar;
