import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import JobDetails from './jobDetails';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getJobs, decorateJob, getDeviceJobs } from 'core/api/jobs';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

const jobList = ({
  initialPageLoad,
  setInitialLoads,
  showNotification,
  hideEnvDropdown,
  selectedAccount,
  setSelectedAccount,
  deviceId,
}) => {
  const [resetPageNo, setResetPageNo] = useState(false);
  const { jobId, awsAccount } = useParams();
  const [jobs, setJobs] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false);
  const [isFetchingJobs, setIsFetchingJobs] = useState(false);

  // API call to fetch the data
  const fetchJobs = async (pageSize = PAGINATION_DEFAULT_OPTION.value, lastEvaluatedKey1, awsAccount) => {
    try {
      setIsFetchingJobs(true);
      const { response } = await getJobs(pageSize, lastEvaluatedKey1, awsAccount);
      if (response.message) {
        let temp = [];
        response.message.map((job) =>
          temp.push(
            decorateJob({
              jobFromAPI: job,
            })
          )
        );
        setInitialLoads(true);
        setJobs((previous) => previous.concat(temp));
        response?.nextToken ? setIsFetchingJobs(true) : setIsFetchingJobs(false);
        response?.nextToken ? setLastEvaluatedKey(response?.nextToken || false) : null;
      } else {
        setInitialLoads(true);
        setIsFetchingJobs(false);
      }
    } catch (e) {
      console.error(e);
      setInitialLoads(true);
      setIsFetchingJobs(false);
      showNotification(e);
    }
  };

  // API call to fetch device related jobs
  const fetchDeviceJobs = async (deviceId, pageSize = PAGINATION_DEFAULT_OPTION.value, nextToken, awsAccount = '') => {
    try {
      setIsFetchingJobs(true);
      const { response } = await getDeviceJobs(deviceId, pageSize, nextToken, awsAccount);
      if (response.message) {
        let temp = [];
        response.message.map((job) =>
          temp.push(
            decorateJob({
              jobFromAPI: job,
            })
          )
        );
        setInitialLoads(true);
        setJobs((previous) => previous.concat(temp));
        response?.nextToken ? setIsFetchingJobs(true) : setIsFetchingJobs(false);
        response?.nextToken ? setLastEvaluatedKey(response?.nextToken || false) : null;
      } else {
        setInitialLoads(true);
        setIsFetchingJobs(false);
      }
    } catch (e) {
      console.error(e);
      setInitialLoads(true);
      setIsFetchingJobs(false);
      showNotification(e);
    }
  };

  // check for lastEvaluatedKey state and fetch next set of records.
  useEffect(() => {
    if (lastEvaluatedKey && Object.keys(lastEvaluatedKey).length > 0) {
      if (deviceId) {
        fetchDeviceJobs(deviceId, undefined, lastEvaluatedKey, selectedAccount);
      } else {
        fetchJobs(undefined, lastEvaluatedKey, selectedAccount.value.toLowerCase());
      }
    }
    return () => false;
  }, [lastEvaluatedKey]);

  // To fethc records on page load for job list page
  useEffect(() => {
    if (!jobId && !jobs.length && !deviceId) {
      fetchJobs(undefined, lastEvaluatedKey, selectedAccount.value.toLowerCase());
    }
  }, [jobId]);

  // To fetch device jobs when device detail job page gets load initially
  useEffect(() => {
    if (deviceId) {
      setInitialLoads(false);
      fetchDeviceJobs(deviceId, undefined, undefined, selectedAccount);
    }
  }, [deviceId]);

  // To load the jobs wehn user clicks on refresh button
  const refreshJobs = () => {
    setResetPageNo(!resetPageNo);
    setJobs([]);
    setLastEvaluatedKey(false);
    if (deviceId) {
      setInitialLoads(false);
      fetchDeviceJobs(deviceId, undefined, undefined, selectedAccount);
    } else {
      fetchJobs(undefined, undefined, selectedAccount.value.toLowerCase());
    }
  };

  // To load the data when user change the account dropdown value
  const optionChangeHandler = (e) => {
    setLastEvaluatedKey(false);
    setSelectedAccount(e);
    setResetPageNo(!resetPageNo);
    setJobs([]);
    fetchJobs(undefined, undefined, e.label.toLowerCase());
  };

  let columns = [
    {
      title: 'Job Id',
      dataIndex: 'jobId',
      searchIndex: 'searchableJobId',
      key: 'jobId',
      searchable: true,
      width: '33%',
      render(value, job) {
        return (
          <Link
            to={`/job-status/${encodeURIComponent(job.jobId)}/${
              selectedAccount?.value ? selectedAccount.value.toLowerCase() : selectedAccount
            }`}
            style={{ textDecoration: 'none' }}
          >
            <span title={'Job Id: ' + (value ? value : '-')} className="jobNameAndIcon">
              <IconSvgComponent svgFileName={'gears'} alt="Job" />
              <TruncatedText jobid>{job.searchableJobId}</TruncatedText>
            </span>
          </Link>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      searchIndex: 'searchableName',
      key: 'name',
      searchable: true,
      width: '20%',
      render(value, job) {
        return (
          <span title={'Name: ' + (value ? value : '-')}>
            <TruncatedText jobname>{job.searchableName}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      searchIndex: 'searchableType',
      key: 'type',
      searchable: true,
      width: '8%',
      render(value, job) {
        return <span title={'Job Type: ' + (value ? value : '-')}>{job.searchableType}</span>;
      },
    },
    {
      title: 'Version',
      dataIndex: 'version',
      searchIndex: 'searchableVersion',
      key: 'version',
      searchable: true,
      width: '8%',
      render(value, job) {
        return <span title={'Version: ' + (value ? value : '-')}>{job.searchableVersion}</span>;
      },
    },
    {
      title: 'Part/Total',
      dataIndex: 'sortablePartAndTotal',
      searchIndex: 'searchablePartAndTotal',
      key: 'partAndTotal',
      searchable: true,
      width: '8%',
      render(value, job) {
        return (
          <span title={'Part/Total: ' + (job.sortablePartAndTotal ? job.sortablePartAndTotal : '-')}>
            {job.searchablePartAndTotal}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      searchIndex: 'searchableStatus',
      key: 'status',
      searchable: true,
      width: '9%',
      render(value, job) {
        return <span title={'Status: ' + (value ? value : '-')}>{job.searchableStatus}</span>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      searchIndex: 'searchableCreatedAt',
      key: 'createdAt',
      searchable: true,
      width: '15%',
      render(value, job) {
        return (
          <span title={'Created At: ' + (job.formattedCreatedAt ? job.formattedCreatedAt : '-')}>
            {job.searchableCreatedAt}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      {!initialPageLoad && ((!jobId && !awsAccount) || deviceId) ? <LoadingOverlay /> : null}
      {jobId && awsAccount ? <JobDetails jobId={jobId} awsAccount={awsAccount} /> : null}
      <span className={jobId && awsAccount ? 'hideDeviceList' : ''}>
        <PageListHead>
          <PageListTitle>Jobs</PageListTitle>
          <PageListActionButtonContainer>
            <LoadingText>{isFetchingJobs ? 'Fetching records ...' : null}</LoadingText>
            <HeaderButtonDiv cancel>
              <ButtonWithLoader
                disabled={isFetchingJobs}
                isLoading={isFetchingJobs}
                confirmText={'Refresh'}
                loadingStyleProp={'submittingWithSpinner'}
                notLoadingStyleProp={'refresh'}
                clickHandler={refreshJobs}
              />
            </HeaderButtonDiv>
          </PageListActionButtonContainer>
        </PageListHead>
        Total of {jobs.length} Jobs
        <SortableTable
          className="table-simple"
          columns={columns}
          tableData={jobs}
          scroll={{ x: false, y: false }}
          rowKey={(record) => record.jobId}
          refresh={resetPageNo}
          emptyText={ERROR_MESSAGES.EMPTY_TEXT}
          optionChangeHandler={optionChangeHandler}
          selectedAccount={selectedAccount}
          hideEnvDropdown={hideEnvDropdown}
        />
      </span>
    </div>
  );
};

export default jobList;
