/**
 * External dependencies
 */
import { Amplify, API } from 'aws-amplify';
import apiUrl, { getLogoutRedirectUrl } from 'shared/utilities/url';
import { clearAuthStore, getRefreshToken, isExpired, getIdToken } from 'shared/utilities/localStore';
import { loggedOut, fetchRefreshedToken } from 'core/redux/user/actions';
import store from '../redux/store';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const getAuthToken = () => {
  return getIdToken();
};

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'cloudfrontAPI',
        endpoint: apiUrl,
        custom_header: async () => {
          return {
            Authorization: getAuthToken(),
          };
        },
      },
    ],
  },
});

const extractServerError = (e) => {
  return e.response.data && e.response.data.errorMessage ? e.response.data.errorMessage : ERROR_MESSAGES.TRY_LATER; // if you see this error, backend is not sending data.errorMessage
};

export const apiCall = async ({ endpoint, path, method, queryStringParameters, body, headers, responseType }) => {
  try {
    // generate refreshed access token by using refreshed token
    if (!path.toString().includes('/authTokens') && isExpired() && !path.toString().includes('/refreshToken')) {
      await store.dispatch(fetchRefreshedToken(getRefreshToken()));
    }

    const apiResponse = await API[method](endpoint || 'cloudfrontAPI', path, {
      responseType,
      response: true,
      queryStringParameters: queryStringParameters,
      body,
      headers,
    });

    return { response: apiResponse.data };
  } catch (e) {
    if (!e.response) {
      throw ERROR_MESSAGES.SERVER_ERROR;
    } else if (!e.response.status) {
      // if you see this error, backend is not sending response.status
      throw extractServerError(e);
    } else if (e.response.status === 401) {
      // In case of token expired
      if (e.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
        clearAuthStore();
        loggedOut();
        window.location.href = getLogoutRedirectUrl();
        throw ERROR_MESSAGES.SESSION_EXPIRED;
      }
      throw extractServerError(e);
    }
    throw extractServerError(e);
  }
};
