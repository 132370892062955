import { connect } from 'react-redux';
import { loggedOut } from 'core/redux/user/actions';
import { clearAuthStore } from 'shared/utilities/localStore';
import { getLogoutRedirectUrl } from 'shared/utilities/url';

const logout = ({ loggedOut }) => {
  const logoutHandler = async () => {
    await clearAuthStore();
    await loggedOut();
    window.location.href = getLogoutRedirectUrl();
  };
  return logoutHandler();
};

export default connect('', (dispatch) => ({
  loggedOut: () => dispatch(loggedOut()),
}))(logout);
