import { createAction } from 'redux-actions';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { addNotification } from '../ui/actions';
import { decorateSerializedDevices, serializedDevicesList } from 'core/api/serialDevice';

export const requestSerialdevices = createAction('REQUEST_SERIALDEVICES');
export const receiveSerialdevices = createAction('RECEIVE_SERIALDEVICES');
export const rejectSerialdevices = createAction('REJECT_SERIALDEVICES');
export const setFetchingSerialdevices = createAction('SET_FETCHING_SERIALDEVICES');
export const resetSerialdevices = createAction('RESET_SERIALDEVICES');

// fetch serialized Devices list on load
export const fetchSerialDevices = () => {
  return async (dispatch) => {
    try {
      const { response } = await serializedDevicesList();
      dispatch(setFetchingSerialdevices(false));
      if (response) {
        dispatch(
          receiveSerialdevices(
            response.message.items.map((device) =>
              decorateSerializedDevices({
                serialData: device,
              })
            )
          )
        );
      }
      return true;
    } catch (e) {
      dispatch(rejectSerialdevices(false));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_SERIAL_DEVICE, e),
        })
      );
      return false;
    }
  };
};

// calls when click on refresh button from serial device list page
export const clearAndRestoreSerial = () => {
  return async (dispatch) => {
    dispatch(setFetchingSerialdevices(true));
    dispatch(resetSerialdevices([]));
    return dispatch(fetchSerialDevices());
  };
};
