import { createAction } from 'redux-actions';

import * as lambdaMatrix from 'core/api/lambdaMatrix';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receiveInvocations = createAction('RECEIVE_INVOCATIONS');
export const receiveDuration = createAction('RECEIVE_DURATION');
export const receiveThrottles = createAction('RECEIVE_THROTTLES');
export const receiveErrors = createAction('RECEIVE_ERRORS');

export const requestInvocations = createAction('REQUEST_INVOCATIONS');
export const requestDuration = createAction('REQUEST_DURATION');
export const requestThrottles = createAction('REQUEST_THROTTLES');
export const requestErrors = createAction('REQUEST_ERRORS');

export const handleError = createAction('HANDLE_ERROR');
export const setStackSelection = createAction('LAMBDA_STACK_SELECTION');
export const setStackAccount = createAction('LAMBDA_STACK_ACCOUNT');
export const resetOptions = createAction('LAMBDA_OPTIONS');

// Function to fetch the lambda invocation details
export const fetchLambdaInvocations = (params) => {
  return async (dispatch) => {
    dispatch(requestInvocations());
    try {
      const { response } = await lambdaMatrix.getLambdaInvocations(params);
      dispatch(receiveInvocations(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_LAMBDA_INV, e),
        })
      );
      return false;
    }
  };
};

// Function to fetch the lambda duration details
export const fetchLambdaDuration = (params) => {
  return async (dispatch) => {
    try {
      dispatch(requestDuration());
      const { response } = await lambdaMatrix.getLambdaDuration(params);
      dispatch(receiveDuration(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_LAMBDA_DURATION, e),
        })
      );
      return false;
    }
  };
};

// Function to fetch the lambda throttles details
export const fetchLambdaThrottles = (params) => {
  return async (dispatch) => {
    try {
      dispatch(requestThrottles());
      const { response } = await lambdaMatrix.getLambdaThrottles(params);
      dispatch(receiveThrottles(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_LAMBDA_THROTTLES, e),
        })
      );
      return false;
    }
  };
};

// Function to fetch the lambda error details
export const fetchLambdaErrors = (params) => {
  return async (dispatch) => {
    try {
      dispatch(requestErrors());
      const { response } = await lambdaMatrix.getLambdaErrors(params);
      dispatch(receiveErrors(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_LAMBDA_ERRORS, e),
        })
      );
      return false;
    }
  };
};
// set state when selecting Stack
export const stackSelection = (selection) => {
  return async (dispatch) => {
    return dispatch(setStackSelection(selection));
  };
};

// set state when selecting Account
export const stackAccount = (selection) => {
  return async (dispatch) => {
    dispatch(setStackAccount(selection));
  };
};

// reset states to its default on changing page.
export const resetEnvStackOptions = () => {
  return async (dispatch) => {
    dispatch(resetOptions());
  };
};
