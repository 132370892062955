/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'shared/constants/colors';

export const Tab = styled.li`
  display: block;
  flex: 0 1 25%;
  padding-bottom: 12px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  text-align: center;
  color: ${colors.haze};
  cursor: pointer;

  ${(props) =>
    props.active &&
    `
    color: ${colors.midnight};
    border-bottom: solid 2px ${colors.charcoal};
  `};
`;

export const ShadowNav = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto 32.5px;
`;
