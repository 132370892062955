/**
 * @ External Dependencies
 */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { throttle } from 'lodash';
/**
 * @ Reducers
 */
import ui from './ui/reducer';
import sidebar from './sidebar/reducer';
import jobs from './jobs/reducer';
import deviceOperations from './deviceOperations/reducer';
import dynamoMatrix from './dynamoMatrix/reducer';
import gatewayMatrix from './gatewayMatrix/reducer';
import lambdaMatrix from './lambdaMatrix/reducer';
import jobDetails from './jobDetails/reducer';
import iotMatrix from './iotMatrix/reducer';
import user from './user/reducer';
import userActivityLogs from './userActivityLogs/reducer';
import envCounts from './envCounts/reducer';
import org from './organizations/reducer';
import serialDevice from './serializedDevice/reducer';
import addons from './addOn.js/reducer';

const appReducer = combineReducers({
  ui,
  sidebar,
  jobs,
  jobDetails,
  deviceOperations,
  dynamoMatrix,
  iotMatrix,
  gatewayMatrix,
  lambdaMatrix,
  user,
  userActivityLogs,
  envCounts,
  org,
  serialDevice,
  addons,
  cached: () => ({}),
});

//https://stackoverflow.com/a/35641992
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const withoutReduxDevTools = applyMiddleware(thunkMiddleware);

const middleware = () => {
  return withoutReduxDevTools;
};

let persistedState = {
  cached: {},
};

let store = createStore(rootReducer, persistedState, middleware());

store.subscribe(
  throttle(() => {}),
  1000
);

export default store;
