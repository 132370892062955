import { jobs, jobDetail, device } from 'shared/constants/api';
import { apiCall } from 'core/api/API';
import { getFormattedDuid } from 'shared/utilities/general';
import moment from 'moment';

export const decorateJob = ({ jobFromAPI }) => {
  jobFromAPI.searchableJobId = jobFromAPI.jobId ? jobFromAPI.jobId : '-';
  jobFromAPI.searchableName = jobFromAPI.name ? jobFromAPI.name : '-';
  jobFromAPI.searchableType = jobFromAPI.type ? jobFromAPI.type : '-';
  jobFromAPI.searchableVersion = jobFromAPI.version ? jobFromAPI.version : '-';
  jobFromAPI.searchablePartAndTotal = `${parseInt(jobFromAPI.part, 10)}/${parseInt(jobFromAPI.total, 10)}`;
  jobFromAPI.sortablePartAndTotal = `${parseInt(jobFromAPI.part, 10)}/${parseInt(jobFromAPI.total, 10)}`;
  jobFromAPI.searchableStatus = jobFromAPI.status ? jobFromAPI.status : '-';
  jobFromAPI.searchableCreatedAt = jobFromAPI.createdAt
    ? moment(jobFromAPI.createdAt).format('MMMM Do YYYY, h:mm:ss a')
    : '-';
  jobFromAPI.formattedCreatedAt = jobFromAPI.createdAt
    ? moment(jobFromAPI.createdAt).format('MMMM Do YYYY, h:mm:ss a')
    : '-';
  return jobFromAPI;
};

export const decorateJobTarget = ({ jobTargetFromAPI }) => {
  const date = new Date(jobTargetFromAPI.queuedAt);
  const formattedDate = moment(date.toLocaleString('en-US')).format('MMMM Do YYYY, h:mm:ss a');

  jobTargetFromAPI.searchableJobName = getFormattedDuid(JSON.parse(JSON.stringify(jobTargetFromAPI.thingName)));
  jobTargetFromAPI.rawVSGID = JSON.parse(JSON.stringify(jobTargetFromAPI.thingName));
  jobTargetFromAPI.searchableStatus = jobTargetFromAPI.status;
  jobTargetFromAPI.searchableQueuedAt = formattedDate;
  jobTargetFromAPI.searchableQueuedAtTooltip = formattedDate;

  return jobTargetFromAPI;
};

export const getJobDetails = async (jobInfo) => {
  const awsAccount = { env: jobInfo.awsAccount };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${jobDetail}/${jobInfo.jobId}`,
    queryStringParameters: awsAccount,
    method: 'get',
  });
};

export const getJobs = async (pageSize, nextToken, awsAccount) => {
  let queryStringParameters;
  if (nextToken && awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, next_token: nextToken, env: awsAccount };
  } else if (!nextToken && awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, env: awsAccount };
  } else if (nextToken && !awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, next_token: nextToken };
  } else if (!nextToken && !awsAccount) {
    queryStringParameters = { page_size: `${pageSize}` };
  }

  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: jobs,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};

export const getDeviceJobs = async (deviceId, pageSize, nextToken, awsAccount) => {
  let queryStringParameters;
  if (nextToken && awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, next_token: nextToken, env: awsAccount };
  } else if (!nextToken && awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, env: awsAccount };
  } else if (nextToken && !awsAccount) {
    queryStringParameters = { page_size: `${pageSize}`, next_token: nextToken };
  } else if (!nextToken && !awsAccount) {
    queryStringParameters = { page_size: `${pageSize}` };
  }

  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${device}/${deviceId}${jobs}`,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};
