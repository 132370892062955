import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';

import { closeSidebar, openSidebar } from 'core/redux/sidebar/actions';
import Nav, {
  NavList,
  NavItem,
  NavButton,
  NavLink,
  NavButtonText,
  NavButtonIcon,
  NavDropdown,
  NavLinkItem,
} from 'shared/styles/components/Nav';
import { getGuardedObjectProp } from 'shared/utilities/general';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import colors from 'shared/constants/colors';

class NavComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: this.getNavItems().reduce((activeIndex, navItem, navItemIndex) => {
        return activeIndex
          ? activeIndex
          : navItem.dropdown
          ? navItem.dropdown.reduce((dropdownItem, dropdownItemIndex) =>
              matchPath(props.location.pathname, { path: dropdownItem.link }) ? dropdownItemIndex : null
            )
          : matchPath(props.location.pathname, { path: navItem.link })
          ? navItemIndex
          : null;
      }, null),
      lastActiveIndex: null,
    };
  }

  getNavItems = () => [
    {
      title: 'Dashboard',
      icon_path: 'analyticsDashboard',
      link: '/dashboard',
    },
    {
      title: 'Organizations',
      icon_path: 'organizations',
      link: '/organizations',
      exact: false,
    },
    {
      title: 'Users',
      icon_path: 'users',
      link: '/users',
    },
    {
      title: 'Job Status',
      icon_path: 'gears-grey',
      link: '/job-status',
    },
    {
      title: 'Device Operation',
      icon_path: 'car',
      link: '/device-operations',
    },
    {
      title: 'Reports',
      icon_path: 'report',
      link: '/device-reports',
    },
    {
      title: 'Serialized Devices',
      icon_path: 'list',
      link: '/serialized-devices',
    },
    {
      title: 'Audit Trail',
      icon_path: 'logs',
      link: '/audit-trail',
      exact: false,
    },
    {
      title: 'Tutorial',
      icon_path: 'pdf',
    },
    {
      title: 'Sign Out',
      icon_path: 'logout-icon',
      link: '/logout',
      exact: false,
    },
  ];

  pathMatchesIndex(path, matchIndex) {
    const { getNavItems, isActiveParent } = this;
    const navItems = getNavItems();
    return getGuardedObjectProp('dropdown', navItems[matchIndex])
      ? isActiveParent(navItems[matchIndex].dropdown, matchIndex)
      : matchPath(path, { path: getGuardedObjectProp('link', navItems[matchIndex]) });
  }

  componentDidUpdate(lastProps) {
    const { props, state } = this;
    if (
      !lastProps.sidebarIsCollapsed &&
      props.sidebarIsCollapsed &&
      !this.pathMatchesIndex(props.location.pathname, state.activeIndex)
    ) {
      this.setState({
        activeIndex: state.lastActiveIndex || null,
        lastActiveIndex: null,
      });
    }
  }

  isActiveParent = (dropdown, index) => {
    const { pathname } = this.props.location;
    const { activeIndex } = this.state;
    const anyChildActive = dropdown.some((item) => matchPath(pathname, { path: item.link }));
    const isActiveParent = anyChildActive && (activeIndex === null || activeIndex === index);
    return isActiveParent;
  };

  handleNavButtonClick = (index, link) => () => {
    const { sidebarIsCollapsed, openSidebar } = this.props;

    // on smaller devices clicking on nav opens the drawer
    if (sidebarIsCollapsed) {
      openSidebar();

      // if a nav item that is a link was clicked
    } else if (link) {
      this.closeSidebar();
      this.setState({ activeIndex: index });
      // else when clicking on a non-link nav item
    } else {
      this.setState({
        activeIndex: index,
        lastActiveIndex: this.state.activeIndex,
      });
    }
  };

  closeSidebar = () => this.props.closeSidebar();

  render() {
    const { state, props, isActiveParent, handleNavButtonClick, getNavItems, closeSidebar } = this;
    const { activeIndex } = state;
    const { sidebarIsCollapsed } = props;
    return (
      <Nav sidebarIsCollapsed={sidebarIsCollapsed}>
        <NavList>
          {getNavItems().map((item, index) => (
            <NavItem key={index}>
              {item.title === 'Tutorial' ? (
                <NavLinkItem href="./pdf/Tutorial.pdf" target="_blank" rel="noopener noreferrer" onClick={closeSidebar}>
                  <NavButtonIcon>
                    <IconSvgComponent
                      svgFileName={item.icon_path}
                      style={{ marginLeft: item.leftOffset, marginRight: item.rightOffset }}
                    />
                  </NavButtonIcon>
                  <NavButtonText>{item.title}</NavButtonText>
                </NavLinkItem>
              ) : item.link ? (
                <NavLink
                  to={item.link}
                  sidebarIsCollapsed={sidebarIsCollapsed}
                  onClick={handleNavButtonClick(index, item.link)}
                >
                  <NavButtonIcon>
                    <IconSvgComponent
                      svgFileName={item.icon_path}
                      style={{ marginLeft: item.leftOffset, marginRight: item.rightOffset }}
                    />
                  </NavButtonIcon>

                  <NavButtonText>{item.title}</NavButtonText>
                </NavLink>
              ) : (
                <NavButton
                  sidebarIsCollapsed={sidebarIsCollapsed}
                  hasDropdown={item.dropdown ? true : false}
                  isActiveParent={isActiveParent(item.dropdown, index)}
                  onClick={handleNavButtonClick(index)}
                >
                  <NavButtonIcon>
                    <IconSvgComponent
                      svgFileName={item.icon_path}
                      style={{ marginLeft: item.leftOffset, marginRight: item.rightOffset }}
                    />
                  </NavButtonIcon>

                  <NavButtonText>{item.title}</NavButtonText>
                </NavButton>
              )}

              {item.dropdown && (
                <AnimateHeight
                  duration={300}
                  height={
                    (activeIndex === index || isActiveParent(item.dropdown, index)) && !sidebarIsCollapsed ? 'auto' : 0
                  }
                >
                  <NavDropdown
                    sidebarIsCollapsed={sidebarIsCollapsed}
                    innerRef={(comp) => {
                      this.dropdown = comp;
                    }}
                  >
                    <NavList>
                      {item.dropdown.map((innerItem) => (
                        <NavItem key={innerItem.title}>
                          {innerItem.link ? (
                            <NavLink to={innerItem.link} exact={innerItem.exact} onClick={closeSidebar}>
                              <NavButtonIcon>
                                <IconSvgComponent
                                  svgFileName={innerItem.icon_path}
                                  style={{
                                    marginLeft: innerItem.leftOffset,
                                    marginRight: innerItem.rightOffset,
                                  }}
                                  beforeInjection={(svg) => {
                                    svg
                                      .querySelectorAll('path')
                                      .forEach((path) => path.setAttribute('fill', colors.haze));
                                  }}
                                />
                              </NavButtonIcon>

                              <NavButtonText>{innerItem.title}</NavButtonText>
                            </NavLink>
                          ) : (
                            <NavButton>
                              <NavButtonIcon>
                                <IconSvgComponent svgFileName={innerItem.icon_path} />
                              </NavButtonIcon>

                              <NavButtonText>{innerItem.title}</NavButtonText>
                            </NavButton>
                          )}
                        </NavItem>
                      ))}
                    </NavList>
                  </NavDropdown>
                </AnimateHeight>
              )}
            </NavItem>
          ))}
        </NavList>
      </Nav>
    );
  }
}

NavComponent.propTypes = {
  sidebarIsCollapsed: PropTypes.bool,
};

export default withRouter(connect(null, { closeSidebar, openSidebar })(NavComponent));
