import { handleActions } from 'redux-actions';
import { receiveEnvCounts, requestEnvCounts, handleError } from './actions';

const defaultState = {
  envCountDetails: {},
  isError: false,
};

const envCounts = handleActions(
  {
    [receiveEnvCounts]: (state, { payload: envCountDetails }) => ({
      ...state,
      envCountDetails: envCountDetails,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [requestEnvCounts]: (state) => ({
      ...state,
      envCountDetails: {},
      isError: false,
    }),
  },
  defaultState
);

export default envCounts;
