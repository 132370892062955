import React, { useState, useEffect } from 'react';
import { SORT_ORDER } from 'shared/constants/table';
import { tableColumnSort } from 'shared/utilities/table';
import { connect } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import useAPIData from 'shared/hooks/useAPIData';
import { Link, useHistory, useParams } from 'react-router-dom';
import { clearAndRestoreDevices, device_Reset } from 'core/redux/deviceOperations/actions';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText, ReportDropdown, ButtonLink } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { EnvSelect } from 'shared/styles/components/SelectField';
import EvnChangeConfirmationModal from 'shared/modals/evnChangeConfirmationModal';
import { TableSpinner } from 'shared/styles/components/Spinner';
import { ERROR_MESSAGES, SUCCESS_MESSAGES, PAGE_INFO } from 'shared/constants/messages';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION, PROD, DEV } from 'shared/constants/matrixOptions';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import OrgDetail from 'features/userOrganization/orgDetail';
import VsgDetail from 'features/userOrganization/vsgDetail';
import threeDots from '../../../assets/svg/three-dots.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownMenu, DropboxToggle } from '../../../shared/styles/components/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';
import { ReactComponent as ReactLogo } from '../../../assets/svg/download.svg';
import { getUnformattedDuid, toGetDate_TimeFormatToLocalOrUTC } from 'shared/utilities/general';
import ResetVehicleConfirmationModal from 'shared/modals/resetVehicleConfirmationModal';
import { ReactSelectDefaultStyles } from 'shared/styles/components/SelectField';
import DeviceInfo from './deviceInfo';
import DeviceReports from './deviceReports';

import { getDeviceJobs, decorateJob } from 'core/api/jobs';
import { getDevices, decorateDevice, getVsgInfoApi } from 'core/api/deviceOperations';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { getStackTable } from 'shared/utilities/localStore';
import { clearAndRestoreAddons } from 'core/redux/addOn.js/action';
import { ButtonWithSpinnerContainer } from 'shared/styles/components/Button';
import { ButtonSpinnerSmall } from 'shared/styles/components/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOrg,
  setSearchTextGlobal,
  setExternalSearch,
  setClearSearchText,
} from '../../../core/redux/deviceOperations/actions';
import spinner from '../../../assets/images/loader-sp.gif';

const deviceList = ({
  isError,
  envList,
  hideEnvDropdown,
  device_Reset,
  setInitialLoads,
  stackSelection,
  selectedStack,
  setStackAccount,
  selectedAccount,
  showNotification,
  clearAndRestoreAddons,
  addons,
  initialPageLoad,
  reloadComponent,
  orgs,
  isFetchingOrgs,
  refreshOrgList,
}) => {
  const [resetPageNo, setResetPageNo] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [active, setActive] = useState(false);
  const [orgModalDetails, setOrgModalDetails] = useState([]);
  const [resetModal, setResetModal] = useState(false);
  const [currentDeviceId, setCurrentDeviceId] = useState(false);

  const [device, setDevice] = useState([]);
  const ONLINE = 'Online';
  const OFFLINE = 'Offline';
  const [devices, setDevices] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false);
  const [isFetchingDevices, setIsFetchingDevices] = useState(false);
  const history = useHistory();
  const { deviceId, awsAccount } = useParams();
  const [vsgactive, setvsgActive] = useState(false);
  const [vsgModalDetails, setvsgModalDetails] = useState({});
  const { selectedOrg, searchTextDevice, externalSearch, clearSearchText } = useSelector(
    (state) => state.deviceOperations
  );
  const [localMatch, setLocalMatch] = useState(false);
  const [unformatDeviceId, setUnformatDeviceId] = useState(null);
  const [additionalSearch, setAdditionalSearch] = useState(false);
  const [currentDeviceIdSearch, setCurrentDeviceIdSearch] = useState(null);

  const dispatch = useDispatch();

  // API call for fetching device data.
  const fetchDevices = async (
    pageSize = PAGINATION_DEFAULT_OPTION.value,
    lastEvaluatedKey1,
    org = null,
    awsAccount,
    stack,
    deviceId = null
  ) => {
    try {
      setIsFetchingDevices(true);
      const { response } = await getDevices(pageSize, lastEvaluatedKey1, org?.value, awsAccount, stack, deviceId);
      if (response.message) {
        let temp = [];
        response.message.map((devices) =>
          temp.push(
            decorateDevice({
              deviceFromAPI: devices,
            })
          )
        );
        if (deviceId) {
          // maintain flag if searching with device id
          setAdditionalSearch(true);
        }
        setDevices((previous) => previous.concat(temp));
        response.lastEvaluatedKey ? setIsFetchingDevices(true) : setIsFetchingDevices(false);
        response?.lastEvaluatedKey ? setLastEvaluatedKey(response?.lastEvaluatedKey || false) : null;
      } else {
        setIsFetchingDevices(false);
      }
    } catch (e) {
      setIsFetchingDevices(false);
      console.log(e);
      setInitialLoads(true);
      showNotification(e);
    }
  };

  // check for lastEvaluatedKey state and fetch next set of records.
  useEffect(() => {
    if (lastEvaluatedKey && Object.keys(lastEvaluatedKey).length > 0 && selectedOrg) {
      fetchDevices(undefined, lastEvaluatedKey, selectedOrg, selectedAccount.value.toLowerCase(), selectedStack.value);
    }
    return () => false;
  }, [lastEvaluatedKey]);

  // on selecting or changing organization from downdown make API call to fetch devices
  useEffect(() => {
    dispatch(setClearSearchText((prev) => !prev));
    dispatch(setExternalSearch(false));
    if (selectedOrg && !externalSearch) {
      dispatch(setSearchTextGlobal(''));
      dispatch(setClearSearchText((prev) => !prev));
      fetchDevices(undefined, undefined, selectedOrg, selectedAccount.value.toLowerCase(), selectedStack.value);
    }
  }, [selectedOrg]);

  // If record is not found in local table than call additional API for searching.
  useEffect(() => {
    if (externalSearch && searchTextDevice) {
      const unformattedDeviceId = getUnformattedDuid(searchTextDevice);
      setUnformatDeviceId(unformattedDeviceId);
      fetchDevices(
        undefined,
        undefined,
        selectedOrg,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        unformattedDeviceId
      );
    }
    return () => false;
  }, [externalSearch, searchTextDevice]);

  const optionList = () => {
    if (selectedStack.label.toLowerCase() == PROD) {
      const str = getStackTable()[DEV].filter((a) => {
        return a.label !== selectedStack.label;
      });
      return tableColumnSort({ data: str, sortIndex: 'label', order: SORT_ORDER.ASC });
    } else {
      const prodStr = getStackTable()[selectedAccount.value.toLowerCase()].filter((a) => {
        return a.label !== selectedStack.label;
      });
      prodStr.push({ label: 'Prod', value: 'Prod' });
      return tableColumnSort({ data: prodStr, sortIndex: 'label', order: SORT_ORDER.ASC });
    }
  };

  const refreshDevices = () => {
    setResetPageNo(!resetPageNo);
    setDevices([]);
    clearAndRestoreAddons(selectedAccount.value.toLowerCase(), selectedStack.value);
    fetchDevices(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value);
  };
  const envChangeHandler = (e, device) => {
    const deviceDetail = JSON.parse(JSON.stringify(device));
    deviceDetail.destEnv = e.label;
    setConfirmationModalData(deviceDetail);
  };
  // on click reset button.
  const deviceReset = (device) => {
    setResetModal(true);
    setDevice(device);
  };
  // Env dropdown change handler
  const optionChangeHandler = (e) => {
    setStackAccount(e);
    stackSelection(STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
    refreshOrgList(e, STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
    dispatch(setSearchTextGlobal(''));
  };

  const stackChangeHandler = (e) => {
    if (e.value !== selectedStack.value) {
      setDevices([]);
      stackSelection(e);
      refreshOrgList(selectedAccount, e);
      dispatch(setSearchTextGlobal(''));
    }
  };

  // on change oraganization dropdown value
  const orgChangeHandler = (e) => {
    if (e?.value !== selectedOrg?.value) {
      dispatch(setOrg(e));
      setDevices([]);
      if (isFetchingDevices) {
        reloadComponent(Math.random(), false);
      }
    }
  };

  //to pass data in organization detail Modal
  const openOrgModal = (userDetail) => {
    if (Object.keys(userDetail.orgDetails).length !== 0) {
      setOrgModalDetails(userDetail.orgDetails);
      setActive(true);
    } else {
      setActive(false);
    }
  };

  // To get vsg Information
  const getVsgInfo = async (deviceId, vehicleId, orgId) => {
    vsgModalDetails.selectedStack = selectedStack;
    vsgModalDetails.selectedAccount = selectedAccount;
    vsgModalDetails.deviceId = deviceId;
    vsgModalDetails.vehicleId = vehicleId;
    vsgModalDetails.orgId = orgId;
    setvsgModalDetails(vsgModalDetails);
    setvsgActive(true);
  };

  // function for making API call and fetching record based on organization and device ID
  const additionalApiCallForDevice = async ({ org, deviceId }) => {
    // to prevent multiple blank search when loading in progress
    if (!org?.value && isFetchingDevices && (!deviceId || deviceId === currentDeviceIdSearch)) {
      return;
    }
    setCurrentDeviceIdSearch(deviceId);
    setAdditionalSearch(false);
    if (!org && deviceId) {
      // additional API call
      setDevices([]);
      const unformatDeviceId = getUnformattedDuid(deviceId);
      fetchDevices(
        undefined,
        undefined,
        null,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        unformatDeviceId
      );
    } else if (org && deviceId) {
      setDevices([]);
      if (isFetchingDevices) {
        setSearchTextGlobal(deviceId);
        dispatch(setExternalSearch(true));
        reloadComponent(Math.random()); // terminate current running calls for devices.
      } else {
        dispatch(setExternalSearch(false));
        const unformatDeviceId = getUnformattedDuid(deviceId);
        fetchDevices(
          undefined,
          undefined,
          selectedOrg,
          selectedAccount.value.toLowerCase(),
          selectedStack.value,
          unformatDeviceId
        );
      }
    }
  };

  // Custom Toggle indicator
  const GridActionToggle = React.forwardRef(({ onClick }, ref) => (
    <DropboxToggle
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={threeDots} />
    </DropboxToggle>
  ));

  GridActionToggle.displayName = 'CustomToggle';

  // Custom Dropdown List
  const GridActionMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');
    return (
      <DropdownMenu ref={ref} style={style} className={className} aria-labelledby={labeledBy} rightAlign>
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </DropdownMenu>
    );
  });

  GridActionMenu.displayName = 'CustomMenu';

  // custom message for Device page
  const showCustomizeMessage = () => {
    if ((selectedOrg && isFetchingDevices) || (!selectedOrg && isFetchingDevices)) {
      return PAGE_INFO.DEVICE_DATA_FETCHING;
    } else if (!selectedOrg && !isFetchingDevices && !additionalSearch) {
      return PAGE_INFO.DEVICE_INITIAL_LOAD;
    } else {
      return ERROR_MESSAGES.EMPTY_TEXT;
    }
  };

  let columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'sortVehicleName',
      searchIndex: 'searcVehicleName',
      key: 'label',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <Link
            to={`/device-operations/${encodeURIComponent(device.deviceId)}/${selectedAccount.value.toLowerCase()}`}
            style={{ textDecoration: 'none' }}
          >
            <span
              title={'Vehicle Name: ' + (device?.vehicleDetails?.label ? device?.vehicleDetails?.label : '-')}
              className="jobNameAndIcon"
            >
              {device.loadingStatus ? <TableSpinner /> : <IconSvgComponent svgFileName={'car'} alt="Device Name" />}
              <b>
                <TruncatedText Vname>{device.searcVehicleName}</TruncatedText>
              </b>
            </span>
          </Link>
        );
      },
    },
    {
      title: 'VSG REGISTRATION ID',
      dataIndex: 'tooltipDeviceName',
      searchIndex: 'searchableDeviceName',
      key: 'deviceName',
      searchable: true,
      width: '45%',
      rawVSGID: 'rawVSGID',
      render(value, device) {
        return (
          <span className="d-flex align-items-center gap-1">
            <span
              title={'VSG Registration Id: ' + (device && device?.tooltipDeviceName ? device?.tooltipDeviceName : '-')}
              className="jobNameAndIcon"
            >
              <TruncatedText RegId>{device.searchableDeviceName}</TruncatedText>
            </span>
            <IconSvgComponent
              onClick={() =>
                getVsgInfo(device?.deviceId, device?.vehicleDetails?.vehicle_id, device?.orgDetails[0]?.organization_id)
              }
              svgFileName={'info'}
              style={{ cursor: 'pointer' }}
              title="Click here to fetch Account and GPS Detail"
            />
          </span>
        );
      },
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      searchIndex: 'searchVehicleType',
      key: 'vehicleType',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span
            title={
              'Vehicle Type: ' + (device?.vehicleDetails?.vehicle_type ? device?.vehicleDetails?.vehicle_type : '-')
            }
          >
            <TruncatedText Vname>{device.searchVehicleType}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Vehicle Year',
      dataIndex: 'vehicleYear',
      searchIndex: 'searchVehicleYear',
      key: 'vehicleYear',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span title={'Vehicle Year: ' + (device?.vehicleDetails?.mfg_year ? device?.vehicleDetails?.mfg_year : '-')}>
            {device.searchVehicleYear}
          </span>
        );
      },
    },

    {
      title: 'Vehicle Make',
      dataIndex: 'vehicleMake',
      searchIndex: 'searchVehicleMake',
      key: 'vehicleMake',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span title={'Vehicle Make: ' + (device?.vehicleDetails?.make ? device?.vehicleDetails?.make : '-')}>
            <TruncatedText Vname>{device.searchVehicleMake}</TruncatedText>
          </span>
        );
      },
    },

    {
      title: 'Vehicle Model',
      dataIndex: 'vehicleModel',
      searchIndex: 'searchVehicleModel',
      key: 'vehicleModel',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span title={'Vehicle Model: ' + (device?.vehicleDetails?.model ? device?.vehicleDetails?.model : '-')}>
            {device.searchVehicleModel}
          </span>
        );
      },
    },

    {
      title: 'License plate',
      dataIndex: 'vehicleLicense',
      searchIndex: 'searchVehicleLicense',
      key: 'vehicleLicense',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span
            title={
              'License Plate: ' + (device?.vehicleDetails?.license_plate ? device?.vehicleDetails?.license_plate : '-')
            }
          >
            {device.searchVehicleLicense}
          </span>
        );
      },
    },

    {
      title: 'Haas',
      dataIndex: 'sortHaasEnabled',
      searchIndex: 'searchHaasEnabled',
      key: 'haas_enabled',
      searchable: false,
      width: '10%',
      render: (value, device) =>
        device?.searchHaasEnabled ? (
          <IconSvgComponent svgFileName={'check-success'} alt="HAAS Enabled" title="HAAS Integration Enabled" />
        ) : (
          ''
        ),
    },
    {
      title: 'GTT',
      dataIndex: 'sortGttEnabled',
      key: 'gtt_enabled',
      searchable: false,
      width: '10%',
      render: (value, device) =>
        device?.searchGttEnabled ? (
          <IconSvgComponent svgFileName={'check-success'} alt="GTT Enabled" title="GTT Integration Enabled" />
        ) : (
          ''
        ),
    },

    {
      title: 'Firmware version',
      dataIndex: 'firmVersion',
      searchIndex: 'searchFirmVersion',
      key: 'firmVersion',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span
            title={
              'Firmware Version: ' +
              (device?.shadowInfo?.firmware?.current?.version ? device?.shadowInfo?.firmware?.current?.version : '-')
            }
          >
            {device.searchFirmVersion}
          </span>
        );
      },
    },
    {
      title: 'Firmware state',
      dataIndex: 'firmState',
      searchIndex: 'searchFirmState',
      key: 'firmState',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span
            title={
              'Firmware State: ' + (device?.shadowInfo?.firmware?.state ? device?.shadowInfo?.firmware?.state : '-')
            }
          >
            <TruncatedText FirmwareState>{device.searchFirmState}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Lock Status',
      dataIndex: 'lockStatus',
      searchIndex: 'searchLockStatus',
      key: 'lockStatus',
      searchable: false,
      width: '10%',
      render(value, device) {
        return (
          <span title={`Lock Status: ${device.securityLock ? device.securityLock : '-'}`}>
            <TruncatedText FirmwareState>{device.searchLockStatus}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Organization',
      dataIndex: 'deviceOrganizationName',
      searchIndex: 'searchableOrganizationName',
      key: 'deviceOrganizationName',
      searchable: false,
      width: '8%',
      render(value, device) {
        return (
          <a
            style={{
              textDecoration: 'none',
              cursor: Object.keys(device.orgDetails).length !== 0 ? 'pointer' : 'auto',
            }}
            onClick={() => openOrgModal(device)}
            title={
              'Organization: ' +
              (device?.orgDetails[0]?.organization_name ? device?.orgDetails[0]?.organization_name : '-')
            }
          >
            <TruncatedText DeviceOrgname>{device.searchableOrganizationName}</TruncatedText>
          </a>
        );
      },
    },

    {
      title: 'Current Stack',
      dataIndex: 'environment',
      searchIndex: 'searchableEnv',
      key: 'environment',
      searchable: false,
      width: '30%',
      render(value, device) {
        return (
          <span title={'Current Stack: ' + (value ? value : '-')}>
            <TruncatedText stack>{device.searchableEnv}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Change Stack',
      dataIndex: '',
      key: '',
      render(value, device) {
        return (
          <span style={{ display: 'flex', width: '140px' }}>
            <EnvSelect
              styles={ReactSelectDefaultStyles}
              value={''}
              clearable={false}
              searchable={false}
              autosize={true}
              options={optionList()}
              onChange={(e) => envChangeHandler(e, device)}
              placeholder="Select Stack"
              closeMenuOnScroll={(e) => {
                return !e?.target?.firstChild?.id?.includes('react-select');
              }}
              menuPlacement={'auto'}
              menuPosition={'fixed'}
            />
          </span>
        );
      },
    },
    {
      title: 'Action',
      width: '8%',
      render(value, device, index) {
        return (
          <span>
            <Dropdown>
              <Dropdown.Toggle as={GridActionToggle} id="dropdown-custom-components"></Dropdown.Toggle>
              <Dropdown.Menu as={GridActionMenu}>
                <Dropdown.Item href="#" onClick={() => deviceReset(device)}>
                  Reset
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <>
          {deviceId && awsAccount ? <DeviceInfo deviceID={deviceId} awsAccount={awsAccount} /> : null}
          {!deviceId && !awsAccount && !initialPageLoad ? <LoadingOverlay /> : null}
          <span className={deviceId && awsAccount ? 'hideDeviceList' : ''}>
            <PageListHead>
              <PageListTitle>Devices</PageListTitle>
            </PageListHead>
            <div>
              Total of {devices.length} Devices{' '}
              {isFetchingDevices ? <img src={spinner} style={{ width: '16px', height: '16px' }} /> : null}
            </div>
            <SortableTable
              className="table-simple"
              columns={columns}
              tableData={devices}
              scroll={{ x: false, y: false }}
              rowKey={(record) => record.jobId}
              refresh={resetPageNo}
              emptyText={() => showCustomizeMessage()}
              selectedAccount={selectedAccount}
              selectedStack={selectedStack}
              hideEnvDropdown={hideEnvDropdown}
              optionChangeHandler={optionChangeHandler}
              stackChangeHandler={stackChangeHandler}
              organizationList={orgs}
              orgChangeHandler={orgChangeHandler}
              isFetchingDevices={isFetchingDevices}
              isFetchingOrgs={isFetchingOrgs}
              selectedOrg={selectedOrg}
              additionalApiCallForDevice={additionalApiCallForDevice}
              localMatch={localMatch}
              searchTextDevice={searchTextDevice}
              reloadComponent={(reload) => reloadComponent(reload)}
              additionalSearch={additionalSearch}
            />
            {active && <OrgDetail width={600} closeModal={setActive} OrgModalDetails={orgModalDetails} />}
            {vsgactive && <VsgDetail width={550} closeModal={setvsgActive} VsgModalDetails={vsgModalDetails} />}
          </span>
        </>
      </div>
      {confirmationModalData && (
        <EvnChangeConfirmationModal
          device={confirmationModalData}
          onCloseModal={() => setConfirmationModalData(null)}
        />
      )}
      {resetModal && (
        <ResetVehicleConfirmationModal
          onCloseModal={() => setResetModal(null)}
          device={device}
          device_Reset={device_Reset}
          selectedAccount={selectedAccount}
          selectedStack={selectedStack}
        />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    isError: state.deviceOperations?.isError,
    envList: state.user.envList,
    deviceId: state?.deviceOperations?.deviceId,
    addons: state.addons.addons,
  }),
  (dispatch) => ({
    clearAndRestoreDevices: (selectedAccount, selectedStack) =>
      dispatch(clearAndRestoreDevices(selectedAccount, selectedStack)),
    device_Reset: (deviceDetails) => dispatch(device_Reset(deviceDetails)),
    clearAndRestoreAddons: (selectedAccount, selectedStack) =>
      dispatch(clearAndRestoreAddons(selectedAccount, selectedStack)),
  })
)(deviceList);
