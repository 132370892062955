/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const matrixWrapper = styled.div``;

export const Matrix = styled.div`
  background: white;
  margin-bottom: 55px;
  .image {
    width: -webkit-fill-available;
    width: -moz-available;
    height: 900px;
  }
`;

export const MatrixOptionRow = styled.div`
  .matrixOptions {
    display: flex;
    justify-content: start;
    line-height: 1.2;
    font-size: 14px;
    margin: 20px 0px;
    background: white;
    padding: 10px;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }
  .option {
    display: flex;
  }
  .show-label {
    margin-right: 10px;
    color: ${colors.haze};
    align-items: center;
    display: flex;
    font-size: 14px !important;
  }
  .datepicker > div {
    display: flex;
  }
  .datetime {
    display: flex !important;
    align-items: center;
    .form-control {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: 1px solid #ccc !important;
      line-height: 17px;
      font-size: 14px;
      font-weight: 700;
      color: ${colors.haze};
      padding-bottom: 3px;
      width: 140px;
      position: relative;
      &:focus {
        box-shadow: unset;
      }
    }
    .rdtPicker {
      top: 30px;
      td.rdtActive {
        background-color: #666666;
      }
    }
  }
  .datetime:first-of-type::after {
    color: ${colors.haze};
    content: '-';
    margin: 0px 15px 0px 10px;
  }
`;
export default matrixWrapper;
