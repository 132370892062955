import { handleActions } from 'redux-actions';
import { setFetchingSerialdevices, resetSerialdevices, rejectSerialdevices, receiveSerialdevices } from './action';

const defaultState = {
  serialDevices: [],
  isFetchingSerialDevices: false,
  isError: false,
};

const serialDevice = handleActions(
  {
    [setFetchingSerialdevices]: (state, { payload: isFetchingSerialDevices }) => ({
      ...state,
      isFetchingSerialDevices: isFetchingSerialDevices,
      isError: false,
    }),
    [receiveSerialdevices]: (state, { payload: serialDevices }) => {
      return {
        ...state,
        serialDevices: state.serialDevices.concat(serialDevices),
      };
    },
    [resetSerialdevices]: (state, { payload: serialDevices }) => {
      return {
        ...state,
        serialDevices: serialDevices,
      };
    },
    [rejectSerialdevices]: (state, { payload: isFetchingSerialDevices }) => ({
      ...state,
      isFetchingSerialDevices: isFetchingSerialDevices,
      isError: true,
      serialDevices: [],
    }),
  },
  defaultState
);
export default serialDevice;
