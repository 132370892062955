import { handleActions } from 'redux-actions';
import { requestActivityLogs, receiveActivityLogs, setFetchingLogs, rejectActivityLogs } from './actions';
import moment from 'moment';

const defaultState = {
  userActivityLogs: [],
  isError: false,
  isFetchingLogs: false,
};

const userActivityLogs = handleActions(
  {
    [setFetchingLogs]: (state, { payload: isFetchingLogs }) => ({
      ...state,
      isFetchingLogs: isFetchingLogs,
      isError: false,
    }),
    [requestActivityLogs]: (state) => ({
      ...state,
      isError: false,
      isFetchingLogs: true,
      userActivityLogs: [],
    }),
    [receiveActivityLogs]: (state, { payload: userActivityLogs }) => {
      return {
        ...state,
        isFetchingLogs: false,
        userActivityLogs: userActivityLogs.map((userActivityLog) => ({
          ...userActivityLog,
          formattedTime: moment(userActivityLog.timestamp).format('MMMM Do YYYY, h:mm:ss a'),
        })),
      };
    },
    [rejectActivityLogs]: (state, { payload: isFetchingLogs }) => ({
      ...state,
      isFetchingLogs: isFetchingLogs,
      isError: true,
      userActivityLogs: [],
    }),
  },
  defaultState
);

export default userActivityLogs;
