/* eslint-disable react/display-name */
import React from 'react';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { Button } from 'shared/styles/components/Button';
import ConfirmCloseModal from 'shared/styles/components/Modal';

export default ({ onRequestClose, onConfirm, message }) => {
  return (
    <ConfirmCloseModal>
      <div className="container">
        <div className="closeButton" onClick={onRequestClose}>
          <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
        </div>
        <div className="prompt">{message}</div>
        <div className="actions">
          <Button formCancel onClick={onRequestClose} style={{ marginRight: '10px' }}>
            Go Back
          </Button>
          <Button onClick={onConfirm} formConfirm>
            Confirm
          </Button>
        </div>
      </div>
    </ConfirmCloseModal>
  );
};
