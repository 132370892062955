import styled, { css } from 'styled-components';

import { media } from 'shared/utilities/media';
import colors from 'shared/constants/colors';

/**
 * @ Components
 */
import RouterNavLink from 'core/components/navigation/RouterNavLink';

import caretDownIcon from 'assets/images/caret-down@2x.png';
import caretUpRedIcon from 'assets/images/caret-up-red@2x.png';

const Nav = styled.nav`
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.cotton};

  ${(props) =>
    props.sidebarIsCollapsed &&
    `
		.nav-button-text {
			opacity: 0;
		}
	`};

  ${(props) =>
    !props.sidebarIsCollapsed &&
    `
		.nav-button-text {
			opacity: 1;
		}
	`};
`;

export const NavItem = styled.li``;

export const NavList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  ${NavItem} {
    border-bottom: 1px solid ${colors.charcoal};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const NavButtonText = styled.span.attrs({
  className: 'nav-button-text',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: opacity 0.3s;
`;

export const NavButton = styled.button.attrs({ className: 'nav-button' })`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 600;
  height: 49px;
  line-height: 49px;
  padding: 0 0 0 12px;
  text-align: left;
  border: none;
  text-decoration: none;
  background: none;
  fill: ${colors.cotton};
  path {
    fill: ${colors.cotton};
  }

  [stroke] {
    fill: ${colors.cotton};
    stroke: ${colors.cotton};
  }

  &:hover {
    box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
    background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
    color: ${colors.cotton};

    path {
      fill: ${colors.cotton};
    }

    [stroke] {
      fill: ${colors.cotton};
      stroke: ${colors.cotton};
    }
  }

  ${(props) =>
    props.hasDropdown &&
    `
		&:after {
			content: '';
			position: absolute;
			right: 22px;
			top: 50%;
			transform: translateY(-50%);
			width: 9px;
			height: 6px;
      		background-image: url(${caretDownIcon});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			opacity: 0;
			transition: transform .3s, background-image .3s, opacity .3s;
		}
	`}

  ${(props) =>
    props.current &&
    `
		box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
		background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
		color: ${colors.cotton};

		&:after {
			background-image: url(${caretUpRedIcon});
		}
	`}

	${(props) =>
    props.isActiveParent &&
    props.sidebarIsCollapsed &&
    `
		background: ${colors.charcoal};
		color: ${colors.cotton};

		path {
			fill: ${colors.cotton};
		}

		[stroke] {
			fill: ${colors.cotton};
			stroke: ${colors.cotton};
		}
	`}

	${(props) =>
    !props.sidebarIsCollapsed &&
    `
		// the dropdown caret
		&:after {
			opacity: 1;
		}
	`}

	${(props) =>
    props.sidebarIsCollapsed &&
    `
		&:hover {
		background: ${colors.midnight};
		}
	`}
`;

export const NavButtonIcon = styled.span`
  display: block;
  min-width: 32px;
  font-size: 0;
  line-height: 0;
`;

export const NavLinkItem = styled.a`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 49px;
  line-height: 49px;
  padding: 0 0 0 18px;
  text-decoration: none;
  background: none;
  color: ${colors.cotton};
`;

export const NavLink = styled(RouterNavLink).attrs({
  className: 'nav-button',
})`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 49px;
  line-height: 49px;
  padding: 0 0 0 18px;
  text-decoration: none;
  background: none;
  color: ${colors.cotton};
  path {
    fill: ${colors.cotton};
  }

  [stroke] {
    fill: ${colors.cotton};
    stroke: ${colors.cotton};
  }

  &.selected {
    box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
    background: ${colors.charcoal};
    color: ${colors.cotton};

    path {
      fill: ${colors.cotton};
    }

    [stroke] {
      fill: ${colors.cotton};
      stroke: ${colors.cotton};
    }
  }

  &:hover {
    box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
    background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
    color: ${colors.cotton};

    path {
      fill: ${colors.cotton};
    }

    [stroke] {
      fill: ${colors.cotton};
      stroke: ${colors.cotton};
    }
  }

  ${(props) =>
    props.hasDropdown &&
    `
		&:after {
			content: '';
			position: absolute;
			right: 22px;
			top: 50%;
			transform: translateY(-50%);
			width: 9px;
			height: 6px;
			background-image: url(${caretDownIcon});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			opacity: 0;
			transition: transform .3s, background-image .3s, opacity .3s;
		}
	`}

  ${(props) =>
    props.current &&
    `
		box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
		background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
		color: ${colors.cotton};

		&:after {
			background-image: url(${caretUpRedIcon});
		}
	`}

	${(props) =>
    !props.sidebarIsCollapsed &&
    `
		&:after {
			opacity: 1;
		}

		&.selected {
			box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
			background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
			color: ${colors.cotton};

			path {
				fill: ${colors.cotton};
			}

			[stroke] {
				fill: ${colors.cotton};
				stroke: ${colors.cotton};
			}
		}
	`}

	${(props) =>
    props.sidebarIsCollapsed &&
    `
		&:hover {
			background: ${colors.midnight};
		}
	`}
`;

export const NavDropdown = styled.div`
  border-top: 1px solid ${colors.charcoal};

  ${NavLink}, ${NavButton} {
    padding-left: 60px;
  }

  ${NavButtonIcon} {
    left: 58px;
  }

  ${(props) =>
    !props.sidebarIsCollapsed &&
    `
		li > .nav-button {
			background: ${colors.midnight};

			&.selected,
			&:hover {
				background: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
			}
		}
	`};
`;

export const NavDynamicList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px !important;

  ${media.tablet_portrait`
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px 0;
  `};
`;
export const NavDynamicBtn = styled.button`
  display: block;
  min-width: 177px;
  padding: 0 5px 15px 5px;
  background: none;
  border: 0 none;
  border-bottom: 2px solid transparent;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.59;
  text-align: center;
  color: ${colors.haze};

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }

  .badge {
    min-width: 31px;
    height: 16px;
    line-height: 16px;
    margin-left: 19px;
    padding: 0 5px;
    background: ${colors.haze};
    border-radius: 15px;
    font-size: 9px;
    color: #fff;
  }
`;
export const NavDynamicListItem = styled.li`
  &.active ${NavDynamicBtn} {
    border-color: ${colors.midnight};
    color: ${colors.midnight};

    .badge {
      background: ${colors.midnight};
    }
  }
`;

export default Nav;
