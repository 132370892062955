/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import device from 'shared/constants/sizing';
//When using this component always set the title to the value so that if truncation occurs, the full detail can be viewed in the tooltip
export const TruncatedText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) =>
    props.Username &&
    `
    width : 200px;
  `};
  ${(props) =>
    props.Usertitle &&
    `
    width : 160px;
  `};
  ${(props) =>
    props.Userfname &&
    `
    width : 160px;
  `};
  ${(props) =>
    props.Userlname &&
    `
    width : 160px;
  `};
  ${(props) =>
    props.Useremail &&
    `
    width : 200px;
  `};
  ${(props) =>
    props.UserOrgname &&
    `
    width : 200px;
  `};
  ${(props) =>
    props.organization_name &&
    `
    width : 200px;
  `};
  ${(props) =>
    props.DeviceOrgname &&
    `
    width : 100px;
  `};
  ${(props) =>
    props.address_state &&
    `
    width : 70px;
  `};
  ${(props) =>
    props.address_1 &&
    `
    width : 200px;
  `};
  ${(props) =>
    props.contact_email_id &&
    `
    width : 200px;
  `};

  ${(props) =>
    props.jobid &&
    `
    @media ${device.extraLarge} {
      width : 450px;
    }
    width:250px
  `};
  ${(props) =>
    props.jobname &&
    `
    @media ${device.extraLarge} {
      width : 300px;
    }
    width : 250px;
  `};
  ${(props) =>
    props.Vname &&
    `
    width : 70px;
  `};
  ${(props) =>
    props.RegId &&
    `
    width : 255px;
  `};
  ${(props) =>
    props.VId &&
    `
    width : 90px;
  `};
  ${(props) =>
    props.FirmwareState &&
    `
    width : 90px;
  `};
  ${(props) =>
    props.AuditDetails &&
    `
    width : 1100px;
  `};
  ${(props) =>
    props.LastLoginTime &&
    `
    width : 180px;
  `};
`;

//For use when wraping is desired, but truncation is needed with a long single word
export const TruncatedTextWithWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MultiLineText = styled.div`
  text-wrap: wrap;
`;
