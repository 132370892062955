import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlEIC,
  baseApiUrlDebug,
  baseApiUrlApp,
  loginRedirectUrlEic,
  loginRedirectUrlDev,
  loginRedirectUrlLocal,
  loginRedirectUrlDebug,
  logoutRedirectUrlEic,
  logoutRedirectUrlDev,
  logoutRedirectUrlLocal,
  logoutRedirectUrlDebug,
} from 'shared/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  // default localhost to dev but this could be changed
  if (!host || host.includes('localhost:')) {
    return baseApiUrlEIC;
  }

  if (host && host.includes('dev.cloud.whelen.com')) {
    return baseApiUrlDev;
  } else if (host && host.includes('eic.cloud.whelen.com')) {
    return baseApiUrlEIC;
  } else if (host && host.includes('debug.cloud.whelen.com')) {
    return baseApiUrlDebug;
  }

  // this should return the url in the format https://{baseapiurl}/{stackname} (i.e. https://api.firstresponder.ai/NAME)
  return baseApiUrlApp + getEnv();
})();

export const getLoginRedirectUrl = () => {
  const host = window && window.location.host;

  // default localhost to dev but this could be changed
  if (!host || host.includes('localhost:')) {
    return loginRedirectUrlLocal;
  }
  if (host && host.includes('dev.cloud.whelen.com')) {
    return loginRedirectUrlDev;
  } else if (host && host.includes('eic.cloud.whelen.com')) {
    return loginRedirectUrlEic;
  } else if (host && host.includes('debug.cloud.whelen.com')) {
    return loginRedirectUrlDebug;
  }
};

export const getLogoutRedirectUrl = () => {
  const host = window && window.location.host;

  // default localhost to dev but this could be changed
  if (!host || host.includes('localhost:')) {
    return logoutRedirectUrlLocal;
  }
  if (host && host.includes('dev.cloud.whelen.com')) {
    return logoutRedirectUrlDev;
  } else if (host && host.includes('eic.cloud.whelen.com')) {
    return logoutRedirectUrlEic;
  } else if (host && host.includes('debug.cloud.whelen.com')) {
    return logoutRedirectUrlDebug;
  }
};
export default apiUrl;
