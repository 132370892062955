/**
 * @ External Dependencies
 */
import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * @ Styled Components
 */
import Main from 'shared/styles/components/Main';
import Content from 'shared/styles/components/Content';
import StyledApplicationContainer from 'shared/styles/components/StyledApplicationContainer.js';

/**
 * @ Components
 */
import HeaderComponent from 'shared/ui/layout/HeaderComponent';
import SidebarComponent from 'core/components/navigation/SidebarComponent';
import PageOverlay from 'shared/ui/layout/PageOverlay';

import Jobs from 'features/jobs/pages/jobs';
import DeviceInfo from 'features/deviceOperations/pages/deviceInfo';
import DeviceReports from 'features/deviceOperations/pages/deviceReports';
import Device from 'features/deviceOperations/pages/device';
import Dashboard from 'features/dashboard/pages/dashboard';
import JobDetails from 'features/jobs/pages/jobDetails';
import Login from '../authentication/pages/Login';
import Charts from '../../../features/dashboard/components/charts';
import LoginDetails from '../authentication/components/loginDetails';
import UserActivityLogs from 'features/auditLog/pages/UserActivityLogs';
import Logout from 'core/components/authentication/pages/logout';
import Users from 'features/users/pages/users';
import Organizations from 'features/userOrganization/organizations';
import SerializedDevices from 'features/serializedDevices/serializedDevices';

const Routes = ({
  ui,
  isPageOverlayVisible,
  toggle,
  isSidebarVisible,
  toggleSidebar,
  history,
  fullscreenMode,
  loggedIn,
  location,
}) => {
  const renderRoute = ({ RouteToComponent }) => {
    return RouteToComponent;
  };
  return (
    <Switch>
      <Route
        path={'/login'}
        render={(props) => {
          return loggedIn ? <Redirect to={'/dashboard'} /> : <Login />;
        }}
      />
      <Route
        path={'/logout'}
        render={(props) => {
          return <Logout />;
        }}
      />
      <Route
        exact
        path={'/redirect'}
        render={(routeProps) =>
          renderRoute({
            requiredRole: null,
            redirectTo: null,
            RouteToComponent: <LoginDetails {...routeProps} />,
          })
        }
      />
      <Route
        path={'/'}
        render={() => {
          return loggedIn ? (
            <StyledApplicationContainer fullscreenMode={fullscreenMode}>
              <HeaderComponent history={history} fullscreenMode={fullscreenMode} />
              <SidebarComponent fullscreenMode={fullscreenMode} />

              <Main fullscreenMode={fullscreenMode}>
                <Content
                  flexColumn
                  overlayIsVisible={ui.overlayIsVisible}
                  onClick={isSidebarVisible ? toggleSidebar : null}
                >
                  <PageOverlay
                    isVisible={isPageOverlayVisible}
                    toggle={() => {
                      toggle({ selector: 'alertsVisible' });
                      toggle({ selector: 'isPageOverlayVisible' });
                    }}
                  />
                  <Route
                    exact
                    path={'/'}
                    render={(props) => {
                      return loggedIn ? <Redirect to="/dashboard" {...props} /> : null;
                    }}
                  />
                  <Route
                    exact
                    path={'/users'}
                    render={(props) => {
                      return loggedIn ? <Users {...props} /> : null;
                    }}
                  />
                  <Route
                    exact
                    path={'/dashboard'}
                    render={(routeProps) =>
                      renderRoute({
                        requiredRole: null,
                        redirectTo: null,
                        RouteToComponent: <Dashboard {...routeProps} />,
                      })
                    }
                  />
                  {/* <Route
                    exact
                    path={'/dashboard_beta'}
                    render={(routeProps) =>
                      renderRoute({
                        requiredRole: null,
                        redirectTo: null,
                        RouteToComponent: <Dashboard {...routeProps} />,
                      })
                    }
                  /> */}
                  <Route
                    exact
                    path={'/job-status/:jobId?/:awsAccount?'}
                    render={(routeProps) =>
                      renderRoute({
                        requiredRole: null,
                        redirectTo: null,
                        RouteToComponent: <Jobs {...routeProps} />,
                      })
                    }
                  />
                  <Route
                    exact
                    path={'/device-operations/:deviceId?/:awsAccount?'}
                    render={(routeProps) =>
                      renderRoute({
                        requiredRole: null,
                        redirectTo: null,
                        RouteToComponent: <Device {...routeProps} />,
                      })
                    }
                  />
                  <Route
                    exact
                    path={'/device-reports'}
                    render={(props) => {
                      return <DeviceReports {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path={'/serialized-devices'}
                    render={(props) => {
                      return <SerializedDevices {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path={'/audit-trail'}
                    render={(routeProps) =>
                      renderRoute({
                        requiredRole: null,
                        redirectTo: null,
                        RouteToComponent: <UserActivityLogs {...routeProps} />,
                      })
                    }
                  />
                  <Route
                    exact
                    path={'/organizations'}
                    render={(props) => {
                      return <Organizations {...props} />;
                    }}
                  />
                  <Route
                    path={'/chart'}
                    render={(props) => {
                      return <Charts />;
                    }}
                  />
                </Content>
              </Main>
            </StyledApplicationContainer>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: location.pathname },
              }}
            />
          );
        }}
      />
    </Switch>
  );
};

export default withRouter(
  connect(
    (state) => ({
      ui: {
        overlayIsVisible: state.ui.overlayIsVisible,
      },
      isPageOverlayVisible: state.ui.isPageOverlayVisible,
      fullscreenMode: state.ui.fullscreenMode,
      sidebarOpenedOnClick: state.sidebar.openedOnClick,
      isSidebarVisible: state.sidebar.visible,
      loggedIn: state?.user?.loggedIn,
    }),
    () => ({})
  )(Routes)
);
