import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { addNotification, setPageSize } from '../../core/redux/ui/actions';

import PageListWrapper from 'shared/styles/components/PageList';
import {
  setFetchingOrgs,
  setStackSelection,
  setStackAccount,
  resetEnvStackOptions,
} from 'core/redux/organizations/action';

import OrgList from './orgList';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';

const organizations = ({
  clearAndRestoreOrgs,
  isError,
  setFetchingOrg,
  isFetchingOrgs,
  setStackSelection,
  selectedStack,
  setStackAccount,
  selectedAccount,
  showNotification,
  resetEnvStackOption,
}) => {
  const orgListRef = React.createRef();
  const [initialPageLoad, setInitialPageLoad] = useState(false);
  const dispatch = useDispatch();

  // function for updating initial loader
  const resetInitialLoad = (state) => {
    setInitialPageLoad(state);
  };

  // on page change reset states, dropdown selections and page size
  useEffect(() => {
    return () => {
      resetEnvStackOption();
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
    };
  }, []);

  return (
    <PageListWrapper>
      <OrgList
        ref={orgListRef}
        key={selectedAccount.value + selectedStack.value}
        setFetchingOrgs={setFetchingOrg}
        isFetchingOrgs={isFetchingOrgs}
        setInitialLoads={(state) => resetInitialLoad(state)}
        clearAndRestoreOrgs={clearAndRestoreOrgs}
        selectedStack={selectedStack}
        selectedAccount={selectedAccount}
        stackSelection={setStackSelection}
        setStackAccount={setStackAccount}
        showNotification={showNotification}
      />
      {!initialPageLoad ? <LoadingOverlay /> : null}
    </PageListWrapper>
  );
};

export default connect(
  (state) => ({
    isFetchingOrgs: state.org.isFetchingOrgs,
    selectedStack: state.org.stackSelection,
    selectedAccount: state.org.selectedAccount,
  }),
  (dispatch) => ({
    setFetchingOrg: (status) => dispatch(setFetchingOrgs(status)),
    setStackSelection: (stack) => dispatch(setStackSelection(stack)),
    setStackAccount: (account) => dispatch(setStackAccount(account)),
    resetEnvStackOption: () => dispatch(resetEnvStackOptions()),
    showNotification: (e) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ORGS, e),
        })
      ),
  })
)(organizations);
