import { createAction } from 'redux-actions';

import { getRulesExecuted, getFailedJobExecutionTotalCount } from 'core/api/iotMatrix';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receiveRulesExecuted = createAction('RECEIVE_RULES_EXECUTED');
export const receiveFailedJobExecutionTotalCount = createAction('RECEIVE_FAILED_EXECUTION_TOTAL_COUNT');

export const requestRulesExecuted = createAction('REQUEST_RULES_EXECUTED');
export const requestFailedJobExecutionTotalCount = createAction('REQUEST_FAILED_EXECUTION_TOTAL_COUNT');
export const handleError = createAction('HANDLE_ERROR');

// to fetch the rules executed base 64 data
export const fetchRulesExecuted = (params) => {
  return async (dispatch) => {
    dispatch(requestRulesExecuted());
    try {
      const { response } = await getRulesExecuted(params);
      dispatch(receiveRulesExecuted(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_RULES_EX, e),
        })
      );
      return false;
    }
  };
};

// to fetch the failed job execution total count base 64 data
export const fetchFailedJobExecutionTotalCount = (params) => {
  return async (dispatch) => {
    dispatch(requestFailedJobExecutionTotalCount());
    try {
      const { response } = await getFailedJobExecutionTotalCount(params);
      dispatch(receiveFailedJobExecutionTotalCount(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_FAILED_JOB_COUNT, e),
        })
      );
      return false;
    }
  };
};
