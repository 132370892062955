import React, { useState } from 'react';
import useAPIData from 'shared/hooks/useAPIData';
import {
  fetchLambdaInvocations,
  fetchLambdaDuration,
  fetchLambdaThrottles,
  fetchLambdaErrors,
} from 'core/redux/lambdaMatrix/actions';
import Matrix from 'features/dashboard/components/matrix';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import MatrixOptions from 'shared/ui/matrix/matrixOptions';
import Charts from 'features/dashboard/components/charts';

const lambdaMatrix = ({
  invocations,
  duration,
  throttles,
  errors,
  fetchLambdaInvocations,
  fetchLambdaThrottles,
  fetchLambdaDuration,
  fetchLambdaErrors,
  isError,
  selectedStack,
}) => {
  let [options, setOptions] = useState(null);
  const isInvocationsLoaded = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        const status = await fetchLambdaInvocations(options);
        return status;
      }
    },
    dependencies: [options],
  });

  const isDurationLoaded = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        const status = await fetchLambdaDuration(options);
        return status;
      }
    },
    dependencies: [options],
  });
  const isErrorsLoaded = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        const status = await fetchLambdaErrors(options);
        return status;
      }
    },
    dependencies: [options],
  });
  const isThrottlesLoaded = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        const status = await fetchLambdaThrottles(options);
        return status;
      }
    },
    dependencies: [options],
  });
  const optionChangeHandler = (e) => {
    setOptions(e);
  };
  return (
    <>
      <MatrixOptions optionChangeHandler={optionChangeHandler} matrixType="lambda" selectedStack={selectedStack} />
      {(isInvocationsLoaded && isDurationLoaded && isThrottlesLoaded && isErrorsLoaded) || isError ? (
        options?.format !== 'json' ? (
          <div>
            {invocations?.message ? <Matrix base64Data={invocations?.message} /> : null}
            {duration?.message ? <Matrix base64Data={duration?.message} /> : null}
            {throttles?.message ? <Matrix base64Data={throttles?.message} /> : null}
            {errors?.message ? <Matrix base64Data={errors?.message} /> : null}
          </div>
        ) : (
          <>
            {invocations?.message ? (
              <Charts options={options} jsonFormData={{ ...invocations?.message, chartType: 'Invocations' }} />
            ) : null}
            {duration?.message ? (
              <Charts options={options} jsonFormData={{ ...duration?.message, chartType: 'Duration' }} />
            ) : null}
            {throttles?.message ? (
              <Charts options={options} jsonFormData={{ ...throttles?.message, chartType: 'Throttles' }} />
            ) : null}
            {errors?.message ? (
              <Charts options={options} jsonFormData={{ ...errors?.message, chartType: 'Errors' }} />
            ) : null}
          </>
        )
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
export default connect(
  (state) => ({
    invocations: state.lambdaMatrix?.invocations,
    duration: state.lambdaMatrix?.duration,
    throttles: state.lambdaMatrix?.throttles,
    errors: state.lambdaMatrix?.errors,
    isError: state.lambdaMatrix?.isError,
    selectedStack: state.lambdaMatrix.stackSelection,
  }),
  (dispatch) => ({
    fetchLambdaInvocations: (params) => {
      return dispatch(fetchLambdaInvocations(params));
    },
    fetchLambdaThrottles: (params) => {
      return dispatch(fetchLambdaThrottles(params));
    },
    fetchLambdaDuration: (params) => {
      return dispatch(fetchLambdaDuration(params));
    },
    fetchLambdaErrors: (params) => {
      return dispatch(fetchLambdaErrors(params));
    },
  })
)(lambdaMatrix);
