import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import threeDots from '../../../assets/svg/three-dots.svg';
import OrgDetail from 'features/userOrganization/orgDetail';
import { ERROR_MESSAGES, PAGE_INFO, SUCCESS_MESSAGES } from 'shared/constants/messages';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownMenu, DropboxToggle } from '../../../shared/styles/components/Dropdown';
import Badge from 'shared/styles/components/Badge';
import ResetConfirmationModal from '../../../shared/modals/resetConfirmationModal';
import { userList as usersList, decorateUser } from '../../../core/api/users';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';
import {
  setUserNameSearchText,
  setExternalSearch,
  setOrg,
  setStackSelection,
  setStackAccount,
} from 'core/redux/user/actions';
import spinner from '../../../assets/images/loader-sp.gif';

// user list table
const userList = ({
  initialPageLoad,
  hideEnvDropdown,
  setInitialLoads,
  selectedStack,
  selectedAccount,
  showNotification,
  user_Reset,
  reloadComponent,
  orgs,
  isFetchingOrgs,
  refreshOrgList,
}) => {
  const [resetPageNo, setResetPageNo] = useState(false);
  const [active, setActive] = useState(false);
  const [orgModalDetails, setOrgModalDetails] = useState([]);
  const [user, setUser] = useState([]);
  const [resetModal, setResetModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [currentUsernameSearch, setCurrentUsernameSearch] = useState(null);
  const { selectedOrg, externalSearch, userNameSearchText } = useSelector((state) => state.user);
  const [additionalSearch, setAdditionalSearch] = useState(false);
  const dispatch = useDispatch();

  // fetch user list on load
  const fetchUsers = async (
    pageSize = PAGINATION_DEFAULT_OPTION.value,
    lastEvaluatedKey1,
    awsAccount = '',
    stack = '',
    allUser = null,
    organization = null,
    username = null
  ) => {
    try {
      setIsFetchingUsers(true);
      const { response } = await usersList(
        pageSize,
        lastEvaluatedKey1,
        awsAccount,
        stack,
        allUser,
        organization?.value,
        username
      );
      if (response.message) {
        let temp = [];
        response.message.map((user) =>
          temp.push(
            decorateUser({
              userFromAPI: user,
            })
          )
        );
        // setting flag if username is searched performed using API call.
        if (username) {
          setAdditionalSearch(true);
        }
        setInitialLoads(true);
        setUsers((previous) => previous.concat(temp));
        response.lastEvaluatedKey ? setIsFetchingUsers(true) : setIsFetchingUsers(false);
        response?.lastEvaluatedKey ? setLastEvaluatedKey(response?.lastEvaluatedKey || false) : null;
      } else {
        setIsFetchingUsers(false);
      }
    } catch (e) {
      console.log(e);
      setIsFetchingUsers(false);
      setInitialLoads(true);
      showNotification(e);
    }
  };

  // check for lastEvaluatedKey state and fetch next set of records with or without org.
  useEffect(() => {
    if (lastEvaluatedKey && !selectedOrg?.value) {
      fetchUsers(
        undefined,
        lastEvaluatedKey,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        true,
        undefined
      );
    } else if (lastEvaluatedKey && selectedOrg?.value) {
      fetchUsers(
        undefined,
        lastEvaluatedKey,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        undefined,
        selectedOrg
      );
    }
    return () => false;
  }, [lastEvaluatedKey]);

  // reload and get records on organization change
  useEffect(() => {
    setUsers([]);
    dispatch(setExternalSearch(false));
    if (selectedOrg && !externalSearch) {
      dispatch(setUserNameSearchText(''));
      fetchUsers(
        undefined,
        undefined,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        undefined,
        selectedOrg
      );
    }
  }, [selectedOrg]);

  // If record is not found in local table than call additional API for searching.
  useEffect(() => {
    if (externalSearch && userNameSearchText) {
      fetchUsers(
        undefined,
        undefined,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        false,
        selectedOrg,
        userNameSearchText
      );
    }
  }, [externalSearch, userNameSearchText]);

  // on click refresh button.
  const refreshUsers = () => {
    setResetPageNo(!resetPageNo);
    setUsers([]);
    if (selectedOrg?.value) {
      fetchUsers(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value, false, organization);
    } else {
      fetchUsers(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value, true, organization);
    }
  };

  // on click reset button.
  const userReset = (user) => {
    setResetModal(true);
    setUser(user);
  };

  // Env dropdown change handler
  const optionChangeHandler = (e) => {
    dispatch(setStackAccount(e));
    dispatch(setStackSelection(STACK_DEFAULT_OPTION[e.label.toLowerCase()]));
    refreshOrgList(e, STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
  };

  const stackChangeHandler = (e) => {
    if (e.value !== selectedStack.value) {
      dispatch(setUserNameSearchText(''));
      setUsers([]);
      dispatch(setStackSelection(e));
      refreshOrgList(selectedAccount, e);
    }
  };

  // on change oraganization dropdown value
  const orgChangeHandler = (e) => {
    if (e?.value !== selectedOrg?.value) {
      dispatch(setUserNameSearchText(''));
      dispatch(setOrg(e));
      setUsers([]);
      if (isFetchingUsers) {
        reloadComponent(Math.random());
      }
    }
  };

  // refresh userlist component
  const reloadUserList = () => {
    reloadComponent(Math.random());
  };

  // additional API call for user
  const additionalApiCallForUser = ({ org, username }) => {
    // to prevent multiple blank search when loading in progress
    if (!org?.value && isFetchingUsers && (!username || username === currentUsernameSearch)) {
      return;
    }
    setCurrentUsernameSearch(username);
    setAdditionalSearch(false);
    setUsers([]);
    if (org && !username) {
      fetchUsers(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value, true, org, undefined);
    } else if (!org && username) {
      if (isFetchingUsers) {
        dispatch(setUserNameSearchText(username));
        dispatch(setExternalSearch(true));
        reloadComponent(Math.random()); // terminate current running calls for users.
      } else {
        fetchUsers(
          undefined,
          undefined,
          selectedAccount.value.toLowerCase(),
          selectedStack.value,
          false,
          undefined,
          username
        );
      }
    } else if (!org && !username) {
      fetchUsers(
        undefined,
        undefined,
        selectedAccount.value.toLowerCase(),
        selectedStack.value,
        true,
        undefined,
        undefined
      );
    } else if (org && username) {
      if (isFetchingUsers) {
        dispatch(setUserNameSearchText(username));
        dispatch(setExternalSearch(true));
        reloadComponent(Math.random()); // terminate current running calls for users.
      } else {
        dispatch(setExternalSearch(false));
        fetchUsers(
          undefined,
          undefined,
          selectedAccount.value.toLowerCase(),
          selectedStack.value,
          false,
          org,
          username
        );
      }
    }
  };

  //to pass data in organization detail Modal
  const toggleOrgModal = (userDetail) => {
    setOrgModalDetails(userDetail.organization);
    setActive(true);
  };

  // custom message for user page for no data and fethcing data
  const showCustomizeMessage = () => {
    if (!users?.length && isFetchingUsers) {
      return SUCCESS_MESSAGES.FETCHING_RECORD;
    } else {
      if (additionalSearch || selectedOrg) {
        return ERROR_MESSAGES.EMPTY_TEXT;
      } else {
        return PAGE_INFO.DEVICE_INITIAL_LOAD;
      }
    }
  };
  // Custom Toggle indicator
  const GridActionToggle = React.forwardRef(({ onClick }, ref) => (
    <DropboxToggle
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={threeDots} />
    </DropboxToggle>
  ));

  GridActionToggle.displayName = 'CustomToggle';

  // Custom Dropdown List
  const GridActionMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');
    return (
      <DropdownMenu ref={ref} style={style} className={className} aria-labelledby={labeledBy} rightAlign>
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </DropdownMenu>
    );
  });

  GridActionMenu.displayName = 'CustomMenu';

  let columns = [
    {
      title: 'User Name',
      dataIndex: 'Username',
      searchIndex: 'searchableUserName',
      key: 'Username',
      searchable: true,
      width: '15%',
      render(value, user) {
        return (
          <span title={'User Name: ' + value} className="jobNameAndIcon">
            <IconSvgComponent svgFileName={'users'} alt="User" />
            <TruncatedText Username>{user?.searchableUserName}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'User Title',
      dataIndex: 'user_title',
      searchIndex: 'searchableTitle',
      key: 'user_title',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <span title={'User Title: ' + value}>
            <TruncatedText Usertitle>{user?.searchableTitle}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'First Name',
      dataIndex: 'given_name',
      searchIndex: 'searchableFirstName',
      key: 'given_name',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <span title={'First Name: ' + value}>
            <TruncatedText Userfname>{user?.searchableFirstName}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'family_name',
      searchIndex: 'searchableLastName',
      key: 'family_name',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <span title={'Last Name: ' + value}>
            <TruncatedText Userlname>{user?.searchableLastName}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Contact Email',
      dataIndex: 'email',
      searchIndex: 'searchableEmail',
      key: 'email',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <span title={'Contact Email: ' + value}>
            <TruncatedText Useremail> {user?.searchableEmail}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'User Role',
      dataIndex: 'user_role',
      searchIndex: 'searchableUserRole',
      key: 'user_role',
      searchable: false,
      width: '8%',
      render(value, user) {
        return <span title={'User Role: ' + value}>{user?.searchableUserRole}</span>;
      },
    },
    {
      title: 'Organization',
      dataIndex: 'tooltipOrganizationName',
      searchIndex: 'searchableOrganizationName',
      key: 'tooltipOrganizationName',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <a
            style={{
              textDecoration: 'none',
              cursor: user?.organization ? 'pointer' : 'auto',
            }}
            onClick={() => (value && value !== undefined ? toggleOrgModal(user) : null)}
            title={'Organization: ' + (value ? value : '-')}
          >
            <TruncatedText UserOrgname>{user?.searchableOrganizationName}</TruncatedText>
          </a>
        );
      },
    },
    {
      title: 'Last Login Time',
      dataIndex: 'last_login_time',
      searchIndex: 'searchableLastLoginTime',
      key: 'user_login',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <span title={'Last Login Time: ' + user?.formattedLastLoginTime}>
            <TruncatedText LastLoginTime>{user?.searchableLastLoginTime}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      searchIndex: 'active',
      key: 'user_status',
      searchable: false,
      width: '8%',
      render(value, user) {
        return (
          <Badge active={user?.active === true || user?.active === 'true'}>
            <span title={user?.active === true || user?.active === 'true' ? 'Status Enabled' : 'Status Disabled'}>
              {user?.active === true || user?.active === 'true' ? 'Enabled' : 'Disabled'}
            </span>
          </Badge>
        );
      },
    },
    {
      title: 'Action',
      width: '8%',
      render(value, user) {
        return (
          <span>
            <Dropdown>
              <Dropdown.Toggle as={GridActionToggle} id="dropdown-custom-components"></Dropdown.Toggle>
              <Dropdown.Menu as={GridActionMenu}>
                <Dropdown.Item href="#" onClick={() => userReset(user)}>
                  Reset
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageListHead>
        <PageListTitle>Users</PageListTitle>
      </PageListHead>
      <div>
        Total of {users?.length} Users{' '}
        {isFetchingUsers ? <img src={spinner} style={{ width: '16px', height: '16px' }} /> : null}
      </div>
      <SortableTable
        className="table-simple"
        columns={columns}
        tableData={users ? users : []}
        scroll={{ x: false, y: false }}
        rowKey={(record) => record.userId}
        refresh={resetPageNo}
        emptyText={() => showCustomizeMessage()}
        optionChangeHandler={optionChangeHandler}
        stackChangeHandler={stackChangeHandler}
        selectedAccount={selectedAccount}
        selectedStack={selectedStack}
        hideEnvDropdown={hideEnvDropdown}
        organizationList={orgs}
        isFetchingOrgs={isFetchingOrgs}
        orgChangeHandler={orgChangeHandler}
        setUserNameSearchText={setUserNameSearchText}
        additionalApiCallForUser={additionalApiCallForUser}
        selectedOrg={selectedOrg}
        additionalSearch={additionalSearch}
        reloadUserList={reloadUserList}
      />
      {active && <OrgDetail width={600} closeModal={setActive} OrgModalDetails={orgModalDetails} />}
      {resetModal && (
        <ResetConfirmationModal
          onCloseModal={() => setResetModal(null)}
          user={user}
          user_Reset={user_Reset}
          selectedAccount={selectedAccount}
          selectedStack={selectedStack}
        />
      )}
    </div>
  );
};

export default connect((state) => ({
  isResetUserLoader: state.user.isResetUserLoader,
  initialPageLoad: state.org.initialPageLoad,
}))(userList);
