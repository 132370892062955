import React, { useState } from 'react';
import useAPIData from 'shared/hooks/useAPIData';
import { fetchFailedJobExecutionTotalCount, fetchRulesExecuted } from 'core/redux/iotMatrix/actions';
import Matrix from 'features/dashboard/components/matrix';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import MatrixOptions from 'shared/ui/matrix/matrixOptions';
import Charts from 'features/dashboard/components/charts';

const iotMatrix = ({
  rulesExecuted,
  failedJobExecutionTotalCount,
  fetchRulesExecuted,
  fetchFailedJobExecutionTotalCount,
  isError,
}) => {
  let [options, setOptions] = useState(null);

  const isRulesExecuted = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchRulesExecuted(options);
      }
    },
    dependencies: [options],
  });

  const isFailedJobExecutionTotalCount = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchFailedJobExecutionTotalCount(options);
      }
    },
    dependencies: [options],
  });

  const optionChangeHandler = (e) => {
    setOptions(e);
  };
  return (
    <>
      <MatrixOptions optionChangeHandler={optionChangeHandler} matrixType="iotMatrix" />
      {(isRulesExecuted && isFailedJobExecutionTotalCount) || isError ? (
        options?.format !== 'json' ? (
          <div>
            {rulesExecuted?.message ? <Matrix base64Data={rulesExecuted.message} /> : null}
            {failedJobExecutionTotalCount?.message ? (
              <Matrix base64Data={failedJobExecutionTotalCount.message} />
            ) : null}
          </div>
        ) : (
          <>
            {rulesExecuted?.message ? (
              <Charts options={options} jsonFormData={{ ...rulesExecuted.message, chartType: 'RulesExecuted' }} />
            ) : null}
            {failedJobExecutionTotalCount?.message ? (
              <Charts
                options={options}
                jsonFormData={{ ...failedJobExecutionTotalCount.message, chartType: 'FailedJobExecutionTotalCount' }}
              />
            ) : null}
          </>
        )
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
export default connect(
  (state) => ({
    rulesExecuted: state.iotMatrix.rulesExecuted,
    failedJobExecutionTotalCount: state.iotMatrix.failedJobExecutionTotalCount,
    isError: state.iotMatrix?.isError,
  }),
  (dispatch) => ({
    fetchRulesExecuted: (params) => {
      return dispatch(fetchRulesExecuted(params));
    },
    fetchFailedJobExecutionTotalCount: (params) => {
      return dispatch(fetchFailedJobExecutionTotalCount(params));
    },
  })
)(iotMatrix);
