import { auditLog } from 'shared/constants/api';
import { apiCall } from 'core/api/API';
import moment from 'moment';

export const getUserActivityLogs = async () => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: auditLog,
    method: 'get',
  });
};

export const decorateActivityLogs = ({ activityLogsFromAPI }) => {
  const date = new Date(activityLogsFromAPI.timestamp);
  const formattedDate = moment(date.toLocaleString('en-US')).format('MMMM Do YYYY, h:mm:ss a');

  activityLogsFromAPI.searchableTimeStamp = formattedDate;
  activityLogsFromAPI.formattedTimeStamp = formattedDate;
  activityLogsFromAPI.searchableAction = activityLogsFromAPI.action;
  activityLogsFromAPI.searchableUserName = activityLogsFromAPI.user_name;
  activityLogsFromAPI.searchableDetails = activityLogsFromAPI.details;

  return activityLogsFromAPI;
};
