import { useState, useEffect } from 'react';

export default ({ fetchAPIData, dependencies }) => {
  const [dataLoaded, setDataLoaded] = useState(null);
  const fetchData = async () => {
    try {
      setDataLoaded(false);
      const success = await fetchAPIData();
      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [].concat(dependencies));

  return dataLoaded;
};
