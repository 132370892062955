import styled from 'styled-components';
import { media } from 'shared/utilities/media';

export default styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  display: block !important;
  flex: 1 1 100%;
  transition: padding-left 0.5s;
  ${media.mobile`
    	padding-left: 0;
	`};

  &:after {
    /* Overlay */
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 31, 80, 0.21);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s, left 0.3s;
    touch-action: none;
  }

  ${(props) =>
    props.overlayIsVisible &&
    `
		&:after {
			opacity: 1;
			visibility: visible;
		}
	`}

  ${(props) =>
    props.flexColumn &&
    `
		display: flex;
	`}
`;
