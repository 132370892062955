const size = {
  largeAnalytics: '1160px',
  extraLarge: '1366px',
  large: '1024px',
  medium: '600px',
};

const device = {
  largeAnalytics: `(min-width: ${size.largeAnalytics})`,
  extraLarge: `(min-width: ${size.extraLarge})`,
  large: `(min-width: ${size.large})`,
  medium: `(min-width: ${size.medium})`,
};

export default device;
