import { createAction } from 'redux-actions';
import { getUserActivityLogs, decorateActivityLogs } from 'core/api/userActivityLogs';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const requestActivityLogs = createAction('REQUEST_USER_ACTIVITY_LOG');
export const receiveActivityLogs = createAction('RECEIVE_USER_ACTIVITY_LOG');
export const rejectActivityLogs = createAction('REJECT_USER_ACTIVITY_LOG');
export const setFetchingLogs = createAction('SET_FETCHING_LOGS');

// to fetch user activity logs
export const fetchUserActivityLogs = () => {
  return async (dispatch) => {
    dispatch(requestActivityLogs());
    try {
      const { response } = await getUserActivityLogs();
      dispatch(setFetchingLogs(response.nextToken ? true : false));
      if (response && response.message) {
        dispatch(
          receiveActivityLogs(
            response.message.map((activityLogs) =>
              decorateActivityLogs({
                activityLogsFromAPI: activityLogs,
              })
            )
          )
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectActivityLogs(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ACTIVITY_LOGS, e),
        })
      );
      return false;
    }
  };
};
