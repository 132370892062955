import { createAction } from 'redux-actions';

export const setFetchingOrgs = createAction('SET_FETCHING_ORGS');
export const setInitialOrgLoads = createAction('INITIAL_ORG_LOAD');
export const setStackSelection = createAction('ORG_STACK_SELECTION');
export const setStackAccount = createAction('ORG_STACK_ACCOUNT');
export const resetOptions = createAction('RESET_OPTIONS');

// set initial loader state
export const setInitialLoads = (status) => {
  return async (dispatch) => {
    dispatch(setInitialOrgLoads(status));
  };
};

// set state while fetching records
export const setFetchingOrg = (status) => {
  return async (dispatch) => {
    dispatch(setFetchingOrgs(status));
  };
};

// set state when selecting Stack
export const stackSelection = (selection) => {
  return async (dispatch) => {
    dispatch(setStackSelection(selection));
  };
};

// set state when selecting Account
export const stackAccount = (selection) => {
  return async (dispatch) => {
    dispatch(setStackAccount(selection));
  };
};

// reset states to its default on changing page.
export const resetEnvStackOptions = () => {
  return async (dispatch) => {
    dispatch(resetOptions());
  };
};
