import { handleActions } from 'redux-actions';
import {
  receiveLoginDetails,
  rejectLoginDetails,
  requestLoginDetails,
  requestLoggedInStatus,
  receiveLoggedInStatus,
  rejectLoggedInStatus,
  requestLoggedOut,
  receiveEnvList,
  requestEnvList,
  handleError,
  receiveRefreshedToken,
  requestRefreshedToken,
  rejectRefreshedToken,
  setOrg,
  setEnableUserLoad,
  setUserNameSearchText,
  setExternalSearch,
} from './actions';
import {
  receiveUsers,
  setFetchingUsers,
  resetUsers,
  rejectUsers,
  resetUserLoader,
  resetOptions,
  setInitialUserLoads,
  setStackAccount,
  setStackSelection,
} from './actions';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

const defaultState = {
  user: {},
  loggedIn: false,
  loggingIn: false,
  checkingSession: false,
  envList: [],
  isError: false,
  users: [],
  isFetchingUsers: false,
  isError: false,
  isResetUserLoader: false,
  selectedStack: STACK_DEFAULT_OPTION.dev,
  selectedAccount: ENV_DEFAULT_OPTION,
  initialPageLoad: false,
  selectedOrg: null,
  userNameSearchText: '',
};

const user = handleActions(
  {
    [requestLoginDetails]: (state) => ({
      ...state,
      user: {},
      loggingIn: true,
    }),
    [receiveLoginDetails]: (state, { payload: details }) => ({
      ...state,
      user: details,
      loggedIn: true,
      loggingIn: false,
      checkingSession: false,
    }),
    [rejectLoginDetails]: (state) => ({
      ...state,
      user: {},
      loggedIn: false,
      loggingIn: false,
    }),
    [requestLoggedInStatus]: (state) => ({ ...state, checkingSession: true }),
    [receiveLoggedInStatus]: (state, action) => ({
      ...state,
    }),
    [rejectLoggedInStatus]: (state) => ({
      ...state,
      checkingSession: false,
      loggedIn: false,
      loggingIn: false,
    }),
    [receiveRefreshedToken]: (state, { payload: details }) => ({
      ...state,
      user: details,
      loggedIn: true,
      loggingIn: false,
      checkingSession: false,
    }),
    [requestRefreshedToken]: (state) => ({
      ...state,
      user: {},
      loggingIn: true,
    }),
    [rejectRefreshedToken]: (state) => ({
      ...state,
      user: {},
      loggedIn: false,
      loggingIn: false,
    }),
    [requestLoggedOut]: (state) => ({
      ...state,
      loggedIn: false,
      user: {},
    }),
    [receiveEnvList]: (state, { payload: envList }) => ({
      ...state,
      envList: envList,
    }),
    [requestEnvList]: (state) => ({
      ...state,
      envList: [],
      isError: false,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [setFetchingUsers]: (state, { payload: isFetchingUsers }) => ({
      ...state,
      isFetchingUsers: isFetchingUsers,
      isError: false,
    }),
    [receiveUsers]: (state, { payload: users }) => {
      return {
        ...state,
        users: state.users.concat(users),
      };
    },
    [resetUsers]: (state, { payload: users }) => {
      return {
        ...state,
        users: users,
      };
    },
    [rejectUsers]: (state, { payload: isFetchingUsers }) => ({
      ...state,
      isFetchingUsers: isFetchingUsers,
      isError: true,
      users: [],
    }),
    [resetUserLoader]: (state, { payload: isResetUserLoader }) => ({
      ...state,
      isResetUserLoader: isResetUserLoader,
    }),
    [resetOptions]: (state) => ({
      ...state,
      selectedAccount: defaultState.selectedAccount,
      selectedStack: defaultState.selectedStack,
      initialPageLoad: false,
    }),
    [setInitialUserLoads]: (state, { payload }) => ({
      ...state,
      initialPageLoad: payload,
      isError: false,
    }),
    [setStackSelection]: (state, { payload }) => ({
      ...state,
      selectedStack: payload,
      isError: false,
    }),
    [setStackAccount]: (state, { payload }) => ({
      ...state,
      selectedAccount: payload,
      isError: false,
    }),
    [setOrg]: (state, { payload }) => ({
      ...state,
      selectedOrg: payload,
    }),
    [setUserNameSearchText]: (state, { payload }) => ({
      ...state,
      userNameSearchText: payload,
    }),
    [setExternalSearch]: (state, { payload }) => ({
      ...state,
      externalSearch: payload,
    }),
  },
  defaultState
);

export default user;
