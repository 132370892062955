/**
 * @ External Dependencies
 */
import { createAction } from 'redux-actions';

// Overlay //
export const toggleOverlay = createAction('TOGGLE_OVERLAY');
export const hideOverlay = createAction('HIDE_OVERLAY');
export const displayOverlay = createAction('DISPLAY_OVERLAY');
export const toggleGlobalOverlay = createAction('TOGGLE_GLOBAL_OVERLAY');

// Global //
export const toggle = createAction('TOGGLE');
export const setPageSize = createAction('SET_PAGE_SIZE');

// Notifications //
export const addNotification = createAction('ADD_NOTIFICATION');
export const successAddNotification = createAction('SUCCESS_ADD_NOTIFICATION');
export const successRemoveNotification = createAction('SUCCESS_REMOVE_NOTIFICATION');

export const togglefullscreenMode = (setFullscreen) => {
  return async (dispatch) => {
    if (setFullscreen) {
      try {
        await dispatch(setfullscreenModeOn());
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    } else {
      await dispatch(setfullscreenModeOff());
    }
  };
};
