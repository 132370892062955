import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from 'rc-table';
import PageListWrapper from 'shared/styles/components/PageList';
import { PageListHead, PageListTitle, EmailInput } from 'shared/styles/components/PageList';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { MatrixSelect } from 'shared/styles/components/SelectField';
import { TableFilters } from 'shared/styles/components/PageList';
import { STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';
import { getStackTable } from 'shared/utilities/localStore';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FormControl, Validation } from 'shared/styles/components/Form';
import * as exports from 'shared/constants/matrixOptions';
import { sendReportApi } from 'core/api/deviceOperations';
import { addNotification } from '../../../core/redux/ui/actions';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { ButtonSpinnerExtraSmall } from 'shared/styles/components/Spinner';
import { getDeviceReport } from 'core/api/deviceOperations';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

const tableStyle = {
  width: 'max-content',
  overflow: 'auto',
  border: '1px solid #e9e9eb',
  margin: 'auto',
  paddingBottom: '0',
};

export const ReactSelectDefaultStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: 'unset',
    height: '35px',
    borderRadius: 0,
    border: '1px solid lightgrey',
    color: 'hsl(0, 0%, 20%)',
    boxShadow: 'unset',
    '&:hover': {
      borderColor: 'gray',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 35,
    overflowY: 'auto',
    fontSize: '14px',
    lineHeight: '20px',
    top: '-1px',
  }),
  menu: (base, { selectProps }) => ({
    ...base,
    width: selectProps.isStack ? 'max-content' : '100%',
    minWidth: selectProps.isStack ? '90px' : undefined,
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: '0px',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
    backgroundColor: state.isSelected ? 'gray !important' : '',
  }),
};

const components = {
  header: {
    cell: (props) => {
      return <th style={{ padding: '15px 10px' }}>{props.children}</th>;
    },
  },
};

// Email field components
const EmailField = ({ record, env, stackOption, showNotification }) => {
  const [disable, setDisable] = useState(false);
  const [btndisable, setBtndisable] = useState(true);

  // validation for Email Text fields
  const Schema = yup
    .object()
    .shape({
      emailName: yup
        .string()
        .required('Email address is required')
        .email('Invalid email address (Format abc@whelen.com)')
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[Ww][Hh][Ee][Ll][Ee][Nn].[Cc][Oo][Mm]$/,
          'Invalid email address (Format abc@whelen.com)'
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, values },
    reset: resetInput,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(Schema),
  });

  // Onblur event handler to reset Input value
  const handleBlur = (e) => {
    if (errors.emailName?.message) {
      resetInput({ emailName: '' });
    }
  };

  // Onchange event handler to validate email
  const handleChange = (value) => {
    // email regex
    const isEmail = (email) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[Ww][Hh][Ee][Ll][Ee][Nn].[Cc][Oo][Mm]$/i.test(email);
    if (!isEmail(value)) {
      setBtndisable(true);
    } else {
      setBtndisable(false);
    }
  };

  // Send Report Data
  const sendReportdata = async (formdata) => {
    let reqData = {
      env: env.label,
      emailId: formdata.emailName.toLowerCase(),
      tableStackName: stackOption.value,
    };

    try {
      setDisable(true);
      let reportType = record.type;
      let res;
      if (!disable) {
        res = await sendReportApi(reqData, reportType);
        if (res.response.message) {
          let notification = {
            status: LEVELS.SUCCESS,
            title: res.response.message,
          };
          showNotification(notification);
          setDisable(false);
          resetInput({ emailName: '' });
          setBtndisable(true);
        } else {
          setDisable(false);
        }
      }
    } catch (e) {
      let errorData = {
        status: LEVELS.ERROR,
        title: ERROR_MESSAGES.SENDING_EMAIL_ERROR,
        message: e,
      };
      showNotification(errorData);
      setDisable(false);
    }
  };

  return (
    <EmailInput>
      <form id="email" onSubmit={handleSubmit(sendReportdata)}>
        <div className="input-grp">
          <FormControl
            onBlurCapture={handleBlur}
            {...register('emailName', {
              onChange: ({ target: { value } }) => {
                handleChange(value);
              },
            })}
            type="text"
            fluid
            placeholder="Please enter email address"
            maxLength={128}
          />
          <button type="button" disabled={btndisable} onClick={handleSubmit(sendReportdata)} className="sendButton">
            {!disable && <IconSvgComponent className="send-icon" svgFileName="send" title="send" alt="send" />}
            {disable && <ButtonSpinnerExtraSmall />}
          </button>
        </div>
        <Validation id="1" isInvalid={errors.emailName?.type && true} isValid={!errors.emailName?.type && true}>
          {errors.emailName?.message}
        </Validation>
      </form>
    </EmailInput>
  );
};
// Device Reports components
const deviceReports = ({ envList, showNotification }) => {
  const [env, setEnv] = useState(exports.ENV_DEFAULT_OPTION);
  const [stackNames, setStackNames] = useState(getStackTable() ? getStackTable()[env?.label?.toLowerCase()] : []);
  const [stackOption, setStackOption] = useState(STACK_DEFAULT_OPTION.dev);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);

  // api call to fetch report records
  const fetchReportData = async () => {
    setLoading(true);
    try {
      const { response } = await getDeviceReport();
      if (response.message) {
        const res = await response.message;
        setReportData(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log('error', e);
      showNotification(e);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  // Env dropdown change handler
  const optionChangeHandler = (e) => {
    let stackName = getStackTable();
    setStackNames(stackName[e.label.toLowerCase()]);
    setEnv(e);
    setStackOption(STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
  };

  // Stack dropdown change handler
  const stackChangeHandler = (e) => {
    setStackOption(e);
  };

  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Generate report and send email',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <EmailField record={record} env={env} stackOption={stackOption} showNotification={showNotification} />
        </span>
      ),
    },
  ];

  return (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Reports</PageListTitle>
        </PageListHead>
        <TableFilters>
          <div className="field-wrapper" style={{ display: 'flex', gap: '10px', width: '674px', margin: 'auto' }}>
            <div className="option">
              <div className="show-label">Account: </div>
              <MatrixSelect
                styles={ReactSelectDefaultStyles}
                value={env}
                clearable={false}
                searchable={false}
                autosize={true}
                options={envList.filter(
                  (d) => (d.value === 'Dev') & (d.envName === 'Dev') || d.envName === 'Staging' || d.envName === 'Prod'
                )}
                placeholder={'Select'}
                onChange={optionChangeHandler}
              />
            </div>
            <div className="option">
              <div className="show-label">Stack: </div>
              <MatrixSelect
                styles={ReactSelectDefaultStyles}
                value={stackOption}
                clearable={false}
                searchable={false}
                autosize={true}
                options={stackNames}
                placeholder={'Select'}
                onChange={stackChangeHandler}
                isStack
              />
            </div>
          </div>
        </TableFilters>
        {loading ? (
          <LoadingOverlay />
        ) : (
          <Table
            className="table-simple report-table"
            style={tableStyle}
            columns={columns}
            data={reportData}
            components={components}
          />
        )}
      </PageListWrapper>
    </>
  );
};
export default connect(
  (state) => ({
    envList: state.user.envList,
  }),
  (dispatch) => ({
    showNotification: (e) => {
      dispatch(
        addNotification({
          notification: createNotification(e.status, e.title, e.message),
        })
      );
    },
  })
)(deviceReports);
