/**
 * @ External Dependecies
 */
import styled, { css } from 'styled-components';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import { SectionBody } from 'shared/styles/components/Section';

const PageListWrapper = styled.div`
  background-color: ${colors.fog};
  height: calc(100vh - 70px);
  overflow: auto;
  flex: 1 1 100%;
  padding: 40px 10px;
  @media ${device.large} {
    padding: 48px 83px 20px 83px;
  }
  .head-wrapper {
    display: block;
    .lastseen {
      color: ${colors.haze};
    }
  }
  .deviceId {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }
  .pageHeader {
    margin-left: 10px;
  }
  .hideDeviceList {
    display: none;
  }
  ${(props) =>
    props.centered &&
    props.centered === true &&
    css`
      display: flex;
      flex-direction: column;

      ${SectionBody} {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .svg-icon {
        margin-bottom: 15px;
      }
    `}
`;

export const PageListHead = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0;
    line-height: 38px;
  }
`;

export const PageListTitle = styled.h4`
  display: inline-block;
`;

export const PageListActionButtonContainer = styled.div`
  display: block;
  @media ${device.medium} {
    display: flex;
    align-items: center;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  width: 230px;
  @media ${device.medium} {
    width: 380px;
  }
  .searchButton {
    background: black;
    height: 35px;
    border: none;
    ${({ invalid }) =>
      invalid &&
      css`
        border-color: red !important;
      `}
  }

  .searchButton[disabled] {
    background: #cfcfcf;
  }

  .search {
    display: block;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    background: white;
    border-radius: 2px;
    border-left: 1px solid lightgrey;
    border-right: none;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    font-size: 14px;
  }

  .search-icon {
    padding: 3px 10px;
    stroke: white;
    line-height: 0;
  }

  .Search-box-button {
    display: block;
    height: 35px;
    background: white;
    border-left: none;
    border-right: none;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    width: 35px;
    font-size: 11px;
    font-weight: 600;
  }
  ${({ devicePage }) =>
    devicePage &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const EmailInput = styled.div`
  display: flex;
  width: 230px;
  @media ${device.medium} {
    width: 380px;
  }
  .input-grp {
    display: flex;
  }
  .sendButton {
    background: black;
    height: 35px;
    width: 35px;
    border: none;
    position: relative;
  }
  .send {
    display: block;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    background: #eeeeee;
    border: unset;
    font-size: 14px;
  }

  .send::placeholder {
    font-size: 12px;
    line-height: 30px;
  }

  .send-icon {
    padding: 3px 10px;
    stroke: white;
    line-height: 0;
  }

  .send-box-button {
    display: block;
    height: 35px;
    background: white;
    border-left: none;
    border-right: none;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    width: 35px;
    font-size: 11px;
    font-weight: 600;
  }

  .sendButton[disabled] {
    background: #cfcfcf;
  }
`;

export const PaginationStyles = styled.div`
  .pagination li {
    display: inline-block;
    padding: 5px;
  }
  .pagination li a {
    text-decoration: underline;
  }
  .pagination li:not(.active, .next-page, .previous):hover {
    background-color: ${colors.charcoal};
  }
  .pagination li:not(.active, .next-page, .previous):hover a {
    color: ${colors.cotton};
  }

  .pagination .active a {
    color: ${colors.midnight};
    text-decoration: none;
  }

  .pagination li :not(.active, .next-page, .previous) a {
    color: ${colors.haze};
    text-decoration: none;
  }

  .pagination .next-page a {
    text-decoration: none;
  }
  .pagination .previous a {
    text-decoration: none;
  }
`;
export const TableFilters = styled.div`
  display: flex;
  margin: 10px 0px 20px 0px;
  align-items: center;
  .pagination {
    display: flex;
    justify-content: flex-end;
    color: rgb(28, 28, 28);
    line-height: 1.2;
    font-size: 14px;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    min-width: 125px;
  }
  .field-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
  }
  .option {
    margin-top: 1px;
    display: flex;
    align-items: center;
  }
  .show-label {
    margin-right: 10px;
    color: ${colors.haze};
    font-size: 14px;
  }
  .page-size-dd {
    margin-top: 5px;
    display: flex;
  }
  .pagination li.disabled {
    cursor: default;
    color: rgb(138, 144, 156);
  }
  .pagination li.disabled a {
    text-decoration: none;
    cursor: default;
  }
  .show-label {
    margin-right: 10px;
    color: ${colors.haze};
    align-items: center;
    display: flex;
  }
`;
export default PageListWrapper;
