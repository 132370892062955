import React from 'react';
import { connect } from 'react-redux';

import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import useAPIData from 'shared/hooks/useAPIData';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { fetchJobDetails } from 'core/redux/jobDetails/actions';
import { HeaderButtonDiv, LoadingText } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import JobDetailsHeader from 'features/jobs/components/jobDetailsHeader';
import { Link } from 'react-router-dom';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { BackButton } from 'shared/styles/components/BackButton';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { getFormattedDuid } from 'shared/utilities/general';

const jobDetails = ({ targets, jobDetails, fetchJobDetails, isFetchingJobDetails, jobId, isError, awsAccount }) => {
  const jobDetailsLoaded = useAPIData({
    fetchAPIData: () => fetchJobDetails({ jobId: jobId, awsAccount: awsAccount }),
    dependencies: [],
  });

  const refreshJobDetails = () => {
    fetchJobDetails({ jobId: jobId, awsAccount: awsAccount });
  };
  let columns = [
    {
      title: 'Device Name',
      dataIndex: 'thingName',
      searchIndex: 'searchableJobName',
      key: 'jobName',
      searchable: true,
      width: '45%',
      rawVSGID: 'rawVSGID',
      render(value, target) {
        return (
          <Link to={`/device-operations/${encodeURIComponent(value)}/${awsAccount}`} style={{ textDecoration: 'none' }}>
            <span title={'Device Name: ' + getFormattedDuid(value)} className="jobNameAndIcon">
              <IconSvgComponent svgFileName={'gears'} alt="Device Id" />
              {target.searchableJobName}
            </span>
          </Link>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      searchIndex: 'searchableStatus',
      key: 'status',
      searchable: true,
      width: '20%',
      render(value, target) {
        return <span title={'Status: ' + value}>{target.searchableStatus}</span>;
      },
    },
    {
      title: 'Queued At',
      dataIndex: 'queuedAt',
      searchIndex: 'searchableQueuedAt',
      key: 'queuedAt',
      searchable: true,
      width: '35%',
      render(value, target) {
        return <span title={'Queued At: ' + target.searchableQueuedAtTooltip}>{target.searchableQueuedAt}</span>;
      },
    },
  ];
  return (
    <>
      <PageListHead>
        <div className="deviceId">
          <Link to={'/job-status'}>
            <BackButton>
              <IconSvgComponent svgFileName={'corner-up-left'} alt="Back" />
            </BackButton>
          </Link>
          <PageListTitle className="pageHeader">Job Details</PageListTitle>
        </div>
        <PageListActionButtonContainer>
          <LoadingText>{isFetchingJobDetails ? 'Fetching data ...' : null}</LoadingText>
          <HeaderButtonDiv cancel>
            <ButtonWithLoader
              disabled={isFetchingJobDetails}
              isLoading={isFetchingJobDetails}
              confirmText={'Refresh'}
              loadingStyleProp={'submittingWithSpinner'}
              notLoadingStyleProp={'refresh'}
              clickHandler={() => refreshJobDetails()}
            />
          </HeaderButtonDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      <JobDetailsHeader resource={jobDetails} targets={targets?.length} />
      <SortableTable
        className="table-simple"
        columns={columns}
        tableData={targets}
        scroll={{ x: false, y: false }}
        rowKey={(record) => record.thingName}
        emptyText={ERROR_MESSAGES.EMPTY_TEXT}
      />
      {jobDetailsLoaded || (isFetchingJobDetails && <LoadingOverlay />)}
    </>
  );
};

export default connect(
  (state, ownProps) => ({
    targets: state.jobDetails.targets,
    jobDetails: state.jobDetails.jobDetails,
    isFetchingJobDetails: state.jobDetails.isFetchingJobDetails,
    isError: state.jobDetails?.isError,
  }),
  (dispatch) => ({
    fetchJobDetails: (jobInfo) => dispatch(fetchJobDetails(jobInfo)),
  })
)(jobDetails);
