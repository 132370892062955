export const baseApiUrlEIC = 'https://eic-api.cloud.whelen.com';
export const baseApiUrlProd = 'https://api.cloud.whelen.com/app';
export const baseApiUrlDev = 'https://dev-api.cloud.whelen.com';
export const baseApiUrlDebug = 'https://debug-api.cloud.whelen.com';
export const baseApiUrlStaging = 'https://api.firstresponder.ai/staging';
export const baseApiUrlApp = 'https://api.firstresponder.ai/';
export const loginRedirectUrlEic =
  'https://eic-wcp-debug.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=3kgbjfb4jar6s0stdnebe6gda7&redirect_uri=https://eic.cloud.whelen.com/redirect';
export const loginRedirectUrlDev =
  'https://dev-wcp-debug.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=2rrplk44kpnu6hdrcmgj467vh5&redirect_uri=https://dev.cloud.whelen.com/redirect';
export const loginRedirectUrlLocal =
  'https://eic-wcp-debug.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=3kgbjfb4jar6s0stdnebe6gda7&redirect_uri=http://localhost:3000/redirect';
export const loginRedirectUrlDebug =
  'https://debug-wcp-debug.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=2i3u5ubdu77oe808efkck8bqvf&redirect_uri=https://debug.cloud.whelen.com/redirect';

export const logoutRedirectUrlEic =
  'https://eic-wcp-debug.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=3kgbjfb4jar6s0stdnebe6gda7&redirect_uri=https://eic.cloud.whelen.com/login';
export const logoutRedirectUrlDev =
  'https://dev-wcp-debug.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=2rrplk44kpnu6hdrcmgj467vh5&redirect_uri=https://dev.cloud.whelen.com/login';
export const logoutRedirectUrlLocal =
  'https://eic-wcp-debug.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=3kgbjfb4jar6s0stdnebe6gda7&redirect_uri=http://localhost:3000/login';
export const logoutRedirectUrlDebug =
  'https://debug-wcp-debug.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=2i3u5ubdu77oe808efkck8bqvf&redirect_uri=https://debug.cloud.whelen.com/login';

export const loginDetails = '/authTokens';
export const jobs = '/jobs';
export const shadow = '/shadow';
export const device = '/device';
export const devices = '/devices';
export const statsInfo = '/getStatsInfo';
export const systemErrors = '/metric/SystemErrors';
export const userErrors = '/metric/UserErrors';
export const consumedReadCapacityUnits = '/metric/ConsumedReadCapacityUnits';
export const consumedWriteCapacityUnits = '/metric/ConsumedWriteCapacityUnits';
export const throttledRequests = '/metric/ThrottledRequests';
export const rulesExecuted = '/metric/RulesExecuted';
export const failedJobExecutionTotalCount = '/metric/FailedJobExecutionTotalCount';
export const jobDetail = '/job';
export const gateway5xxError = '/metric/5XXError';
export const gatewayCount = '/metric/Count';
export const integrationLatency = '/metric/integrationLatency';
export const latency = '/metric/Latency';
export const lambdaInvocations = '/metric/Invocations';
export const lambdaDuration = '/metric/Duration';
export const lambdaThrottles = '/metric/Throttles';
export const lambdaErrors = '/metric/Errors';
export const auditLog = '/admin/audit';
export const envList = '/stackInfo';
export const refreshedToken = '/refreshToken';
export const users = '/users';
export const orgs = '/organizations';
export const stacks = '/stacks';
export const serialized = '/serializedDevice';
export const vehicleAddOn = '/addonreport';
export const reports = '/reports';
export const reportsData = '/reporttypes';

export const EMPTY_STRING = 'EMPTY_STRING';
export const FIRMWAREREPO_URL = 'https://firmwarerepo.whelen.com/firmware?';
