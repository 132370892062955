const theme = {
  container: '1360px',
  header_height: '70px',
  sidebar_width_collapsed: '50px',
  sidebar_width_expanded: '280px',
  font: 'Muli, sans-serif',
  font_size_base: '16px',
  line_height_base: '1.2',
  font_size: {
    h1: '66px',
    h2: '42px',
    h3: '28px',
    h4: '24px',
    h5: '18px',
    h6: '14px',
  },
};

export default theme;
