import { createAction } from 'redux-actions';

import { getEnvCounts } from 'core/api/envCounts';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const receiveEnvCounts = createAction('RECEIVE_ENV_COUNTS');
export const requestEnvCounts = createAction('REQUEST_ENV_COUNTS');
export const handleError = createAction('HANDLE_ERROR');

// function is use to fetch the count of all env from organization, user and vehicle.
export const fetchEnvCounts = (stackName, env) => {
  return async (dispatch) => {
    dispatch(requestEnvCounts());
    try {
      const { response } = await getEnvCounts(stackName, env);
      dispatch(receiveEnvCounts(response));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ENV_COUNT, e),
        })
      );
      return false;
    }
  };
};
