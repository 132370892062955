import React from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'shared/ui/modals/ConfirmationModal';
import { device_Reset } from 'core/redux/deviceOperations/actions';

const resetConfirmationModal = ({ device, device_Reset, onCloseModal, selectedAccount, selectedStack }) => {
  return (
    <>
      <ConfirmationModal
        width={800}
        onCloseModal={onCloseModal}
        onSuccess={onCloseModal}
        onFailure={onCloseModal}
        cancelHandler={onCloseModal}
        handleRequestClose={onCloseModal}
        submitHandler={async () => {
          return await device_Reset({
            awsAccount: selectedAccount.value.toLowerCase(),
            device: device,
            stack: selectedStack,
          });
        }}
        bodyText={`Do you really want to reset this vehicle: ${
          device?.vehicleDetails?.label ? device?.vehicleDetails?.label : '- '
        }? All preferences will be reset to default.`}
        title={'Reset Vehicle'}
        svgFileName={'car'}
        iconDimensions={{ height: 23, width: 25 }}
        alt="Device"
        confirmText="Reset"
        cancelText="Cancel"
      />
    </>
  );
};

export default connect(
  (state) => ({
    isFetchingUsers: state.user.isFetchingUsers,
  }),
  (dispatch) => ({
    device_Reset: (deviceDetails) => dispatch(device_Reset(deviceDetails)),
  })
)(resetConfirmationModal);
