/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const EnvCount = styled.div`
  background: white;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 25px;
  margin-bottom: 20px;
  .show-label {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 22px;
    color: ${colors.midnight};
  }
`;
