/**
 * The external dependencies.
 */
import styled, { css } from 'styled-components';

/**
 * The internal dependencies.
 */

import caretDownDark from 'assets/images/caret-down-dark@2x.png';
export const SectionHead = styled.header`
  margin-bottom: 41px;
`;
export const SectionBody = styled.div``;
export const SectionTitle = styled.h4`
  font-family: Muli, sans-serif;
  font-weight: 600;

  ${(props) =>
    props.size &&
    `
		font-size: ${props.size}px;
	`};
`;
export const SectionStatus = styled.h6`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  font-weight: bold;
  text-transform: uppercase;
  color: #8a909c;
`;

export const SectionToggle = styled.div`
  width: 35px;
  background-image: url(${caretDownDark});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8px 5px;
  transition: transform 0.3s;

  ${(props) =>
    props.collapsed === true &&
    `
    transform: rotate(180deg);
  `};
`;

export const SectionInner = styled.div`
  margin-bottom: 23px;
`;
