import { statsInfo } from 'shared/constants/api';
import { apiCall } from 'core/api/API';

export const getEnvCounts = async (stackName, env) => {
  const currentEnv = { env };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${statsInfo}/${stackName}`,
    queryStringParameters: currentEnv,
    method: 'get',
  });
};
