import React, { useState, useEffect } from 'react';
import { SORT_ORDER } from 'shared/constants/table';
import { tableColumnSort } from 'shared/utilities/table';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MatrixOptionRow } from 'shared/styles/components/matrixWrapper';
import { MatrixSelect } from 'shared/styles/components/SelectField';
import * as exports from 'shared/constants/matrixOptions';
import DatetimeRangePicker from 'react-datetime-range-picker';
import moment from 'moment';
import { HeaderButtonDiv } from 'shared/styles/components/Button';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { ReactSelectDefaultStyles } from 'shared/styles/components/SelectField';
import { getStackTable } from 'shared/utilities/localStore';
import { setStackAccount } from 'core/redux/dynamoMatrix/actions';
import { STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';

const matrixOptions = ({ matrixType, stackSelection, optionChangeHandler, envList }) => {
  let now = new Date();
  const location = useLocation();
  let ends = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0, 0));
  let timezone = now.toTimeString().split('GMT')[1].split('(')[0].trim();
  let starts = moment(ends).subtract(3, 'hours');
  let [startDate, setStartDate] = useState(starts);
  let [endDate, setEndDate] = useState(ends);

  let [start, setStart] = useState(starts.valueOf());
  let [end, setEnd] = useState(ends.valueOf());
  const [period, setPeriod] = useState(exports.PERIOD_DEFAULT_OPTION);
  const [stat, setStat] = useState(exports.STAT_DEFAULT_OPTION);
  const [env, setEnv] = useState(exports.ENV_DEFAULT_OPTION);
  const [timeZoneValue, setTimeZoneValue] = useState(exports.TIMEZONE_DEFAULT_OPTION);
  let currentPath = location.pathname;
  const [stackNames, setStackNames] = useState(getStackTable() ? getStackTable()[env?.label.toLowerCase()] : []);
  const [stackOption, setStackOption] = useState(STACK_DEFAULT_OPTION.dev);

  useEffect(() => {
    onApplySelection();
  }, []);

  //On Apply button selection
  const onApplySelection = () => {
    const options = {
      period: period.value,
      stat: stat.value,
      stackName: stackOption?.label,
      env: env.envName,
      format: currentPath === '/dashboard' ? exports.jsonFormat : exports.imageFormat,
      start,
      end,
      timezone: timeZoneValue.value === exports.TIMEZONE_DEFAULT_OPTION.value ? timezone : exports.UTC_TIME,
    };
    if (matrixType === 'iotMatrix' || matrixType === 'gatewayMatrix') {
      delete options['stackName'];
    }
    optionChangeHandler(options);
  };

  const datetimeChangeHandler = (e) => {
    setEnd(e.end.valueOf());
    setStart(e.start.valueOf());
  };
  const transformedEnvList = (envList) => {
    const updatedList = envList.filter(
      (d) => (d.value === 'Dev') & (d.envName === 'Dev') || d.envName === 'Staging' || d.envName === 'Prod'
    );
    return tableColumnSort({ data: updatedList, sortIndex: 'label', order: SORT_ORDER.ASC });
  };

  const periodChangeHandler = (e) => {
    setPeriod(e);
  };
  const statChangeHandler = (e) => {
    setStat(e);
  };

  //for account change selection
  const envChangeHandler = (e) => {
    let stackName = getStackTable();
    setStackNames(stackName[e.label.toLowerCase()]);
    setEnv(e);
    setStackAccount(e);
    setStackOption(STACK_DEFAULT_OPTION[e.label.toLowerCase()]);
  };

  //for stack change selection
  const stackHandler = (e) => {
    setStackOption(e);
  };

  return (
    <MatrixOptionRow>
      <div className="matrixOptions">
        <div className="option">
          <div className="show-label">Date and Time Range: </div>
          <div className="datepicker">
            <DatetimeRangePicker
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/DD/YYYY"
              timeFormat="H:mm"
              pickerClassName="datetime"
              onChange={datetimeChangeHandler}
            />
          </div>
        </div>
        <div className="option">
          <div className="show-label">Period: </div>
          <MatrixSelect
            styles={ReactSelectDefaultStyles}
            value={period}
            clearable={false}
            searchable={false}
            autosize={true}
            options={exports.PERIOD_OPTIONS}
            onChange={periodChangeHandler}
          />
        </div>
        <div className="option">
          <div className="show-label">Stat: </div>
          <MatrixSelect
            styles={ReactSelectDefaultStyles}
            value={stat}
            clearable={false}
            searchable={false}
            autosize={true}
            options={tableColumnSort({ data: exports.STAT_OPTIONS, sortIndex: 'label', order: SORT_ORDER.ASC })}
            onChange={statChangeHandler}
          />
        </div>
        {matrixType !== 'iotMatrix' && matrixType !== 'gatewayMatrix' ? (
          <div className="option">
            <div className="show-label">Account: </div>
            <MatrixSelect
              styles={ReactSelectDefaultStyles}
              value={env}
              clearable={false}
              searchable={false}
              autosize={true}
              options={transformedEnvList(envList)}
              placeholder={'Select'}
              onChange={envChangeHandler}
            />
          </div>
        ) : null}
        {matrixType === 'iotMatrix' || matrixType === 'gatewayMatrix' ? (
          <div className="option">
            <div className="show-label">Account: </div>
            <MatrixSelect
              styles={ReactSelectDefaultStyles}
              value={env}
              clearable={false}
              searchable={false}
              autosize={true}
              options={transformedEnvList(envList)}
              placeholder={'Select'}
              onChange={envChangeHandler}
            />
          </div>
        ) : null}
        {matrixType !== 'iotMatrix' && matrixType !== 'gatewayMatrix' ? (
          <div className="option">
            <div className="show-label">Stack: </div>
            <MatrixSelect
              styles={ReactSelectDefaultStyles}
              value={stackOption}
              clearable={false}
              searchable={false}
              autosize={true}
              options={stackNames}
              placeholder={'Select'}
              onChange={stackHandler}
              isStack
            />
          </div>
        ) : null}
        <div className="option">
          <div className="show-label">Timezone: </div>
          <MatrixSelect
            styles={ReactSelectDefaultStyles}
            value={timeZoneValue}
            clearable={false}
            searchable={false}
            autosize={true}
            options={tableColumnSort({ data: exports.TIMEZONE_OPTIONS, sortIndex: 'label', order: SORT_ORDER.ASC })}
            onChange={(e) => setTimeZoneValue(e)}
          />
        </div>
        <HeaderButtonDiv cancel>
          <ButtonWithLoader confirmText={'Apply'} clickHandler={() => onApplySelection()} />
        </HeaderButtonDiv>
      </div>
    </MatrixOptionRow>
  );
};
export default connect((state) => ({
  envList: state.user.envList,
}))(matrixOptions);
