import React, { useState } from 'react';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'shared/constants/messages';

// serialized list table
const serializedDevicesList = ({ serialDevices, isFetchingSerialDevices, clearAndRestoreSerial }) => {
  const [resetPageNo, setResetPageNo] = useState(false);

  // on click refresh button.
  const refreshData = () => {
    setResetPageNo(!resetPageNo);
    clearAndRestoreSerial();
  };
  let columns = [
    {
      title: 'VSG REGISTRATION ID',
      dataIndex: 'serialDuidTip',
      searchIndex: 'serialDuid',
      key: 'duid',
      searchable: true,
      width: '10%',
      rawVSGID: 'rawVSGID',
      render(value, serialDevices) {
        return (
          <span title={'VSG Registration Id: ' + serialDevices?.serialDuidTip} className="jobNameAndIcon">
            <TruncatedText>{serialDevices?.serialDuid}</TruncatedText>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageListHead>
        <PageListTitle>Serialized Devices</PageListTitle>
        <PageListActionButtonContainer>
          <LoadingText>{isFetchingSerialDevices ? SUCCESS_MESSAGES.FETCHING_RECORD : null}</LoadingText>
          <HeaderButtonDiv cancel>
            <ButtonWithLoader
              disabled={isFetchingSerialDevices}
              isLoading={isFetchingSerialDevices}
              confirmText={'Refresh'}
              loadingStyleProp={'submittingWithSpinner'}
              notLoadingStyleProp={'refresh'}
              clickHandler={refreshData}
            />
          </HeaderButtonDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      <div style={{ textAlign: 'center' }}>Total of {serialDevices.length} Serialized Devices</div>
      <SortableTable
        serialDevice
        fixedWidth
        className="table-simple"
        columns={columns}
        tableData={serialDevices ? serialDevices : []}
        scroll={{ x: false, y: false }}
        refresh={resetPageNo}
        emptyText={ERROR_MESSAGES.EMPTY_TEXT}
      />
    </div>
  );
};

export default serializedDevicesList;
