import { createAction } from 'redux-actions';

import { getJobs, decorateJob } from 'core/api/jobs';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';

export const requestJobs = createAction('REQUEST_JOBS');
export const receiveJobs = createAction('RECEIVE_JOBS');
export const rejectJobs = createAction('REJECT_JOBS');
export const setFetchingJobs = createAction('SET_FETCHING_JOBS');
export const resetJobs = createAction('RESET_JOBS');

export const fetchJobs = (pageSize = PAGINATION_DEFAULT_OPTION.value, nextToken, awsAccount = '') => {
  return async (dispatch) => {
    dispatch(requestJobs());
    try {
      const { response } = await getJobs(pageSize, nextToken, awsAccount);
      dispatch(setFetchingJobs(response.nextToken ? true : false));
      if (response.message) {
        dispatch(
          receiveJobs(
            response.message.map((job) =>
              decorateJob({
                jobFromAPI: job,
              })
            )
          )
        );
        if (response.nextToken) {
          dispatch(fetchJobs(PAGINATION_DEFAULT_OPTION.value, response.nextToken, awsAccount));
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectJobs(false));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_JOBS, e),
        })
      );
      return false;
    }
  };
};

export const clearAndRestoreJobs = (jobDetails) => {
  return async (dispatch) => {
    dispatch(setFetchingJobs(true));
    dispatch(resetJobs([]));
    return dispatch(fetchJobs(undefined, undefined, jobDetails.awsAccount));
  };
};
