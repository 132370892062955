import React from 'react';
import Modal from 'shared/styles/components/Modal';
import { CardLabel, CardValue, CardRow } from 'shared/styles/components/JobDetailsCard';
import { Container, Title, CloseButton, ModalText } from 'shared/styles/components/Modal';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { toGetDate_TimeFormatToLocalOrUTC } from 'shared/utilities/general';

export const ShowOrgDetail = ({ width, closeModal, orgModalDetails }) => {
  const noDataAvailable = '-';
  let contactName = orgModalDetails.contact_name ? orgModalDetails.contact_name : noDataAvailable;
  let contactUserEmail = orgModalDetails.contact_email_id ? orgModalDetails.contact_email_id : noDataAvailable;
  let phoneNumber = orgModalDetails.contact_phone ? orgModalDetails.contact_phone : noDataAvailable;
  let vehicleLimit = orgModalDetails.vehicle_limit ? orgModalDetails.vehicle_limit : noDataAvailable;
  let address_1 = orgModalDetails.address_1 ? orgModalDetails.address_1 : '';
  let address_2 = orgModalDetails.address_2 ? ', ' + orgModalDetails.address_2 : '';
  let address_city = orgModalDetails.address_city ? ', ' + orgModalDetails.address_city : '';
  let address_state = orgModalDetails.address_state ? ', ' + orgModalDetails.address_state : '';

  let fullAddress = address_1 + ' ' + address_2 + ' ' + address_city + ' ' + address_state;

  let customerType = orgModalDetails?.customer_type ? orgModalDetails.customer_type : noDataAvailable;

  let startDate =
    orgModalDetails.start_date && orgModalDetails.start_date.match(/^\d{4}-\d{2}-\d{2}$/)
      ? toGetDate_TimeFormatToLocalOrUTC({ date: orgModalDetails.start_date, fullDate: false })
      : noDataAvailable;

  let endDate =
    orgModalDetails.end_date && orgModalDetails.end_date.match(/^\d{4}-\d{2}-\d{2}$/)
      ? toGetDate_TimeFormatToLocalOrUTC({ date: orgModalDetails.end_date, fullDate: false })
      : noDataAvailable;
  const handleCloseModal = () => {
    closeModal(false);
  };
  return (
    <Modal>
      <Container autoHeight maxWidth={width ? width : 600}>
        <CloseButton
          onClick={() => {
            closeModal(false);
          }}
        >
          <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
        </CloseButton>
        <Title style={styles.title}>{orgModalDetails.organization_name}</Title>
        <ModalText border>
          <CardRow>
            <CardLabel orgLabel>
              <span>Contact Name</span>
            </CardLabel>
            <CardValue>{contactName}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Contact Email</span>
            </CardLabel>
            <CardValue>{contactUserEmail}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Contact Phone</span>
            </CardLabel>
            <CardValue>{phoneNumber}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Address</span>
            </CardLabel>
            <CardValue>{fullAddress}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Customer Type</span>
            </CardLabel>
            <CardValue>{customerType}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Start Date</span>
            </CardLabel>
            <CardValue>{startDate}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>End Date</span>
            </CardLabel>
            <CardValue>{endDate}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel orgLabel>
              <span>Vehicle Limit</span>
            </CardLabel>
            <CardValue>{vehicleLimit}</CardValue>
          </CardRow>
        </ModalText>
      </Container>
      <div style={styles.overlay} onClick={handleCloseModal}></div>
    </Modal>
  );
};

const orgDetail = ({ width, closeModal, OrgModalDetails }) => {
  const type = Object.getPrototypeOf(OrgModalDetails).constructor.name;
  if (type === 'Object') {
    return <ShowOrgDetail width={width} closeModal={closeModal} orgModalDetails={OrgModalDetails} />;
  } else {
    return <ShowOrgDetail width={width} closeModal={closeModal} orgModalDetails={OrgModalDetails[0]} />;
  }
};
const styles = {
  title: {
    marginBottom: '20px',
    wordBreak: 'break-all',
  },
  icon: {
    marginRight: '10px',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: 0.2,
    backgroundColor: '#000000',
    overflow: 'auto',
  },
};

export default orgDetail;
