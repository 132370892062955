import React from 'react';
import JSONTree from 'react-json-view';
import useAPIData from 'shared/hooks/useAPIData';
import { fetchDeviceShadow } from 'core/redux/deviceOperations/actions';
import { JsonTree, DownloadBtn, JsonExpandCollapse } from 'shared/styles/components/JsonTree';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import { Button } from 'shared/styles/components/Button';
import { useEffect } from 'react';
import { getShadow } from 'shared/utilities/localStore';
import { useState } from 'react';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { NO_PAGE_DATA } from '../../../shared/constants/messages';

const DEFAULT_JSON_LENGTH = 2;
const ExpandCaret = { paddingLeft: '4px' };
const Unlocked = 'Unlocked';
const Locked = 'Locked';

// Expand All Component
const ExpandAll = () => {
  return (
    <>
      <span>Expand All</span>
      <IconSvgComponent style={ExpandCaret} svgFileName={'caret-front'} />
    </>
  );
};

// Collapse All Component
const CollapseAll = () => {
  return (
    <>
      <span>Collapse All</span>
      <IconSvgComponent style={ExpandCaret} svgFileName={'caret-down'} />
    </>
  );
};

const deviceShadow = ({ shadow, fetchDeviceShadow, selectedDevice, isError, awsAccount }) => {
  const [expandAllStateInfo, setExpandAllStateInfo] = useState(false);
  const [expandAllMetaInfo, setExpandAllMetaInfo] = useState(false);
  const [viewStateInfo, setViewStateInfo] = useState(true);
  const [viewMetaInfo, setViewMetaInfo] = useState(true);
  const deviceShadowLoaded = useAPIData({
    fetchAPIData: () => fetchDeviceShadow({ deviceId: selectedDevice, awsAccount: awsAccount }),
    dependencies: [],
  });

  useEffect(() => {
    updateDeviceShadow(shadow);
  }, [shadow]);

  const updateDeviceShadow = (obj) => {
    if (obj?.access === 15) {
      obj.access = Unlocked;
    } else if (obj?.access === 1) {
      obj.access = Locked;
    }
    if (obj?.timestamp) {
      let date = new Date(obj.timestamp * 1000);
      // convert timestamp to date
      obj.timestamp = date.toLocaleString('en-US', { hour12: false });
    }
    for (let k in obj) {
      if (typeof obj[k] === 'object') {
        updateDeviceShadow(obj[k]);
      }
    }
  };

  // To re-render the State Info JSON object for expand or collapse all operation
  useEffect(() => {
    if (!viewStateInfo) {
      setViewStateInfo(true);
    }
  }, [viewStateInfo]);

  // To re-render the Meta Info JSON object for expand or collapse all operation
  useEffect(() => {
    if (!viewMetaInfo) {
      setViewMetaInfo(true);
    }
  }, [viewMetaInfo]);

  return deviceShadowLoaded || isError ? (
    <>
      <DownloadBtn
        disabled={
          !(
            shadow?.metadata &&
            Object.keys(shadow.metadata).length > 0 &&
            shadow?.state &&
            Object.keys(shadow.state).length > 0
          )
        }
      >
        <Button className="downloadBtn">
          <a
            type="button"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(getShadow(), 0, 2))}`}
            download={`${selectedDevice}.json`}
          >
            Download
          </a>
        </Button>
      </DownloadBtn>
      <JsonTree>
        <div className="tree-grid">
          {shadow?.state && Object.keys(shadow.state).length > 0 ? (
            <>
              <h4>
                State Information
                <JsonExpandCollapse
                  onClick={() => {
                    setExpandAllStateInfo((prev) => {
                      setViewStateInfo(false);
                      return !prev;
                    });
                  }}
                >
                  {expandAllStateInfo ? <CollapseAll /> : <ExpandAll />}
                </JsonExpandCollapse>
              </h4>
              <>
                {viewStateInfo && (
                  <JSONTree
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    collapsed={expandAllStateInfo ? false : DEFAULT_JSON_LENGTH}
                    src={shadow?.state}
                  />
                )}
              </>
            </>
          ) : (
            <>
              <h4> State Information </h4>
              <p> {NO_PAGE_DATA.DEVICE_NO_STATE_INFORMATON} </p>
            </>
          )}
        </div>
        <div className="tree-grid">
          {shadow?.state && Object.keys(shadow.state).length > 0 ? (
            <>
              <h4>
                Meta Information
                <JsonExpandCollapse
                  onClick={() => {
                    setExpandAllMetaInfo((prev) => {
                      setViewMetaInfo(false);
                      return !prev;
                    });
                  }}
                >
                  {expandAllMetaInfo ? <CollapseAll /> : <ExpandAll />}
                </JsonExpandCollapse>
              </h4>
              <>
                {viewMetaInfo && (
                  <JSONTree
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    collapsed={expandAllMetaInfo ? false : DEFAULT_JSON_LENGTH}
                    src={shadow?.metadata}
                  />
                )}
              </>
            </>
          ) : (
            <>
              <h4> Meta Information </h4>
              <p> {NO_PAGE_DATA.DEVICE_NO_META_INFORMATON} </p>
            </>
          )}
        </div>
      </JsonTree>
    </>
  ) : (
    <LoadingOverlay />
  );
};
export default connect(
  (state) => ({
    shadow: state.deviceOperations.shadow,
    isError: state.deviceOperations?.isError,
  }),
  (dispatch) => ({
    fetchDeviceShadow: (deviceDetails) => {
      return dispatch(fetchDeviceShadow(deviceDetails));
    },
  })
)(deviceShadow);
