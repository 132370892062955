import { handleActions } from 'redux-actions';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from '../../../shared/constants/matrixOptions';
import {
  receiveDeviceShadow,
  receiveDeviceSimInfo,
  requestDeviceSimInfo,
  handleError,
  requestDeviceShadow,
  receiveDevices,
  requestDevices,
  requestEnvChange,
  receiveJobs,
  setFetchingJobs,
  resetJobs,
  rejectJobs,
  setFetchingdevices,
  resetDevices,
  setInitialDeviceLoads,
  setStackSelection,
  setStackAccount,
  resetOptions,
  setOrg,
  setSearchTextGlobal,
  setExternalSearch,
  setClearSearchText,
} from './actions';

const defaultState = {
  devices: [],
  shadow: {},
  simInfo: {},
  deviceJobs: [],
  isError: false,
  isFetchingJobs: false,
  isFetchingDevices: false,
  initialPageLoad: false,
  stackSelection: STACK_DEFAULT_OPTION.dev,
  selectedAccount: ENV_DEFAULT_OPTION,
  selectedOrg: null,
  searchTextDevice: '',
  externalSearch: false,
  clearSearchText: true,
};

const deviceOperations = handleActions(
  {
    [setFetchingdevices]: (state, { payload: isFetchingDevices }) => ({
      ...state,
      isFetchingDevices: isFetchingDevices,
      isError: false,
    }),

    [requestDevices]: (state) => ({
      ...state,
      isError: false,
      isFetchingDevices: true,
      devices: [],
    }),
    [receiveDevices]: (state, { payload: devices }) => ({
      ...state,
      devices: state.devices.concat(devices),
    }),
    [requestEnvChange]: (state, { payload: deviceId }) => {
      return {
        ...state,
        devices: state.devices.map((device) => {
          device.loadingStatus = device.loadingStatus || device.deviceId === deviceId;
          return device;
        }),
      };
    },
    [receiveDeviceShadow]: (state, { payload: shadow }) => ({
      ...state,
      shadow: shadow,
    }),
    [requestDeviceShadow]: (state) => ({
      ...state,
      shadow: {},
      isError: false,
    }),
    [requestDeviceSimInfo]: (state) => ({
      ...state,
      simInfo: {},
      isError: false,
    }),
    [receiveDeviceSimInfo]: (state, { payload: simInfo }) => ({
      ...state,
      simInfo: simInfo,
    }),
    [handleError]: (state) => ({
      ...state,
      shadow: {},
      simInfo: {},
      isError: true,
      isFetchingDevices: false,
      devices: state.devices.map((device) => ({
        ...device,
        loadingStatus: false,
      })),
    }),
    [setFetchingJobs]: (state, { payload: isFetchingJobs }) => ({
      ...state,
      isFetchingJobs: isFetchingJobs,
      isError: false,
    }),
    [receiveJobs]: (state, { payload: jobs }) => {
      return {
        ...state,
        deviceJobs: state.deviceJobs.concat(jobs),
      };
    },
    [resetDevices]: (state, { payload }) => {
      return {
        ...state,
        devices: payload,
      };
    },
    [resetJobs]: (state, { payload: jobs }) => {
      return {
        ...state,
        deviceJobs: jobs,
      };
    },
    [rejectJobs]: (state, { payload: isFetchingJobs }) => ({
      ...state,
      isFetchingJobs: isFetchingJobs,
      isError: true,
      deviceJobs: [],
    }),
    [setInitialDeviceLoads]: (state, { payload }) => ({
      ...state,
      initialPageLoad: payload,
      isError: false,
    }),
    [setStackSelection]: (state, { payload }) => ({
      ...state,
      stackSelection: payload,
      isError: false,
    }),
    [setStackAccount]: (state, { payload }) => ({
      ...state,
      selectedAccount: payload,
      isError: false,
    }),
    [resetOptions]: (state) => ({
      ...state,
      selectedAccount: defaultState.selectedAccount,
      stackSelection: defaultState.stackSelection,
      initialPageLoad: false,
    }),
    [setOrg]: (state, { payload }) => ({
      ...state,
      selectedOrg: payload,
    }),
    [setSearchTextGlobal]: (state, { payload }) => ({
      ...state,
      searchTextDevice: payload,
    }),
    [setExternalSearch]: (state, { payload }) => ({
      ...state,
      externalSearch: payload,
    }),
    [setClearSearchText]: (state, { payload }) => ({
      ...state,
      clearSearchText: payload,
    }),
  },
  defaultState
);

export default deviceOperations;
