export const size = {
  // 1460px = 91.25em
  xxl: '91.25em',
  // 1200px = 75em
  xl: '75em',
  // 1024px = 64em
  lg: '64em',
  // 768px = 48em
  md: '48em',
  // 600px = 37.5em
  sm: '37.5em',
};

export const hexWithOpacity = (hexColor, alpha) => `rgba(
      ${parseInt(hexColor.slice(1, 3), 16)},
      ${parseInt(hexColor.slice(3, 5), 16)},
      ${parseInt(hexColor.slice(5, 7), 16)},
      ${alpha})`;

// BREAKPOINTS
export const minWidth = {
  xxl: `(min-width: ${size.xxl})`,
  xl: `(min-width: ${size.xl})`,
  lg: `(min-width: ${size.lg})`,
  md: `(min-width: ${size.md})`,
  sm: `(min-width: ${size.sm})`,
};

export const colors = {
  fog: '#f9f9f9',
  concrete: '#e5e5e5',
  metal: '#e1e5ea',
  gray50: '#cccccc',
  haze: '#8a909c',
  primary: '#206bc4',
  cherry: '#e72729',
  lightTangerine: '#e46473',
  cotton: '#ffffff',
  midnight: '#1c1c1c',
  black: '#000000',
  blackberry: '#001f28',
  charcoal: '#424242',
  grass: '#4ca311',
  muted: '#616876',
  stone: '#999',
};

export const breakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const device = {
  xxl: `(max-width: ${breakpoints.xxl})`,
  xl: `(max-width: ${breakpoints.xl})`,
  lg: `(max-width: ${breakpoints.lg})`,
  md: `(max-width: ${breakpoints.md})`,
  sm: `(max-width: ${breakpoints.sm})`,
  xs: `(max-width: ${breakpoints.xs})`,
};

// Font Size
export const fontSize = {
  xs: '11px',
  sm: '12px',
  md: '13px',
  normal: '.875rem',
  lg: '1rem',
  lead: '1.75rem',
  xl: '1.25rem',
  xxl: '3rem',
  display1: '5rem',
  display2: '7rem;',
  display3: '10rem',
};

export const dir = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

// Spacing Size
export const spacing = {
  xs: '.25rem',
  sm: '.5rem',
  size1: '.75rem',
  size2: '1rem',
  size3: '1.5rem',
  size4: '2rem',
  size5: '2.5rem',
  size6: '3rem',
  size7: '3.5rem',
  size8: '4rem',
};

export const property = {
  margin: 'margin',
  padding: 'padding',
};

// Line Height
export const lineHeight = {
  normal: 1,
  loose: 1.2,
  tight: 0.9,
};

// font Weight
export const fontWeight = {
  fontWeightThin: 100,
  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
};

// font Weight
export const borderRadius = {
  xs: '3px',
  sm: '5px',
  md: '8px',
  lg: '12px',
  xl: '15px',
};
