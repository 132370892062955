import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggle, toggleGlobalOverlay } from 'core/redux/ui/actions';
import ProfileWidget, {
  ProfileWidgetHolder,
  ProfileWidgetAvatar,
  ProfileWidgetGivenName,
} from 'shared/styles/components/ProfileWidget';
import DropdownMenu, { DropdownMenuTrigger } from 'shared/styles/components/DropdownMenu';

const ProfileWidgetComponent = ({ user }) => {
  return (
    <ProfileWidget>
      <ProfileWidgetHolder borderleft>
        <ProfileWidgetGivenName>{user.name}</ProfileWidgetGivenName>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <ProfileWidgetAvatar></ProfileWidgetAvatar>
          </DropdownMenuTrigger>
        </DropdownMenu>
      </ProfileWidgetHolder>
    </ProfileWidget>
  );
};

ProfileWidgetComponent.propTypes = {
  avatar: PropTypes.string,
};

export default connect(
  (state) => ({
    globalOverlayIsVisible: state.ui.globalOverlayIsVisible,
    user: state.user.user,
  }),
  (dispatch) => ({
    toggle: (selector) => dispatch(toggle(selector)),
    toggleGlobalOverlay: () => dispatch(toggleGlobalOverlay()),
  })
)(ProfileWidgetComponent);
