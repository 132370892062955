import { apiCall } from 'core/api/API';
import { serialized } from 'shared/constants/api';
import { getFormattedDuid } from 'shared/utilities/general';

// add Serialized Devices details keys for searching
export const decorateSerializedDevices = ({ serialData }) => {
  serialData.serialDuid = getFormattedDuid(serialData.duid);
  serialData.serialDuidTip = getFormattedDuid(serialData.duid);
  serialData.rawVSGID = serialData.duid;
  return serialData;
};

//for Serialized Devices list pagination
export const serializedDevicesList = async () => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: serialized,
    method: 'get',
  });
};
