import React from 'react';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { StyleChart } from 'shared/styles/components/charts';
import { UTC_TIME } from 'shared/constants/matrixOptions';
import { toGetDate_TimeFormatToLocalOrUTC } from 'shared/utilities/general';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const chart = ({ jsonFormData, options: chartDataset }) => {
  let uniqueTimeStamp = new Set([]);
  let labels = [];
  let values = [];

  const displayChart = () => {
    return (
      <>
        {jsonFormData && (
          <StyleChart>
            <Line options={options} width={70} height={40} data={data()} />
          </StyleChart>
        )}
      </>
    );
  };
  // generate a random color code
  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // To change the date time to Local or UTC format
  const changeDateFormatToLocalOrUTC = (date) => {
    let value = new Date(date);
    if (chartDataset && chartDataset.timezone && chartDataset.timezone !== UTC_TIME) {
      return toGetDate_TimeFormatToLocalOrUTC({ date: value });
    } else {
      return toGetDate_TimeFormatToLocalOrUTC({ date: value, isUTC: true });
    }
  };

  // Prepare an array of sorted TimeStamp
  const mergedXAxis = () => {
    jsonFormData?.MetricDataResults.map((obj) => {
      uniqueTimeStamp = new Set([...uniqueTimeStamp, ...obj.Timestamps]);
      labels = [...uniqueTimeStamp].sort();
      labels.forEach((value, index) => {
        labels[index] = changeDateFormatToLocalOrUTC(value);
      });
    });
  };
  const dataHandler = () => {
    mergedXAxis();
    jsonFormData?.MetricDataResults.map((obj) => {
      // prepare the key value pair for xAxis and yAxis for each api
      let updatedData = obj.Values.reduce(function (result, field, index) {
        result[changeDateFormatToLocalOrUTC(obj.Timestamps[index])] = field;
        return result;
      }, {});
      const chartValue = labels.map((obj) => {
        // if the time Stamp is present in the merged timeStamp array then return value else return null in an array
        if (updatedData?.hasOwnProperty(obj)) {
          return updatedData[obj];
        } else {
          return null;
        }
      });

      let randomColor = getRandomColor();
      values.push({
        label: obj.Label,
        data: [...chartValue],
        borderColor: randomColor,
        backgroundColor: randomColor,
      });
      displayChart();
    });
  };
  const data = () => {
    return {
      labels,
      datasets: values,
    };
  };

  const options = {
    responsive: true,
    backgroundColor: 'white',
    plugins: {
      legend: {
        align: 'start',
        position: 'bottom',
      },
      title: {
        display: true,
        align: 'start',
        text: jsonFormData.chartType,
        font: {
          weight: 'bold',
          size: '30',
        },
      },
    },
  };

  dataHandler();
  return displayChart();
};

export default chart;
