import styled from 'styled-components';

import colors from 'shared/constants/colors';

export const Container = styled.div`
  position: relative;
  max-width: 680px;
  max-height: 430px;
  width: 100%;
  margin: auto;
  padding: 23px;
  font-family: Muli, sans-serif;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

  ${(props) =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}

  ${(props) =>
    props.autoHeight &&
    `
    max-height: none;
    height: auto;
  `};
`;

export const CloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  color: ${colors.midnight};
  font-size: 24px;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 25px;
`;

export const ModalText = styled.div`
  padding: 0 24px 24px 0;
  font-size: 14px;
  letter-spacing: 1;
  color: ${colors.haze};
  ${(props) =>
    props.border &&
    `
    padding:24px;
    border: 1px solid #dfdfdf;
  `};
`;

export const confirmCloseModal = styled.div`
  .container {
    position: absolute;
    padding: 23px;
    max-width: 473px;
    font-family: 'Muli, sans-serif';
    font-weight: 800;
    color: ${colors.midnight};
    background-color: #ffff;
    z-index: 3;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  }
  .prompt {
    padding: 14px 24px 0 24px;
  }
  .closeButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-right: 24px;
  }
`;

export const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
`;

export default Modal;
