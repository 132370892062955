export const PAGINATION_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const PAGINATION_DEFAULT_OPTION = { label: '50', value: 50 };
