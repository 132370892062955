import { vehicleAddon, decorateAddOn } from 'core/api/vehicleAddon';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { addNotification } from '../ui/actions';
import { createAction } from 'redux-actions';

export const requestAddons = createAction('REQUEST_ADDONS');
export const receiveAddons = createAction('RECEIVE_ADDONS');
export const rejectAddons = createAction('REJECT_ADDONS');
export const setFetchingAddons = createAction('SET_FETCHING_ADDONS');
export const resetAddons = createAction('RESET_ADDONS');

// API call for fetching add-on vehicle data
export const fetchAddons = (awsAccount = '', stack = '') => {
  return async (dispatch) => {
    dispatch(requestAddons());
    try {
      const { response } = await vehicleAddon(awsAccount, stack);
      dispatch(setFetchingAddons(false));
      if (response.message) {
        dispatch(
          receiveAddons(
            response.message.map((addon) =>
              decorateAddOn({
                addOnData: addon,
              })
            )
          )
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectAddons(false));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ADDON, e),
        })
      );
      return false;
    }
  };
};

export const clearAndRestoreAddons = (awsAccount, stack) => {
  return async (dispatch) => {
    dispatch(setFetchingAddons(true));
    dispatch(resetAddons([]));
    return dispatch(fetchAddons(awsAccount, stack));
  };
};
