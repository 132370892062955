import { handleActions } from 'redux-actions';
import { receiveJobs, setFetchingJobs, resetJobs, rejectJobs } from './actions';

const defaultState = {
  jobs: [],
  isFetchingJobs: false,
  isError: false,
};

const jobs = handleActions(
  {
    [setFetchingJobs]: (state, { payload: isFetchingJobs }) => ({
      ...state,
      isFetchingJobs: isFetchingJobs,
      isError: false,
    }),
    [receiveJobs]: (state, { payload: jobs }) => {
      return {
        ...state,
        jobs: state.jobs.concat(jobs),
      };
    },
    [resetJobs]: (state, { payload: jobs }) => {
      return {
        ...state,
        jobs: jobs,
      };
    },
    [rejectJobs]: (state, { payload: isFetchingJobs }) => ({
      ...state,
      isFetchingJobs: isFetchingJobs,
      isError: true,
      jobs: [],
    }),
  },
  defaultState
);

export default jobs;
