import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { openSidebar, closeSidebar, requestEndCloseSidebar } from 'core/redux/sidebar/actions';
import Sidebar from 'shared/styles/components/Sidebar';
import NavComponent from './NavComponent';

class SidebarComponent extends Component {
  openSidebar = () => {
    const { openedOnClick, openSidebar } = this.props;
    if (openedOnClick) return;
    openSidebar();
  };

  closeSidebar = () => {
    const { openedOnClick, closeSidebar } = this.props;
    if (openedOnClick) return;
    closeSidebar();
  };

  render() {
    const { expanded, sidebarIsCollapsed, requestEndCloseSidebar, fullscreenMode } = this.props;

    return (
      <Sidebar
        expanded={expanded}
        onMouseEnter={this.openSidebar}
        onMouseLeave={this.closeSidebar}
        onTransitionEnd={requestEndCloseSidebar}
        fullscreenMode={fullscreenMode}
      >
        <NavComponent sidebarIsCollapsed={sidebarIsCollapsed} />
      </Sidebar>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      expanded: state.sidebar.visible,
      sidebarIsCollapsed: !state.sidebar.visible,
      openedOnClick: state.sidebar.openedOnClick,
    }),
    {
      openSidebar,
      closeSidebar,
      requestEndCloseSidebar,
    }
  )(SidebarComponent)
);
