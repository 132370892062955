import React from 'react';
import { CardLabel, CardValue, CardRow } from 'shared/styles/components/JobDetailsCard';
import { JobDetailCardHeader, JobDetailWrapper } from 'shared/styles/components/Header';
import moment from 'moment';

function jobDetailsHeader({ resource, targets }) {
  return (
    <JobDetailWrapper>
      <JobDetailCardHeader>
        <CardRow>
          <CardLabel>Job Id:</CardLabel>
          <CardValue>{resource?.jobId}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Canceled Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfCanceledThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Status:</CardLabel>
          <CardValue>{resource?.status}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Succeeded Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfSucceededThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Target Selection:</CardLabel>
          <CardValue>{resource?.targetSelection}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Failed Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfFailedThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Comment:</CardLabel>
          <CardValue>{resource?.comment}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Rejected Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfRejectedThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Device to Update:</CardLabel>
          <CardValue>{`${targets} thing(s)`}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Queued Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfQueuedThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Created at:</CardLabel>
          <CardValue>{moment(resource.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>InProgress Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfInProgressThings}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Completed at:</CardLabel>
          <CardValue>{resource?.completedAt}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Removed Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfRemovedThings}</CardValue>
        </CardRow>

        <CardRow>
          <CardLabel>Job Description:</CardLabel>
          <CardValue>{resource?.description}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>TimedOut Things:</CardLabel>
          <CardValue>{resource?.jobProcessDetails?.numberOfTimedOutThings}</CardValue>
        </CardRow>
      </JobDetailCardHeader>
    </JobDetailWrapper>
  );
}

export default jobDetailsHeader;
