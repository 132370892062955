import React from 'react';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

import { DebounceInput } from 'react-debounce-input';
import { SearchBox } from '../../styles/components/PageList';

const Searchbox = ({
  searchText,
  searchChangeHandler,
  filterChangeHandler,
  validationMessage,
  devicePage,
  getUnformattedDuid,
  userPage,
}) => {
  // Enter key handler
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      filterChangeHandler({ search_key: searchText, searchType: 'click' });
    }
  };

  return !devicePage && !userPage ? (
    <SearchBox>
      <DebounceInput
        type="text"
        className="search"
        placeholder="Search"
        onKeyDown={onKeyDownHandler}
        value={searchText}
        onChange={searchChangeHandler}
      />
      {searchText ? (
        <button className="Search-box-button" onClick={() => filterChangeHandler({ search_key: '' })}>
          <IconSvgComponent svgFileName="searbox-close" title="Reset" alt="SearboxClose" />
        </button>
      ) : null}
      <button type="button" className="searchButton" onClick={() => filterChangeHandler({ search_key: searchText })}>
        <IconSvgComponent className="search-icon" svgFileName="searchbox" title="Search" alt="Search" />
      </button>
    </SearchBox>
  ) : (
    <SearchBox devicePage invalid={validationMessage ? true : false}>
      <div style={{ display: 'flex' }}>
        <DebounceInput
          type="text"
          className="search"
          placeholder={userPage ? 'Please enter user name' : 'Please enter VSG ID'}
          onKeyDown={!validationMessage ? onKeyDownHandler : undefined}
          value={searchText}
          onChange={searchChangeHandler}
        />
        {searchText ? (
          <button className="Search-box-button" onClick={() => filterChangeHandler({ search_key: '', clear: true })}>
            <IconSvgComponent svgFileName="searbox-close" title="Reset" alt="SearboxClose" />
          </button>
        ) : null}
        <button
          type="button"
          className="searchButton"
          onClick={() => filterChangeHandler({ search_key: searchText, searchType: 'click' })}
          disabled={userPage ? false : getUnformattedDuid(searchText).length <= 23 ? 'disabled' : false}
        >
          <IconSvgComponent className="search-icon" svgFileName="searchbox" title="Search" alt="Search" />
        </button>
      </div>
      <div style={{ fontSize: '12px', color: 'rgb(231, 39, 41)', marginTop: '2px', height: '1px' }}>
        {validationMessage}
      </div>
    </SearchBox>
  );
};

export default Searchbox;
