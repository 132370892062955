import { apiCall } from 'core/api/API';
import { vehicleAddOn } from 'shared/constants/api';

export const decorateAddOn = ({ addOnData }) => {
  return addOnData;
};

export const vehicleAddon = async (awsAccount, stack) => {
  let queryStringParameters;
  queryStringParameters = {
    env: awsAccount,
    tableStackName: stack,
  };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: vehicleAddOn,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};
