import React, { useState } from 'react';
import useAPIData from 'shared/hooks/useAPIData';
import {
  fetchGateway5XXError,
  fetchGatewayCount,
  fetchGatewayIntegrationLatency,
  fetchGatewayLatency,
} from 'core/redux/gatewayMatrix/actions';
import Matrix from 'features/dashboard/components/matrix';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import MatrixOptions from 'shared/ui/matrix/matrixOptions';
import Charts from 'features/dashboard/components/charts';

const gatewayMatrix = ({
  getway5xxError,
  gatewayCount,
  integrationLatency,
  latency,
  fetchGateway5XXError,
  fetchGatewayCount,
  fetchGatewayIntegrationLatency,
  fetchGatewayLatency,
  isError,
}) => {
  let [options, setOptions] = useState(null);

  const is5xxError = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchGateway5XXError(options);
      }
    },
    dependencies: [options],
  });

  const isCount = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchGatewayCount(options);
      }
    },
    dependencies: [options],
  });
  const isIntegrationLatency = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchGatewayIntegrationLatency(options);
      }
    },
    dependencies: [options],
  });
  const isLatency = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchGatewayLatency(options);
      }
    },
    dependencies: [options],
  });

  const optionChangeHandler = (e) => {
    setOptions(e);
  };
  return (
    <>
      <MatrixOptions optionChangeHandler={optionChangeHandler} matrixType="gatewayMatrix" />
      {(is5xxError && isCount && isIntegrationLatency && isLatency) || isError ? (
        options?.format !== 'json' ? (
          <div>
            {getway5xxError?.message ? <Matrix base64Data={getway5xxError.message} /> : null}
            {gatewayCount?.message ? <Matrix base64Data={gatewayCount.message} /> : null}
            {integrationLatency?.message ? <Matrix base64Data={integrationLatency.message} /> : null}
            {latency?.message ? <Matrix base64Data={latency.message} /> : null}
          </div>
        ) : (
          <>
            {getway5xxError?.message ? (
              <Charts options={options} jsonFormData={{ ...getway5xxError.message, chartType: '5xxError' }} />
            ) : null}
            {gatewayCount?.message ? (
              <Charts options={options} jsonFormData={{ ...gatewayCount.message, chartType: 'Count' }} />
            ) : null}
            {integrationLatency?.message ? (
              <Charts
                options={options}
                jsonFormData={{ ...integrationLatency.message, chartType: 'IntegrationLatency' }}
              />
            ) : null}
            {latency?.message ? (
              <Charts options={options} jsonFormData={{ ...latency.message, chartType: 'Latency' }} />
            ) : null}
          </>
        )
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
export default connect(
  (state) => ({
    getway5xxError: state.gatewayMatrix.getway5xxError,
    gatewayCount: state.gatewayMatrix.gatewayCount,
    integrationLatency: state.gatewayMatrix.integrationLatency,
    latency: state.gatewayMatrix.latency,
    isError: state.gatewayMatrix?.isError,
  }),
  (dispatch) => ({
    fetchGateway5XXError: (params) => {
      return dispatch(fetchGateway5XXError(params));
    },
    fetchGatewayCount: (params) => {
      return dispatch(fetchGatewayCount(params));
    },
    fetchGatewayIntegrationLatency: (params) => {
      return dispatch(fetchGatewayIntegrationLatency(params));
    },
    fetchGatewayLatency: (params) => {
      return dispatch(fetchGatewayLatency(params));
    },
  })
)(gatewayMatrix);
