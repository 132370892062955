import React from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'shared/ui/modals/ConfirmationModal';
import { user_Reset } from 'core/redux/user/actions';

const resetConfirmationModal = ({ user, user_Reset, onCloseModal, selectedAccount, selectedStack }) => {
  return (
    <>
      <ConfirmationModal
        width={800}
        onCloseModal={onCloseModal}
        onSuccess={onCloseModal}
        onFailure={onCloseModal}
        cancelHandler={onCloseModal}
        handleRequestClose={onCloseModal}
        submitHandler={async () => {
          return await user_Reset({
            awsAccount: selectedAccount.value.toLowerCase(),
            user: user,
            stack: selectedStack,
          });
        }}
        bodyText={`Do you really want to reset this user: ${
          user?.Username ? user?.Username : '- '
        }? It will reset password and two-factor option.`}
        title={'Reset User'}
        svgFileName={'users'}
        iconDimensions={{ height: 20, width: 22 }}
        alt="User"
        confirmText="Reset"
        cancelText="Cancel"
      />
    </>
  );
};

export default connect(
  (state) => ({
    isFetchingUsers: state.user.isFetchingUsers,
  }),
  (dispatch) => ({
    user_Reset: (userDetails) => dispatch(user_Reset(userDetails)),
  })
)(resetConfirmationModal);
