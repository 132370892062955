import { apiCall } from 'core/api/API';
import { orgs } from 'shared/constants/api';
import { toGetDate_TimeFormatToLocalOrUTC } from 'shared/utilities/general';

// add organization details keys for searching
export const decorateOrgs = ({ orgFromAPI }) => {
  orgFromAPI.searchOrgName = orgFromAPI.organization_name ? orgFromAPI.organization_name : '-';
  orgFromAPI.searchEmail = orgFromAPI.contact_email_id ? orgFromAPI.contact_email_id : '-';
  let address1 = orgFromAPI.address_1 ? orgFromAPI.address_1 : '';
  let address2 = orgFromAPI.address_2 ? ', ' + orgFromAPI.address_2 : '';
  let addressCity = orgFromAPI.address_city ? ', ' + orgFromAPI.address_city : '';
  orgFromAPI.searchAddress = address1 + address2 + addressCity;
  orgFromAPI.searchForAddress = address1 + address2 + addressCity;

  orgFromAPI.searchAddressState = orgFromAPI.address_state ? orgFromAPI.address_state : '-';
  orgFromAPI.searchCustomerType = orgFromAPI.customer_type ? orgFromAPI.customer_type : '-';
  orgFromAPI.searchContactPhone = orgFromAPI.contact_phone ? orgFromAPI.contact_phone : '-';
  orgFromAPI.searchStartDate = orgFromAPI.startDate =
    orgFromAPI.start_date && orgFromAPI.start_date.match(/^\d{4}-\d{2}-\d{2}$/)
      ? toGetDate_TimeFormatToLocalOrUTC({ date: orgFromAPI.start_date, fullDate: false })
      : 'NA';
  orgFromAPI.searchEndDate = orgFromAPI.endDate =
    orgFromAPI.end_date && orgFromAPI.end_date.match(/^\d{4}-\d{2}-\d{2}$/)
      ? toGetDate_TimeFormatToLocalOrUTC({ date: orgFromAPI.end_date, fullDate: false })
      : 'NA';
  orgFromAPI.startDateValue =
    orgFromAPI.start_date && orgFromAPI.start_date.match(/^\d{4}-\d{2}-\d{2}$/) ? orgFromAPI.start_date : 'NA';
  orgFromAPI.endDateValue =
    orgFromAPI.end_date && orgFromAPI.end_date.match(/^\d{4}-\d{2}-\d{2}$/) ? orgFromAPI.end_date : 'NA';
  orgFromAPI.searchVehicleCount = orgFromAPI.vehicle_count ? orgFromAPI.vehicle_count : '-';
  orgFromAPI.searchOrganizationActive = orgFromAPI.organization_active ? orgFromAPI.organization_active : '-';

  // Hass search and sort data
  orgFromAPI.searchHaasEnabled = orgFromAPI.haas_enabled;
  orgFromAPI.searchHaasSetup = orgFromAPI.haas_setup;
  orgFromAPI.sortHaasEnabled = orgFromAPI.searchHaasEnabled ? 1 : 0;
  orgFromAPI.sortHaasEnabled =
    orgFromAPI.sortHaasEnabled && orgFromAPI.searchHaasSetup ? 2 : orgFromAPI.sortHaasEnabled;

  // GTT search and sort data
  orgFromAPI.searchGttEnabled = orgFromAPI.gtt_enabled;
  orgFromAPI.searchGttSetup = orgFromAPI.gtt_setup;
  orgFromAPI.sortGttEnabled = orgFromAPI.searchGttEnabled ? 1 : 0;
  orgFromAPI.sortGttEnabled = orgFromAPI.sortGttEnabled && orgFromAPI.searchGttSetup ? 2 : orgFromAPI.sortGttEnabled;

  return orgFromAPI;
};

//for organization list pagination
export const orgList = async (pageSize, lastEvaluatedKey, awsAccount, stack) => {
  let queryStringParameters;
  if (lastEvaluatedKey && awsAccount) {
    queryStringParameters = {
      pageSize: `${pageSize}`,
      organization_id: lastEvaluatedKey.organization_id,
      env: awsAccount,
      tableStackName: stack,
    };
  } else if (!lastEvaluatedKey && awsAccount) {
    queryStringParameters = { pageSize: `${pageSize}`, env: awsAccount, tableStackName: stack };
  } else if (lastEvaluatedKey && !awsAccount) {
    queryStringParameters = { pageSize: `${pageSize}`, organization_id: lastEvaluatedKey.organization_id };
  } else if (!lastEvaluatedKey && !awsAccount) {
    queryStringParameters = { pageSize: `${pageSize}` };
  }
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: orgs,
    method: 'get',
    queryStringParameters: queryStringParameters,
  });
};
