import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { addNotification, setPageSize } from '../../../core/redux/ui/actions';

import PageListWrapper from 'shared/styles/components/PageList';
import {
  setFetchingDevices,
  setStackSelection,
  setStackAccount,
  resetEnvStackOptions,
  setOrg,
  setSearchTextGlobal,
} from '../../../core/redux/deviceOperations/actions';

import DeviceList from './deviceList';
import { orgList as organizationsList } from 'core/api/organizations';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { useSelector, useDispatch } from 'react-redux';

const device = ({
  setInitialLoad,
  setFetchingDevice,
  isFetchingDevices,
  setStackSelection,
  selectedStack,
  setStackAccount,
  showNotification,
  resetEnvStackOption,
}) => {
  const [initialPageLoad, setInitialPageLoad] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(false);
  const [lastEvaluatedKeyOrg, setlastEvaluatedKeyOrg] = useState(false);
  const { selectedAccount } = useSelector((state) => state.deviceOperations);
  const dispatch = useDispatch();

  // function for updating initial loader
  const resetInitialLoad = (state) => {
    setInitialPageLoad(state);
  };

  // refresh device list page when organization option change if API call is already in progress for previous selection.
  const reloadComponent = (org) => {
    setOrganization(org);
  };

  // fetch organization list
  const fetchOrgs = async (
    pageSize = PAGINATION_DEFAULT_OPTION.value,
    lastEvaluatedKey1,
    awsAccount = '',
    stack = '',
    type
  ) => {
    try {
      setIsFetchingOrgs(true);
      const { response } = await organizationsList(pageSize, lastEvaluatedKey1, awsAccount, stack);
      if (response.message) {
        let temp = [];
        response.message.map((orgs) =>
          temp.push({
            label: orgs.organization_name,
            value: orgs.organization_id,
          })
        );
        setOrgs((previous) => previous.concat(temp));
        response?.lastEvaluatedKey ? setlastEvaluatedKeyOrg(response?.lastEvaluatedKey || false) : null;
        response.lastEvaluatedKey ? setIsFetchingOrgs(true) : setIsFetchingOrgs(false);
        resetInitialLoad(true);
      }
    } catch (e) {
      console.log(e);
      setIsFetchingOrgs(false);
      showNotification(e, 'org');
    }
  };

  // on changing stack refresh org list with new API call
  const refreshOrgList = (account, stack) => {
    setOrgs([]);
    dispatch(setOrg(null));
    fetchOrgs(undefined, undefined, account.value.toLowerCase(), stack.value);
  };

  // alphanumeric sort for organization list in dropdown
  useEffect(() => {
    function alphanumericSort(a, b) {
      return a.label.localeCompare(b.label, undefined, { numeric: true });
    }
    const records = orgs.sort(alphanumericSort);
    setOrgs(records);
  }, [orgs]);

  // check for lastEvaluatedKeyOrg state and fetch next set of records.
  useEffect(() => {
    if (lastEvaluatedKeyOrg) {
      fetchOrgs(undefined, lastEvaluatedKeyOrg, selectedAccount.value.toLowerCase(), selectedStack.value);
    }
    return () => false;
  }, [lastEvaluatedKeyOrg]);

  useEffect(() => {
    dispatch(setSearchTextGlobal(''));
    fetchOrgs(undefined, undefined, selectedAccount.value.toLowerCase(), selectedStack.value);
  }, []);

  // on page change reset states and dropdown selections.
  useEffect(() => {
    return () => {
      resetEnvStackOption();
      dispatch(setOrg(null));
      // reset pagination when navigating to other pages or browser refresh
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
    };
  }, []);

  return (
    <PageListWrapper>
      <DeviceList
        key={selectedAccount.value + selectedStack.value + organization}
        setFetchingDevices={setFetchingDevice}
        isFetchingDevices={isFetchingDevices}
        setInitialLoads={(state) => resetInitialLoad(state)}
        reloadComponent={(org) => reloadComponent(org)}
        selectedStack={selectedStack}
        selectedAccount={selectedAccount}
        stackSelection={setStackSelection}
        setStackAccount={setStackAccount}
        showNotification={showNotification}
        initialPageLoad={initialPageLoad}
        orgs={orgs}
        isFetchingOrgs={isFetchingOrgs}
        refreshOrgList={(account, stack) => refreshOrgList(account, stack)}
      />
    </PageListWrapper>
  );
};

export default connect(
  (state) => ({
    isFetchingDevices: state.deviceOperations.isFetchingDevices,
    selectedStack: state.deviceOperations.stackSelection,
    selectedAccount: state.deviceOperations.selectedAccount,
  }),
  (dispatch) => ({
    setFetchingDevice: (status) => dispatch(setFetchingDevices(status)),
    setStackSelection: (stack) => dispatch(setStackSelection(stack)),
    setStackAccount: (account) => dispatch(setStackAccount(account)),
    resetEnvStackOption: () => dispatch(resetEnvStackOptions()),
    showNotification: (e, type) =>
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            type !== 'org' ? ERROR_MESSAGES.FETCH_DEVICES : ERROR_MESSAGES.FETCH_ORGS,
            e
          ),
        })
      ),
  })
)(device);
