/* eslint-disable react/display-name */
import React from 'react';

// assets
import loadingGIF from 'assets/images/loading.gif';
import logoPNG from 'assets/images/wlogo-small@2x.png';

export default ({ withLogo, externalStyles }) => {
  return (
    <div
      style={{
        ...styles.overlay,
        ...(externalStyles && { ...externalStyles.overlay }),
      }}
    >
      <div style={styles.container}>
        <img src={loadingGIF} style={styles.animation} />
        {withLogo ? <img src={logoPNG} style={styles.logo} /> : null}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: '0px',
    backgroundColor: '#000',
    opacity: 0.2,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  animation: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    width: '40%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
