/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import theme from 'shared/styles/global/theme';
import { media } from 'shared/utilities/media';

export default styled.div`
  display: grid;
  grid-template-columns: ${theme.sidebar_width_collapsed} minmax(0, 1fr);
  grid-template-rows: ${theme.header_height} auto;

  ${media.mobile`
  grid-template-columns: 0 minmax(0, 1fr);
    `}

  ${(props) =>
    props.fullscreenMode &&
    `
		display: block;
	`};
`;
