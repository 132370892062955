/**
 * @ External Dependencies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependencies
 */

import { colors, spacing, hexWithOpacity, borderRadius, fontWeight } from '../global/variables';

export const DropboxToggle = styled.a`
  padding: 0 ${spacing.sm};
`;

export const DropdownMenu = styled.div`
  box-shadow: 0 0.5rem 1rem ${hexWithOpacity(colors.black, 0.07)};
  z-index: 1030;
  border: solid 1px ${colors.metal};
  background: ${colors.cotton};
  position: absolute;
  display: none;
  right: 22px !important;
  left: auto;
  top: 100%;
  min-width: 11rem;
  border-radius: ${borderRadius.sm};
  padding: 0;
  inset: unset !important;
  transform: unset !important;
  transform: translateX(-80%) !important;
  &.show {
    display: block;
  }
  &:before {
    right: 0.75rem;
    content: '';
    position: absolute;
    top: -0.25rem;
    left: 0.75rem;
    display: block;
    background: inherit;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: center;
    border: 1px solid;
    border-color: inherit;
    z-index: -1;
    clip: rect(0, 9px, 9px, 0);
  }
  a {
    min-width: 11rem;
    margin: 0;
    line-height: 1.4285714286;
    clear: both;
    font-weight: ${fontWeight.fontWeightNormal};
    color: ${colors.midnight};
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    padding: 0.55rem 1rem;
    font-size: 14px;
    display: block;
    text-decoration: none;
    &:hover {
      color: ${colors.charcoal};
      background: ${colors.fog};
    }
  }
  ${(props) =>
    props.rightAlign &&
    `
    &:before {
      left:auto;
    }
     
   `};
`;
