import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const StyleRadio = styled.div`
  .switch-field {
    padding: 0px 6px 23px 0px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 16px;
    overflow: hidden;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    border-radius: 25px 0px 0px 25px;
    border: 2px solid #1c1c1c;
    background-color: ${colors.cotton};
    color: #1c1c1c;
    line-height: 1;
    text-align: center;
    padding: 10px 31px;
    margin-right: -1px;
    z-index: 999;
    font-size: 12px;
    font-weight: 700;
  }
  .switch-field .second-radio {
    border-radius: 25px 25px 25px 25px !important;
  }

  .switch-field input:checked + label {
    background-color: ${colors.metal};
    box-shadow: none;
  }
`;
