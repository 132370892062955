export const SUCCESS_MESSAGES = {
  CHANGES_DEVICE_ENV: 'Device Migration Initiated Successfully',
  CHANGE_DEVICE_ENV_SUB:
    'It may take some time to reflect the correct stack status. You can check the updated status by refreshing the page.',
  FETCHING_RECORD: 'Fetching records ...',
  USER_RESET_SUCCESS: 'User reset successfully and mail sent on registered email id.',
  VEHICLE_RESET_SUCCESS: 'Vehicle reset is successfully done',
};
export const ERROR_MESSAGES = {
  FETCH_DEVICES: 'Error Getting Devices',
  CHANGES_DEVICE_ENV: 'Error Getting to Change Device Stack',
  FETCH_DEVICE_SHADOW: 'Error Getting Device Shadow',
  FETCH_DEVICE_SIM_INFO: 'Error Getting Device Sim Information',
  FETCH_DEVICE_JOBS: 'Error Getting Device Jobs',
  FETCH_DYNAMO_SYSTEM_ERRORS: 'Error Getting Dynamo System Errors',
  FETCH_DYNAMO_USER_ERRORS: 'Error Getting Dynamo User Errors',
  FETCH_READ_CAP_UNITS: 'Error Getting Consumed Read Capacity Units',
  FETCH_WRITE_CAP_UNITS: 'Error Getting Consumed Write Capacity Units',
  FETCH_THROTTLED_REQ: 'Error Getting Throttled Requests',
  FETCH_ENV_COUNT: 'Error Getting Counts From Environments',
  FETCH_GATEWAY_5XX_ERRORS: 'Error Getting Gateway 5XXError Data',
  FETCH_GATEWAY_COUNT: 'Error Getting Gateway Count Metric Data',
  FETCH_GATEWAY_INT_LAT: 'Error Getting Integration Latency Data',
  FETCH_GATEWAY_LAT: 'Error Getting Latency Data',
  FETCH_FAILED_JOB_COUNT: 'Error Getting Failed Job Execution Total Count',
  FETCH_RULES_EX: 'Error Getting Rules Executed',
  FETCH_JOB_DETAILS: 'Error Getting Job Details',
  FETCH_JOBS: 'Error Getting Jobs',
  FETCH_ADDON: 'Error Getting Vehicle Addons',
  FETCH_SERIAL_DEVICE: 'Error Getting Serialized device Data',
  FETCH_ORGS: 'Error Getting Organization Data',
  FETCH_LAMBDA_INV: 'Error Getting Lambda Invocations',
  FETCH_LAMBDA_DURATION: 'Error Getting Lambda Duration',
  FETCH_LAMBDA_THROTTLES: 'Error Getting Lambda Throttles',
  FETCH_LAMBDA_ERRORS: 'Error Getting Lambda Errors',
  LOGIN_DETAILS: 'Error Logging In',
  LOGGED_IN_STATUS: 'Error Getting Logged In Status',
  REFRESHED_TOKEN: 'Error Getting Refreshed Access Token',
  FETCH_ENV_LIST: 'Error Getting Environment List',
  FETCH_ACTIVITY_LOGS: 'Error Getting User Activity Logs',
  TRY_LATER: 'Something Went Wrong. Please Try Later.',
  SERVER_ERROR: 'Error Getting Response From Server, Please Check Your Network Connection.',
  SESSION_EXPIRED: 'Session Is Expired. Please Sign In Again.',
  TOKEN_EXPIRED: 'The incoming token has expired',
  EMPTY_TEXT: 'No records found',
  FETCH_USERS: 'Error Getting Users',
  USER_RESET_ERROR: 'User does not exist',
  VEHICLE_RESET_ERROR: 'Vehicle does not exist',
  FETCH_TABLE_NAME: 'Invalid environment',
  FETCH_ACCOUNT_GPS: 'Error getting account and gps detail',
  SENDING_EMAIL_ERROR: 'Error sending email',
  INVALID_VSG_ID: 'Minimum 24 characters required',
};

export const PAGE_INFO = {
  DEVICE_DATA_FETCHING: 'Fetching records...',
  DEVICE_INITIAL_LOAD: 'No organization selected',
};

export const NO_PAGE_DATA = {
  DEVICE_NO_STATE_INFORMATON: 'No state information available',
  DEVICE_NO_META_INFORMATON: 'No meta information available',
};
