import React, { useState } from 'react';
import useAPIData from 'shared/hooks/useAPIData';
import {
  fetchDynamoSystemErrors,
  fetchDynamoUserErrors,
  fetchReadCapacityUnits,
  fetchWriteCapacityUnits,
  fetchThrottledRequests,
} from 'core/redux/dynamoMatrix/actions';
import { fetchEnvCounts } from 'core/redux/envCounts/actions';
import Matrix from 'features/dashboard/components/matrix';
import Charts from 'features/dashboard/components/charts';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import MatrixOptions from 'shared/ui/matrix/matrixOptions';
import { EnvCount } from 'shared/styles/components/envCountWrapper';

const dynamoMatrix = ({
  systemErrors,
  userErrors,
  readCapacityUnits,
  writeCapacityUnits,
  throttledRequests,
  envCountDetails,
  fetchDynamoSystemErrors,
  fetchThrottledRequests,
  fetchDynamoUserErrors,
  fetchReadCapacityUnits,
  fetchWriteCapacityUnits,
  fetchEnvCounts,
  isError,
  selectedStack,
}) => {
  let [options, setOptions] = useState(null);

  const isSystemErrors = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchDynamoSystemErrors(options);
      }
    },
    dependencies: [options],
  });

  const isUserErrors = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchDynamoUserErrors(options);
      }
    },
    dependencies: [options],
  });
  const isReadRequest = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchReadCapacityUnits(options);
      }
    },
    dependencies: [options],
  });
  const isWriteRequest = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchWriteCapacityUnits(options);
      }
    },
    dependencies: [options],
  });
  const isThrottledRequests = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchThrottledRequests(options);
      }
    },
    dependencies: [options],
  });
  const isEnvCountLoaded = useAPIData({
    fetchAPIData: async () => {
      if (options) {
        return await fetchEnvCounts(options.stackName, options.env);
      }
    },
    dependencies: [options],
  });

  const optionChangeHandler = (e) => {
    setOptions(e);
  };

  const tableEnvCount = () => {
    if (envCountDetails?.message?.length) {
      return envCountDetails?.message?.map((obj) => {
        return <div key={obj.id}>{`${obj.tableName} Count: ${obj.totalCount}`}</div>;
      });
    } else {
      return null;
    }
  };

  return (
    <>
      <MatrixOptions
        optionChangeHandler={optionChangeHandler}
        matrixType="dynamoMatrix"
        selectedStack={selectedStack}
      />
      {((options?.env === 'Prod' || isEnvCountLoaded) &&
        isSystemErrors &&
        isUserErrors &&
        isReadRequest &&
        isWriteRequest &&
        isThrottledRequests) ||
      isError ? (
        <>
          {tableEnvCount() ? <EnvCount>{tableEnvCount()}</EnvCount> : null}
          {options?.format !== 'json' ? (
            <div>
              {systemErrors?.message ? <Matrix base64Data={systemErrors.message} /> : null}
              {userErrors?.message ? <Matrix base64Data={userErrors.message} /> : null}
              {readCapacityUnits?.message ? <Matrix base64Data={readCapacityUnits.message} /> : null}
              {writeCapacityUnits?.message ? <Matrix base64Data={writeCapacityUnits.message} /> : null}
              {throttledRequests?.message ? <Matrix base64Data={throttledRequests.message} /> : null}
            </div>
          ) : (
            <>
              {systemErrors?.message ? (
                <Charts options={options} jsonFormData={{ ...systemErrors.message, chartType: 'SystemErrors' }} />
              ) : null}
              {userErrors?.message ? (
                <Charts options={options} jsonFormData={{ ...userErrors.message, chartType: 'UserErrors' }} />
              ) : null}
              {readCapacityUnits?.message ? (
                <Charts
                  options={options}
                  jsonFormData={{ ...readCapacityUnits.message, chartType: 'ConsumedReadCapacityUnits' }}
                />
              ) : null}
              {writeCapacityUnits?.message ? (
                <Charts
                  options={options}
                  jsonFormData={{ ...writeCapacityUnits.message, chartType: 'ConsumedWriteCapacityUnits' }}
                />
              ) : null}
              {throttledRequests?.message ? (
                <Charts
                  options={options}
                  jsonFormData={{ ...throttledRequests.message, chartType: 'ThrottledRequests' }}
                />
              ) : null}{' '}
            </>
          )}
        </>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
export default connect(
  (state) => ({
    systemErrors: state.dynamoMatrix.systemErrors,
    userErrors: state.dynamoMatrix.userErrors,
    readCapacityUnits: state.dynamoMatrix.readCapacityUnits,
    writeCapacityUnits: state.dynamoMatrix.writeCapacityUnits,
    throttledRequests: state.dynamoMatrix.throttledRequests,
    isError: state.dynamoMatrix?.isError,
    selectedStack: state.dynamoMatrix.stackSelection,
    envCountDetails: state.envCounts.envCountDetails,
  }),
  (dispatch) => ({
    fetchDynamoSystemErrors: (params) => {
      return dispatch(fetchDynamoSystemErrors(params));
    },
    fetchDynamoUserErrors: (params) => {
      return dispatch(fetchDynamoUserErrors(params));
    },
    fetchReadCapacityUnits: (params) => {
      return dispatch(fetchReadCapacityUnits(params));
    },
    fetchWriteCapacityUnits: (params) => {
      return dispatch(fetchWriteCapacityUnits(params));
    },
    fetchThrottledRequests: (params) => {
      return dispatch(fetchThrottledRequests(params));
    },
    fetchEnvCounts: (stackName, env) => {
      return dispatch(fetchEnvCounts(stackName, env));
    },
  })
)(dynamoMatrix);
