import { handleActions } from 'redux-actions';
import { ENV_DEFAULT_OPTION, STACK_DEFAULT_OPTION } from 'shared/constants/matrixOptions';
import { setFetchingOrgs, setInitialOrgLoads, setStackSelection, setStackAccount, resetOptions } from './action';

const defaultState = {
  orgs: [],
  isFetchingOrgs: false,
  isError: false,
  initialPageLoad: false,
  stackSelection: STACK_DEFAULT_OPTION.dev,
  selectedAccount: ENV_DEFAULT_OPTION,
};

const org = handleActions(
  {
    [setFetchingOrgs]: (state, { payload }) => ({
      ...state,
      isFetchingOrgs: payload,
      isError: false,
    }),
    [setInitialOrgLoads]: (state, { payload }) => ({
      ...state,
      initialPageLoad: payload,
      isError: false,
    }),
    [setStackSelection]: (state, { payload }) => ({
      ...state,
      stackSelection: payload,
      isError: false,
    }),
    [setStackAccount]: (state, { payload }) => ({
      ...state,
      selectedAccount: payload,
      isError: false,
    }),
    [resetOptions]: (state) => ({
      ...state,
      selectedAccount: defaultState.selectedAccount,
      stackSelection: defaultState.stackSelection,
      initialPageLoad: false,
    }),
  },
  defaultState
);
export default org;
