import { compareValues } from 'shared/utilities/general';
import { SORT_ORDER } from 'shared/constants/table';

const checkAllvalues = (objA, objB, sortIndex) => {
  // do this is property to sort by is nested
  // currently supports only 1 level of nesting, will need to change if we add more levels
  if (sortIndex) {
    let compoundSortIndex = sortIndex.split('.');
    if (compoundSortIndex.length === 2) {
      sortIndex = compoundSortIndex.pop();
      objA = objA[compoundSortIndex];
      objB = objB[compoundSortIndex];
    }
  }

  const propOne = objA[sortIndex];
  const propTwo = objB[sortIndex];
  return propOne === propTwo;
};

export const tableColumnSort = ({ data, order, sortIndex }) => {
  const allEqual = data.every((item) => {
    return checkAllvalues(data[0], item, sortIndex);
  });
  let sortedData;
  if (allEqual) {
    sortedData = data.reverse();
  } else {
    sortedData = data.sort((a, b) => compareValues(a, b, sortIndex, order));
    if (order === SORT_ORDER.DESC) {
      sortedData.reverse();
    }
  }
  return sortedData;
};
