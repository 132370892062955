/**
 * @ External Dependecies
 */
import styled from 'styled-components';

export const StyleChart = styled.div`
  margin-bottom: 80px;
  background-color: white;
  padding: 0 15px 15px;
`;
