import { handleActions } from 'redux-actions';
import {
  receive5XXError,
  receiveCount,
  receiveIntegrationLatency,
  receiveLatency,
  request5XXError,
  requestCount,
  requestIntegrationLatency,
  requestLatency,
  handleError,
} from './actions';

const defaultState = {
  getway5xxError: {},
  gatewayCount: {},
  integrationLatency: {},
  latency: {},
  isError: false,
};

const gatewayMatrix = handleActions(
  {
    [receive5XXError]: (state, { payload: getway5xxError }) => ({
      ...state,
      getway5xxError: getway5xxError,
    }),
    [receiveCount]: (state, { payload: gatewayCount }) => ({
      ...state,
      gatewayCount: gatewayCount,
    }),
    [receiveIntegrationLatency]: (state, { payload: integrationLatency }) => ({
      ...state,
      integrationLatency: integrationLatency,
    }),
    [receiveLatency]: (state, { payload: latency }) => ({
      ...state,
      latency: latency,
    }),
    [handleError]: (state) => ({
      ...state,
      isError: true,
    }),
    [request5XXError]: (state) => ({
      ...state,
      getway5xxError: {},
      isError: false,
    }),
    [requestCount]: (state) => ({
      ...state,
      gatewayCount: {},
      isError: false,
    }),
    [requestIntegrationLatency]: (state) => ({
      ...state,
      integrationLatency: {},
      isError: false,
    }),
    [requestLatency]: (state) => ({
      ...state,
      latency: {},
      isError: false,
    }),
  },
  defaultState
);

export default gatewayMatrix;
