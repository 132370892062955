import { SORT_ORDER } from 'shared/constants/table';

export const getGuardedObjectProp = (path, object) => {
  if (object) {
    const pathSegments = path.split('.');
    const lastSegment = pathSegments.shift();
    const nextSegmentValue =
      pathSegments.length > 0
        ? object.hasOwnProperty(lastSegment) && getGuardedObjectProp(pathSegments.join('.'), object[lastSegment])
        : object[lastSegment];
    if (nextSegmentValue) {
      return nextSegmentValue;
    }
  }
};

export const compareValues = (objA, objB, sortIndex, order) => {
  // do this is property to sort by is nested
  // currently supports only 1 level of nesting, will need to change if we add more levels
  if (sortIndex) {
    let compoundSortIndex = sortIndex.split('.');
    if (compoundSortIndex.length === 2) {
      sortIndex = compoundSortIndex.pop();
      objA = objA[compoundSortIndex];
      objB = objB[compoundSortIndex];
    }
  }
  let propOne = objA[sortIndex];
  let propTwo = objB[sortIndex];
  if (typeof propOne === 'string') {
    propOne = propOne.toLowerCase();
  }
  if (typeof propTwo === 'string') {
    propTwo = propTwo.toLowerCase();
  }
  if (propOne < propTwo || (propOne === propTwo && order === SORT_ORDER.ASC) || !propOne) {
    return -1;
  }
  if (propOne > propTwo || (propOne === propTwo && order === SORT_ORDER.DESC) || !propTwo) {
    return 1;
  }
  if (order === SORT_ORDER.ASC) {
    return -1;
  }
  return 1;
};

// To change the date time to Local or UTC format
export const toGetDate_TimeFormatToLocalOrUTC = ({
  date,
  isUTC = false,
  fullDate = true,
  isSecondRequired = false,
}) => {
  let value = new Date(date);
  if (!isUTC) {
    let timeZone = value.toString().split('GMT')[1].split('(')[0].trim();
    return (
      ('0' + (value.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + value.getDate()).slice(-2) +
      '/' +
      value.getFullYear() +
      (fullDate
        ? ' ' +
          ('0' + value.getHours()).slice(-2) +
          ':' +
          ('0' + value.getMinutes()).slice(-2) +
          (isSecondRequired ? ':' + ('0' + value.getSeconds()).slice(-2) : '-') +
          ' (GMT' +
          timeZone +
          ')'
        : '')
    );
  } else {
    return (
      ('0' + (value.getUTCMonth() + 1)).slice(-2) +
      '/' +
      ('0' + value.getUTCDate()).slice(-2) +
      '/' +
      value.getUTCFullYear() +
      (fullDate
        ? ' ' +
          ('0' + value.getUTCHours()).slice(-2) +
          ':' +
          ('0' + value.getUTCMinutes()).slice(-2) +
          (isSecondRequired ? ':' + ('0' + value.getSeconds()).slice(-2) : '') +
          ' (UTC)'
        : '')
    );
  }
};

// use to formate VSG ID in device list screen
export const getFormattedDuid = (duid) => {
  let newDeviceId = duid;
  if (duid && (duid.length == 32 || duid.length == 33)) {
    newDeviceId = duid;
  } else {
    newDeviceId = '00000009' + newDeviceId;
  }
  let regEx = /([\w]{8})([\w]{4})([\w]{4})([\w]{4})([\w]{12})/;
  let stringTemplate = '$1-$2-$3-$4-$5';
  let formattedDuid = newDeviceId?.replace(regEx, stringTemplate);
  return formattedDuid;
};

// convert formatted guid to plain text to send with API call
export const getUnformattedDuid = (duid) => {
  let unformattedduid = duid ? duid.replace(/-/g, '') : '';
  if (unformattedduid.length == 32) {
    if (unformattedduid.substr(0, 8) == '00000009') {
      unformattedduid = unformattedduid.replace('00000009', '');
    } else if (unformattedduid.substr(0, 9) == '000000009') {
      unformattedduid = unformattedduid.replace('000000009', '');
    }
  }
  return unformattedduid;
};
