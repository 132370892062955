import styled from 'styled-components';
import colors from 'shared/constants/colors';
import theme from 'shared/styles/global/theme';
import { media } from 'shared/utilities/media';

const Sidebar = styled.aside`
  touch-action: none;
  position: -webkit-sticky;
  position: sticky;
  top: ${theme.header_height};
  left: 0;
  width: ${theme.sidebar_width_collapsed};

  z-index: 10010;
  box-shadow: 8px 1px 43px rgba(0, 0, 0, 0.23);
  background: ${colors.midnight};
  transition: width 0.3s;
  grid-row: 2;
  grid-column: 1/1;

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    height: calc(100vh - ${theme.header_height});
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */

    /* mobile viewport bug fix */
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  ${(props) =>
    props.fullscreenMode &&
    `
		display: none;
	`};

  ${(props) =>
    props.expanded &&
    `
    width: ${theme.sidebar_width_expanded};

    `}

  ${media.mobile`
      transition: left 0.3s;
    `}

    ${(props) =>
    !props.expanded &&
    media.mobile`
        width: ${theme.sidebar_width_collapsed};
        left: -${theme.sidebar_width_expanded};
        position: fixed;
    `}

    ${(props) =>
    props.expanded &&
    media.mobile`
        left: 0px;
    `}
`;

export default Sidebar;
