import React, { useState, useEffect } from 'react';
import SortableTable from 'shared/ui/table/SortablePaginatedTable';
import { connect, useDispatch } from 'react-redux';
import useAPIData from 'shared/hooks/useAPIData';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { fetchUserActivityLogs } from 'core/redux/userActivityLogs/actions';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { HeaderButtonDiv, LoadingText } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { SORT_ORDER } from 'shared/constants/table';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { MultiLineText } from 'shared/styles/components/TruncatedText';
import { setPageSize } from 'core/redux/ui/actions';
import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';

const UserActivityLogs = ({ userActivityLogs, isFetchingLogs, fetchUserActivityLogs, isError }) => {
  const [resetPageNo, setResetPageNo] = useState(false);
  const dispatch = useDispatch();
  const logsLoaded = useAPIData({
    fetchAPIData: fetchUserActivityLogs,
    dependencies: [],
  });
  const refreshLogs = () => {
    setResetPageNo(!resetPageNo);
    fetchUserActivityLogs();
  };

  // reset page size on route change
  useEffect(() => {
    return () => {
      dispatch(setPageSize(PAGINATION_DEFAULT_OPTION));
    };
  }, []);

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      searchIndex: 'searchableTimeStamp',
      key: 'timestamp',
      searchable: true,
      width: '15%',
      render(value, activityLog) {
        return <span title={'Timestamp: ' + activityLog.formattedTimeStamp}>{activityLog.searchableTimeStamp}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      searchable: true,
      searchIndex: 'searchableAction',
      width: '10%',
      render(value, activityLog) {
        return <span title={'Action: ' + value}>{activityLog.searchableAction}</span>;
      },
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      searchable: true,
      searchIndex: 'searchableUserName',
      width: '10%',
      render(value, activityLog) {
        return <span title={'User Name: ' + value}>{activityLog.searchableUserName}</span>;
      },
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      searchIndex: 'searchableDetails',
      searchable: true,
      width: '65%',
      render(value, activityLog) {
        return (
          <span title={'Details: ' + value}>
            <MultiLineText>{activityLog.searchableDetails}</MultiLineText>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>User Activity Logs</PageListTitle>
          <PageListActionButtonContainer>
            <LoadingText>{isFetchingLogs ? 'Fetching records ...' : null}</LoadingText>
            <HeaderButtonDiv cancel>
              <ButtonWithLoader
                disabled={isFetchingLogs}
                isLoading={isFetchingLogs}
                confirmText={'Refresh'}
                loadingStyleProp={'submittingWithSpinner'}
                notLoadingStyleProp={'refresh'}
                clickHandler={() => refreshLogs()}
              />
            </HeaderButtonDiv>
          </PageListActionButtonContainer>
        </PageListHead>
        <SortableTable
          className="table-simple"
          columns={columns}
          tableData={userActivityLogs}
          scroll={{ x: false, y: false }}
          rowKey={(record) => record.group_id}
          defaultSortOrder={SORT_ORDER.DESC}
          emptyText={ERROR_MESSAGES.EMPTY_TEXT}
        />
        {!logsLoaded ? <LoadingOverlay /> : null}
      </PageListWrapper>
    </>
  );
};

export default connect(
  (state) => ({
    userActivityLogs: state.userActivityLogs.userActivityLogs,
    isError: state.userActivityLogs?.isError,
    isFetchingLogs: state.userActivityLogs.isFetchingLogs,
  }),
  (dispatch) => ({
    fetchUserActivityLogs: () => dispatch(fetchUserActivityLogs()),
  })
)(UserActivityLogs);
