import React from 'react';
import { connect } from 'react-redux';
import JobList from 'features/jobs/pages/jobList';
import { addNotification } from 'core/redux/ui/actions';
import { LEVELS, createNotification } from 'shared/utilities/notification';
import { ERROR_MESSAGES } from 'shared/constants/messages';
import { useState } from 'react';

const deviceJobs = ({ selectedDevice, awsAccount, showNotification }) => {
  const [initialPageLoad, setInitialPageLoad] = useState(false);
  return (
    <div style={{ marginTop: '60px' }}>
      <JobList
        key={awsAccount}
        initialPageLoad={initialPageLoad}
        setInitialLoads={(state) => setInitialPageLoad(state)}
        deviceId={selectedDevice}
        showNotification={showNotification}
        selectedAccount={awsAccount}
        hideEnvDropdown={true}
      />
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showNotification: (e) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_DEVICE_JOBS, e),
        })
      ),
  })
)(deviceJobs);
