import React from 'react';
import JSONTree from 'react-json-view';
import useAPIData from 'shared/hooks/useAPIData';
import { fetchDeviceSimInfo } from 'core/redux/deviceOperations/actions';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { connect } from 'react-redux';
import { JsonTree } from 'shared/styles/components/JsonTree';

const deviceSimInfo = ({ selectedDevice, simInfo, fetchDeviceSimInfo, isError }) => {
  const deviceSimInfoLoaded = useAPIData({
    fetchAPIData: () => fetchDeviceSimInfo(selectedDevice),
    dependencies: [selectedDevice],
  });

  return deviceSimInfoLoaded || isError ? (
    <>
      <JsonTree>
        <div className="tree-grid">
          <JSONTree name={false} displayDataTypes={false} displayObjectSize={false} collapsed={2} src={simInfo} />
        </div>
      </JsonTree>
    </>
  ) : (
    <LoadingOverlay />
  );
};
export default connect(
  (state) => ({
    simInfo: state.deviceOperations.simInfo,
    isError: state.deviceOperations?.isError,
  }),
  (dispatch) => ({
    fetchDeviceSimInfo: (deviceId) => {
      return dispatch(fetchDeviceSimInfo(deviceId));
    },
  })
)(deviceSimInfo);
