import React from 'react';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import TabView from 'shared/ui/tabs/TabView';
import { Tab, ShadowNav } from 'shared/styles/components/Tabs';
import DynamoMatrix from '../components/dynamoMatrix';
import IotMatrix from '../components/iotMatrix';
import GatewayMatrix from '../components/gatewayMatrix';
import LambdaMatrix from '../components/lambdaMatrix';
import { StyleRadio } from 'shared/styles/components/radio';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const dashboard = () => {
  const history = useHistory();
  const location = useLocation();
  let currentPath = location.pathname;

  const switchTab = (e) => {
    if (e === 'dashboard') {
      history.push('/dashboard');
    } else {
      history.push('/dashboard_beta');
    }
  };

  return (
    <PageListWrapper>
      <React.Fragment>
        <PageListHead>
          <PageListTitle>Dashboard</PageListTitle>
        </PageListHead>
        {/* <StyleRadio>
          <div className="switch-field">
            <input
              type="radio"
              id="dashboard"
              name="dashboard"
              onChange={(e) => switchTab(e.target.value)}
              value="dashboard"
              checked={currentPath === '/dashboard'}
            />
            <label className="second-radio" htmlFor="dashboard">
              Dashboard
            </label>
            <input
              type="radio"
              id="dashboard_beta"
              name="dashboard_beta"
              onChange={(e) => switchTab(e.target.value)}
              value="dashboard_beta"
              checked={currentPath === '/dashboard_beta'}
            />
            <label htmlFor="dashboard_beta">Dashboard(Beta)</label>
          </div>
        </StyleRadio> */}
        <TabView
          tabContainer={ShadowNav}
          tabs={[
            {
              tabElement: <Tab> API Gateway</Tab>,
              content: <GatewayMatrix />,
            },
            {
              tabElement: <Tab> DynamoDB</Tab>,
              content: <DynamoMatrix />,
            },
            {
              tabElement: <Tab>IoT Core</Tab>,
              content: <IotMatrix />,
            },
            {
              tabElement: <Tab>Lambda</Tab>,
              content: <LambdaMatrix />,
            },
          ]}
        />
      </React.Fragment>
    </PageListWrapper>
  );
};

export default dashboard;
