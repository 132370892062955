import { createAction } from 'redux-actions';
import jwt from 'jwt-decode';

import { getLoginDetails } from 'core/api/login';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { updateAuthStore, loginIsFresh, getIdToken, setStackTable, getStackTable } from 'shared/utilities/localStore';
import { getEnvList } from 'core/api/envOptions';
import { getStackNamesList } from 'core/api/stackNames';
import { SORT_ORDER } from 'shared/constants/table';
import { tableColumnSort } from 'shared/utilities/table';

export const receiveEnvList = createAction('RECEIVE_ENV_LIST');
export const requestEnvList = createAction('REQUEST_ENV_LIST');
export const handleError = createAction('HANDLE_ERROR');
import { getRefreshedToken } from 'core/api/refreshedToken';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'shared/constants/messages';
export const requestLoginDetails = createAction('REQUEST_LOGIN_DETAILS');
export const receiveLoginDetails = createAction('RECEIVE_LOGIN_DETAILS');
export const rejectLoginDetails = createAction('REJECT_LOGIN_DETAILS');
export const resetLoginDetails = createAction('RESET_LOGIN_DETAILS');

export const requestLoggedInStatus = createAction('REQUEST_LOGGED_IN_STATUS');
export const receiveLoggedInStatus = createAction('RECEIVE_LOGGED_IN_STATUS');
export const rejectLoggedInStatus = createAction('REJECT_LOGGED_IN_STATUS');
export const requestRefreshedToken = createAction('REQUEST_REFRESHED_TOKEN');
export const receiveRefreshedToken = createAction('RECEIVE_REFRESHED_TOKEN');
export const rejectRefreshedToken = createAction('REJECT_REFRESHED_TOKEN');

export const requestLoggedOut = createAction('REQUEST_LOGGED_OUT');

import { resetUser } from 'core/api/users';

import { PAGINATION_DEFAULT_OPTION } from 'shared/constants/pagination';
import { DEV, PROD } from 'shared/constants/matrixOptions';
export const requestUsers = createAction('REQUEST_USERS');
export const receiveUsers = createAction('RECEIVE_USERS');
export const rejectUsers = createAction('REJECT_USERS');
export const setFetchingUsers = createAction('SET_FETCHING_USERS');
export const resetUsers = createAction('RESET_USERS');
export const resetUserLoader = createAction('RESET_USERS_LOADER');
export const resetOptions = createAction('RESET_OPTIONS');

export const setInitialUserLoads = createAction('INITIAL_USER_LOAD');
export const setStackSelection = createAction('USER_STACK_SELECTION');
export const setStackAccount = createAction('USER_STACK_ACCOUNT');
export const setOrg = createAction('USER_ORGANIZATION');
export const setEnableUserLoad = createAction('SET_ENABLE_USER_LOAD');
export const setUserNameSearchText = createAction('USER_NAME_SEARCH_TEXT');
export const setExternalSearch = createAction('USER_SET_EXTERNAL_SEARCH');

// Verify the redirect code and fetch the loged in user details.
export const fetchLoginDetails = (code) => {
  return async (dispatch) => {
    dispatch(requestLoginDetails());
    try {
      const { response } = await getLoginDetails(code);
      if (response.message) {
        updateAuthStore({
          AccessToken: response.message.access_token,
          ExpiresIn: response.message.expires_in,
          TokenType: response.message.token_type,
          RefreshToken: response.message.refresh_token,
          IdToken: response.message.id_token,
        });
        const details = jwt(response.message.id_token);
        dispatch(receiveLoginDetails(details));
        dispatch(fetchEnvList());
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectLoginDetails());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.LOGIN_DETAILS, e),
        })
      );
      return false;
    }
  };
};

// called on refresh and 401
export const getLoggedInStatus = () => {
  return async (dispatch) => {
    dispatch(requestLoggedInStatus());

    try {
      const isLoggedIn = loginIsFresh();
      dispatch(receiveLoggedInStatus(isLoggedIn));

      if (isLoggedIn) {
        const idToken = getIdToken();
        const details = jwt(idToken);
        dispatch(receiveLoginDetails(details));
        return;
      }
      throw 'Not logged in!';
    } catch (e) {
      console.log('error getting logged in status:', e);
      dispatch(rejectLoggedInStatus(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.LOGGED_IN_STATUS, e),
        })
      );
    }
  };
};
export const fetchRefreshedToken = (refreshKey) => {
  return async (dispatch) => {
    dispatch(requestRefreshedToken());
    try {
      const { response } = await getRefreshedToken(refreshKey);
      if (response) {
        updateAuthStore({
          AccessToken: response.access_token,
          ExpiresIn: response.expires_in,
          TokenType: response.token_type,
          IdToken: response.id_token,
        });
        const details = jwt(response.id_token);
        dispatch(receiveRefreshedToken(details));
      }
      return true;
    } catch (e) {
      dispatch(rejectRefreshedToken());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.REFRESHED_TOKEN, e),
        })
      );
      return false;
    }
  };
};

// called to have AWS env with their stack details option
export const fetchEnvList = () => {
  return async (dispatch) => {
    dispatch(requestEnvList());
    try {
      const { response } = await getEnvList();
      let envList = response?.message?.map((obj) => {
        return { label: obj.stackName, value: obj.stackId, envName: obj.awsAccount };
      });
      dispatch(receiveEnvList(envList));
      dispatch(fetchTablesList(DEV));
      dispatch(fetchTablesList(PROD));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(handleError());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.FETCH_ENV_LIST, e),
        })
      );
      return false;
    }
  };
};

// called to have AWS env with their stack tables names details option
export const fetchTablesList = (env) => {
  return async (dispatch) => {
    try {
      let response;
      if (env) {
        response = await getStackNamesList(env);
      }
      if (response.message) {
        let stacknames = response?.message?.map((obj) => {
          return { label: obj.stackName.replace('WCP-', ''), value: obj.tableStackName };
        });
        stacknames = tableColumnSort({ data: stacknames, sortIndex: 'label', order: SORT_ORDER.ASC });
        setStackTable({ [env]: stacknames });
      }
      return true;
    } catch (e) {
      return false;
    }
  };
};

// called on Sign out to clear the user info
export const loggedOut = () => {
  return async (dispatch) => {
    dispatch(requestLoggedOut());
  };
};

// calls when click on refresh button from users list page
export const clearAndRestoreUsers = (userDetails) => {
  return async (dispatch) => {
    dispatch(setFetchingUsers(true));
    dispatch(resetUsers([]));
    return dispatch(fetchUsers(undefined, undefined, userDetails.awsAccount, userDetails.stack));
  };
};

// calls when click on reset button from users list page
export const user_Reset = (userDetails) => {
  return async (dispatch) => {
    try {
      dispatch(resetUserLoader(true));
      const { response } = await resetUser(userDetails);
      if (response.message) {
        dispatch(resetUserLoader(false));
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, SUCCESS_MESSAGES.USER_RESET_SUCCESS),
          })
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, ERROR_MESSAGES.USER_RESET_ERROR, e),
        })
      );
    }
  };
};

// set state while fetching records
export const setFetchingUser = (status) => {
  return async (dispatch) => {
    dispatch(setFetchingUsers(status));
  };
};

// set initial loader state
export const setInitialLoads = (status) => {
  return async (dispatch) => {
    dispatch(setInitialUserLoads(status));
  };
};

// set state when selecting Stack
export const stackSelection = (selection) => {
  return async (dispatch) => {
    dispatch(setStackSelection(selection));
  };
};

// set state when selecting Account
export const stackAccount = (selection) => {
  return async (dispatch) => {
    dispatch(setStackAccount(selection));
  };
};
// reset states to its default on changing page.
export const resetEnvStackOptions = () => {
  return async (dispatch) => {
    dispatch(resetOptions());
  };
};
