import React from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'shared/ui/modals/ConfirmationModal';
import { changeDeviceEnv } from 'core/redux/deviceOperations/actions';

const evnChangeConfirmationModal = ({ device, changeDeviceEnv, onCloseModal }) => {
  return (
    <>
      <ConfirmationModal
        width={800}
        onCloseModal={onCloseModal}
        onSuccess={onCloseModal}
        onFailure={onCloseModal}
        cancelHandler={onCloseModal}
        handleRequestClose={onCloseModal}
        submitHandler={async () => {
          return await changeDeviceEnv(device);
        }}
        bodyText={`Are you sure, you want to change the stack of the device ${device.deviceId}?`}
        title={'Change in Stack'}
        svgFileName={'car'}
        iconDimensions={{ height: 23, width: 25 }}
        alt="device"
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </>
  );
};

export default connect(null, (dispatch) => ({
  changeDeviceEnv: (device) => dispatch(changeDeviceEnv(device)),
}))(evnChangeConfirmationModal);
