import { loginDetails } from 'shared/constants/api';
import { apiCall } from 'core/api/API';

export const getLoginDetails = async (code) => {
  let localhostRedirectUri = '';
  const host = window && window.location.host;
  if (!host || host.includes('localhost:')) {
    localhostRedirectUri = '?redirectUri=http://localhost:3000/redirect';
  }
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${loginDetails}/${encodeURIComponent(code)}${localhostRedirectUri}`,
    method: 'get',
  });
};
