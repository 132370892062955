import React from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { successAddNotification, successRemoveNotification } from 'core/redux/ui/actions';

export const getNotificationId = (() => {
  let nextNotificationId = 1;
  return () => {
    const notificationId = nextNotificationId;
    nextNotificationId += 1;
    return notificationId;
  };
})();

const NotificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      borderTop: 'none',
      color: '#fff',
      borderRadius: '3px',
      height: 'max-content',
    },
    info: {
      backgroundColor: '#333',
    },
    success: {
      backgroundColor: '#7ed321',
    },
    warning: {
      backgroundColor: '#5f2449',
    },
    error: {
      backgroundColor: '#c62339',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#fff',
      marginRight: '15px',
      maxWidth: '355px',
      overflowWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    success: {},
    error: {},
  },
  Dismiss: {
    DefaultStyle: {
      fontFamily: 'sans-serif',
      backgroundColor: 'none',
      color: '#ffffff',
    },
    success: {},
    error: {},
  },
};

class Notifier extends React.Component {
  notificationSystem = React.createRef();

  addNotification(notification) {
    const system = this.notificationSystem.current;
    system.addNotification({
      ...notification,
      onAdd: (notification) => {
        this.props.successAddNotification(notification.uid);
      },
      onRemove: ({ uid }) => {
        this.props.successRemoveNotification(uid);
      },
    });
  }

  componentDidUpdate(lastProps) {
    const newNotificationsToAdd = this.props.notificationsToAdd.filter(
      (notification) =>
        !lastProps.notificationsToAdd.find((lastNotification) => notification.uid === lastNotification.uid)
    );
    newNotificationsToAdd.forEach((notification) => {
      this.addNotification(notification);
    });
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} style={NotificationStyles} />;
  }
}

export default connect(
  (state) => ({
    notificationsToAdd: state.ui.notificationsToAdd,
  }),
  (dispatch) => ({
    successAddNotification: (notificationUid) => dispatch(successAddNotification({ notificationUid })),
    successRemoveNotification: (notificationUid) => dispatch(successRemoveNotification({ notificationUid })),
  })
)(Notifier);
