import { apiCall } from 'core/api/API';
import { stacks } from 'shared/constants/api';

export const getStackNamesList = async (env) => {
  let queryStringParameters = { env: env };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: stacks,
    queryStringParameters: queryStringParameters,
    method: 'get',
  });
};
