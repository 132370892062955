import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleSidebar } from 'core/redux/sidebar/actions';
import NavTrigger, { NavTriggerItem } from 'shared/styles/components/NavTrigger';
import Header, { HeaderAside, HeaderInner, WCPLogo } from 'shared/styles/components/Header';
import ProfileWidgetComponent from 'core/widgets/profile/ProfileWidgetComponent';
import wcpLogo from 'assets/images/wcpLogo@2x.png';

class HeaderComponent extends Component {
  state = {
    adminMenuOpen: false,
  };

  render() {
    const { toggleSidebar, history } = this.props;

    return (
      <Header>
        <HeaderInner>
          <HeaderAside flexAlignCenter medium>
            <NavTrigger onClick={toggleSidebar}>
              <NavTriggerItem />
              <NavTriggerItem />
              <NavTriggerItem />
            </NavTrigger>
            <WCPLogo src={wcpLogo} alt="Whelen Diagnostic Dashboard" title="Whelen Diagnostic Dashboard" />
          </HeaderAside>

          <HeaderAside fit>
            <ProfileWidgetComponent history={history} avatar="assets/images/profile-avatar@2x.png" />
          </HeaderAside>
        </HeaderInner>
      </Header>
    );
  }
}

export default connect(null, {
  toggleSidebar,
})(HeaderComponent);
