/**
 * @ External Dependencies
 */
import styled from 'styled-components';
import { borderRadius, colors, device, fontSize, fontWeight, hexWithOpacity, spacing } from '../global/variables';

// Forms style

export const FormGroup = styled.div`
  margin-bottom: ${spacing.size3};
  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.inline &&
    `
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        flex-grow:1;
        && > ${FormLabel} {
         margin-bottom:0;
         margin-right:8px;
        }
        && > ${FormControl} {
          max-width:400px;
		  min-width:250px;
        }
      `}
  ${(props) =>
    props.lastChild &&
    `
        margin-bottom: 0;
      `}
	  ${(props) =>
    props.flexGrowLg &&
    `
        flex-grow:1;
		@media ${device.md} {
			 flex-grow:0;
		 }
      `}
`;

export const FormLabel = styled.label`
  color: ${colors.midnight};
  white-space: nowrap;
  margin-bottom: 0.25rem;
  display: block;
  font-weight: ${fontWeight.fontWeightMedium};
  text-align: left;
  ${(props) =>
    props.small &&
    `
		font-size: ${fontSize.sm};
	`}
`;

export const FormValue = styled.span`
  color: ${colors.haze};
`;

export const FormControl = styled.input`
  display: block;
  width: 100%;
  width: 320px;
  max-width: 320px;
  padding: 0.4375rem 0.75rem;
  height: 35px;
  font-weight: 400;
  line-height: 1.4285714286;
  color: inherit;
  background-color: ${colors.cotton};
  background-clip: padding-box;
  border: 1px solid ${colors.metal};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px 0 0 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: inherit;
    background-color: ${colors.cotton};
    border-color: ${hexWithOpacity(colors.charcoal, 0.5)};
    outline: 0;
  }
  ${(props) =>
    props.small &&
    `
		padding: 0.375rem 0.75rem;
		height: 30px;
		font-size: 0.725rem;
  	`}

  ${(props) =>
    props.fluid &&
    `
   		max-width:100%;
  	`}

  ${(props) =>
    props.inlineInput &&
    `
	padding: 0.4375rem 0;
    border:none;
	color:${colors.muted};
	background:none;
	border-bottom:solid 1px ${colors.metal};
	&:focus {
		border-bottom: solid 1px #ccc;
		box-shadow: none;
		background: transparent;
		border-radius: 0;
	}
  `}
`;

export const FormSelect = styled.select`
  display: block;
  width: 100%;
  max-width: 320px;
  padding: 0.4375rem 2.25rem 0.4375rem 0.75rem;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath
	fill='none' stroke='%23a5a9b1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-size: 16px 12px;
  font-weight: 400;
  line-height: 1.4285714286;
  color: inherit;
  background-color: ${colors.cotton};
  background-clip: padding-box;
  border: 1px solid ${colors.metal};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  color: ${colors.haze};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) =>
    props.small &&
    `
    padding: 0.375rem 0.75rem;
    height: 30px;
    font-size: 0.725rem;
  `}
  &:focus {
    color: inherit;
    background-color: ${colors.cotton};
    border-color: ${hexWithOpacity(colors.primary, 0.5)};
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${hexWithOpacity(colors.primary, 0.25)};
  }
`;

export const Validation = styled.span`
  display: block;
  font-size: ${fontSize.sm};
  text-align: left !important;
  position: absolute;
  ${(props) =>
    props.isInvalid &&
    `
      color:${colors.cherry};
	`}
  ${(props) =>
    props.isValid &&
    `
      color:${colors.grass};
      
    `}
    ${(props) =>
    props.hidden &&
    `
        display:none;
    `}
`;

export const LinkClose = styled.span``;
export const InputGroupAddons = styled.div`
  position: relative;
  margin-bottom: 0;
  flex-grow: 1;
  svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: ${colors.muted};
  }
  ${FormControl} {
    max-width: 100%;
  }
  ${LinkClose} {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: ${colors.muted};
    width: 2rem;
  }
  ${(props) =>
    props.appendIcon &&
    `
		margin-bottom:${spacing.size1};
		${FormControl} {
			padding-left: ${spacing.size5};
		}	
	`}
`;

export const LinkAddons = styled.a``;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  ${(props) =>
    props.widthAuto &&
    `
      width:320px;
    `}

  && > ${FormControl} {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  button {
    position: relative;
    z-index: 2;
    margin-left: -2px;
    height: 40px;
    border-radius: 0 3px 3px 0 !important;
  }
  ${(props) =>
    props.btnAddons &&
    `
    position:relative;
    && > ${FormControl} {
      padding-right:${spacing.size5};
    }
    ${LinkAddons} {
      font-size:${fontSize.normal};
      position:absolute;
      cursor:pointer;
      right:${spacing.size1};
      top: 50%;
      transform: translateY(-50%);
      height:${fontSize.normal};
    }   
  `}
`;

export const InlineGroup = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: ${spacing.size1};
  }
  > div {
    width: 150px;
  }
  svg {
    cursor: pointer;
  }
`;

export const FormCheck = styled.label`
  display: inline-flex;
  cursor: pointer;
  font-weight: ${fontWeight.fontWeightMedium};
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
  ${(props) =>
    props.disabled &&
    `
		pointer-events: none;
		opacity: .5;
	`}
  ${(props) =>
    props.listCheck &&
    `
		 margin-left:${spacing.size1};
	`}
	input[type="radio"] {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: ${hexWithOpacity(colors.haze, 0.1)};
      color: ${colors.midnight};
      &:before {
        box-shadow: inset 0 0 0 0.4375em ${colors.cherry};
      }
    }
  }
  input[type='radio'] + span {
    display: flex;
    align-items: center;
    color: ${colors.haze};
    border-radius: 99em;
    transition: 0.25s ease;
    padding: ${spacing.xs} ${spacing.size1} ${spacing.xs} ${spacing.xs};
    &:hover {
      background-color: mix(${colors.cotton}, ${colors.cherry}, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: ${colors.cotton};
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em ${colors.charcoal};
    }
  }
  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
    &:checked + span {
      color: ${colors.midnight};
      &:before {
        box-shadow: inset 0 0 0 0.9em ${colors.cherry};
      }
      &:after {
        opacity: 1;
      }
    }
  }

  input[type='checkbox'] + span {
    display: flex;
    align-items: center;
    color: ${colors.haze};
    transition: 0.25s ease;
    padding: ${spacing.xs} 0;
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: ${colors.cotton};
      width: 1.5em;
      height: 1.5em;
      border-radius: ${borderRadius.xs};
      margin-right: ${spacing.sm};
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em ${colors.charcoal};
    }
    &:after {
      content: '';
      opacity: 0;
      transition: 0.25s ease;
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid ${colors.cotton};
      border-width: 0 1.5px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

export const FormCheckList = styled.div`
  padding: ${spacing.xs} 0 ${spacing.size1};
  ${(props) =>
    props.columnLayout50 &&
    `
		display:flex;
		flex-wrap:wrap;
		li {
			width:50%;
		}
    `}
  ${(props) =>
    props.columnListForm &&
    ` 
		padding: ${spacing.xs} 0 ${spacing.xs};
	`}
`;

export const InnerFormGroup = styled.div`
  display: flex;
  text-align: left;
  margin: ${spacing.size2} -${spacing.size2} -${spacing.size2};
  padding: ${spacing.size3} ${spacing.size1} 0;
  flex-wrap: wrap;
  background: ${colors.fog};
  border-top: solid 1px ${colors.metal};
  ${FormGroup} {
    margin-bottom: ${spacing.size3} !important;
    width: calc(25% - ${spacing.size2});
    max-width: 25%;
    flex-grow: 1;
    padding: 0 ${spacing.size1};
    @media ${device.md} {
      width: calc(33.33% - ${spacing.size2});
      max-width: 33.33%;
    }
    @media ${device.sm} {
      width: calc(50% - ${spacing.size2});
      max-width: 50%;
    }
    ${InlineGroup} {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
`;
