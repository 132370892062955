import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from 'rc-table';
import reactStringReplace from 'react-string-replace';
import { SORT_ORDER } from 'shared/constants/table';
import { tableColumnSort } from 'shared/utilities/table';
import { SortArrowSpan } from 'shared/styles/components/Table';
import ReactPaginate from 'react-paginate';
import { PaginationStyles, TableFilters } from 'shared/styles/components/PageList';
import { PAGINATION_OPTIONS } from 'shared/constants/pagination';
import {
  PaginationSelect,
  MatrixSelect,
  SelectMenu,
  ReactSelectDefaultStyles,
  ReactPaginationStyle,
  StyledCheckbox,
  ReactSelectOrgStyles,
} from 'shared/styles/components/SelectField';
import Searchbox from 'shared/ui/tableFilters/Searchbox';
import colors from 'shared/constants/colors';
import Select, { components as MultiSelectComponent } from 'react-select';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStackTable } from 'shared/utilities/localStore';
import { setSearchTextGlobal } from '../../../core/redux/deviceOperations/actions';
import { setUserNameSearchText } from '../../../core/redux/user/actions';
import { setPageSize } from '../../../core/redux/ui/actions';
import { getFormattedDuid, getUnformattedDuid } from 'shared/utilities/general';
import { ERROR_MESSAGES } from 'shared/constants/messages';
// custom header cell component that wraps/overwrites default table header cell (see: Table -> components prop line 56)
const SortableHeaderCell = ({ children, isSorted, sortOrder, sortIndex, style, handleSort }) => {
  const onClick = sortIndex && (() => handleSort(sortIndex));
  const sortArrow = sortOrder === SORT_ORDER.ASC ? String.fromCharCode(9650) : String.fromCharCode(9660);

  return (
    <th onClick={onClick} style={style}>
      {children}
      <SortArrowSpan isSorted={isSorted}>{sortArrow}</SortArrowSpan>
    </th>
  );
};

const SortableTable = ({
  style,
  className,
  rowClassName,
  columns,
  tableData,
  onRow,
  rowKey,
  scroll,
  children,
  components,
  emptyText,
  refresh,
  optionChangeHandler,
  stackChangeHandler,
  selectedAccount,
  selectedStack,
  envList,
  defaultSortOrder,
  hideEnvDropdown,
  showStates,
  serialDevice,
  fixedWidth,
  organizationList,
  orgChangeHandler,
  selectedOrg,
  isFetchingOrgs,
  additionalApiCallForDevice,
  reloadComponent,
  additionalSearch,
  additionalApiCallForUser,
  reloadUserList,
}) => {
  const [transformedData, updateTransformedData] = useState({
    tableData,
    index: null,
    order: null,
    isSorted: false,
  });
  const { pageSize } = useSelector((state) => state.ui);
  const [currentPageData, setCurrentPageData] = useState(tableData.slice(0, pageSize.value));
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(Math.ceil(tableData.length / pageSize.value));
  const [searchText, setSearchText] = useState('');
  const [stateOptions, setStateValuesOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [stackNames, setStackNames] = useState(
    getStackTable() ? getStackTable()[selectedAccount?.label?.toLowerCase()] : []
  );
  const { searchTextDevice, clearSearchText } = useSelector((state) => state.deviceOperations);
  const { userNameSearchText } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const [localMatch, setLocalMatch] = useState(false);
  const [isValidVSGId, setIsValidVSGId] = useState(true);

  useEffect(() => {
    setTotalPageCount(Math.ceil(transformedData.tableData.length / pageSize.value));
    handlePaginate({ selected: currentPageNum });
  }, [transformedData]);

  useEffect(() => {
    handlePaginate({ selected: 0 });
  }, [refresh]);

  // clear validation message on clear search text
  useEffect(() => {
    if (clearSearchText) {
      setIsValidVSGId(true);
    }
  }, [clearSearchText]);

  const handleSort = (sortIndex) => {
    const { tableData, order, index } = transformedData;
    let sortOrder;

    if (sortIndex === index) {
      // if we are clicking on a column thats already been sorted, we want to sort it using the reverse order
      sortOrder = order === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    } else {
      sortOrder = defaultSortOrder ? defaultSortOrder : SORT_ORDER.ASC;
    }

    const newTransformedData = tableColumnSort({
      data: tableData,
      sortIndex,
      order: sortOrder,
    });
    updateTransformedData({
      tableData: newTransformedData,
      order: sortOrder,
      index: sortIndex,
      isSorted: true,
    });
  };

  const handlePaginate = (pageData) => {
    let pNum = pageData.selected;
    let pSize = pageData.pageSize?.value ? pageData.pageSize?.value : pageSize.value;
    let items = transformedData.tableData.slice(pNum * pSize, pNum * pSize + pSize);
    if (!items.length && pNum) {
      items = transformedData.tableData.slice((pNum - 1) * pSize, (pNum - 1) * pSize + pSize);
      pNum = pNum - 1;
    }
    setCurrentPageData(items);
    setCurrentPageNum(pNum);
  };

  const searchChangeHandler = (e) => {
    if (location.pathname === '/device-operations') {
      if (getUnformattedDuid(e.target.value).length <= 23) {
        setIsValidVSGId(false);
      } else {
        setIsValidVSGId(true);
      }
      dispatch(setSearchTextGlobal(e.target.value));
    } else if (location.pathname === '/users') {
      dispatch(setUserNameSearchText(e.target.value));
    } else {
      setSearchText(e.target.value);
    }
  };

  const getIndexedColumnValue = (row, dataIndex) => {
    dataIndex = dataIndex.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    dataIndex = dataIndex.replace(/^\./, ''); // strip a leading dot
    const formattedDataIndex = dataIndex.split('.');
    for (let i = 0, n = formattedDataIndex.length; i < n; ++i) {
      const col = formattedDataIndex[i];
      if (col in row) {
        row = row[col];
      } else {
        return;
      }
    }
    return row;
  };

  const replaceByHighlightedData = (fieldData, col, highlightedData) => {
    if (!col.searchIndex) {
      fieldData[col.dataIndex] = highlightedData;
    } else {
      if (col.searchIndex.includes('.')) {
        const levels = col.searchIndex.split('.');
        fieldData[levels[0]][levels[1]] = highlightedData;
      } else {
        fieldData[col.searchIndex] = highlightedData;
      }
    }
    return fieldData;
  };

  // To search text and filter the table data
  const filterChangeHandler = (e, tableDataArr = tableData) => {
    if (location.pathname === '/device-operations') {
      setSearchTextGlobal(e.search_key);
    } else {
      setSearchText(e.search_key);
    }
    let currTransformedData = { ...transformedData };
    if (!e.search_key) {
      const newTransformedData = tableColumnSort({
        data: tableDataArr,
        sortIndex: currTransformedData.index,
        order: currTransformedData.order,
      });
      currTransformedData.tableData = newTransformedData;
      updateTransformedData(currTransformedData);
      return;
    }
    const searchValue = e.search_key?.toLowerCase();
    const filteredData = tableDataArr
      .map((row) => {
        let fieldData = { ...row };
        let flag = false;
        columns.forEach((col) => {
          if (col.searchable === true && col.dataIndex) {
            let indexedValue = getIndexedColumnValue(
              fieldData,
              col.searchIndex ? col.searchIndex : col.dataIndex
            )?.toString();
            if (indexedValue) {
              indexedValue = indexedValue.toString();
            }
            let rawVSGID = col?.rawVSGID && fieldData?.rawVSGID ? fieldData?.rawVSGID : '';
            if (rawVSGID && rawVSGID.length <= 31) {
              rawVSGID = '00000009' + rawVSGID;
            }
            /* case only applicable for device operations page */
            if (location.pathname === '/device-operations') {
              if (
                (indexedValue && indexedValue?.toLowerCase() === searchValue) ||
                rawVSGID?.toLowerCase() === searchValue ||
                fieldData?.rawVSGID.toLowerCase() === getUnformattedDuid(searchValue)
              ) {
                flag = true;
                const highlightedData = reactStringReplace(indexedValue, e.search_key, (match, fieldData) => (
                  <span key={fieldData} style={{ fontWeight: 'bolder', color: colors.midnight }}>
                    {match}
                  </span>
                ));
                fieldData = replaceByHighlightedData(fieldData, col, highlightedData);
              }
            } else if (location.pathname === '/users') {
              if (indexedValue && indexedValue?.toLowerCase() === searchValue.toLowerCase()) {
                flag = true;
                const highlightedData = reactStringReplace(indexedValue, e.search_key, (match, fieldData) => (
                  <span key={fieldData} style={{ fontWeight: 'bolder', color: colors.midnight }}>
                    {match}
                  </span>
                ));
                fieldData = replaceByHighlightedData(fieldData, col, highlightedData);
              }
            } else {
              if (
                (indexedValue && indexedValue?.toLowerCase()?.includes(searchValue)) ||
                rawVSGID?.toLowerCase()?.includes(searchValue)
              ) {
                flag = true;
                const highlightedData = reactStringReplace(indexedValue, e.search_key, (match, fieldData) => (
                  <span key={fieldData} style={{ fontWeight: 'bolder', color: colors.midnight }}>
                    {match}
                  </span>
                ));
                fieldData = replaceByHighlightedData(fieldData, col, highlightedData);
              }
            }
          }
        });
        if (flag) return fieldData;
      })
      .filter(Boolean);
    currTransformedData.tableData = filteredData;
    setCurrentPageNum(0);
    updateTransformedData(currTransformedData);
  };

  // To filter the data for the state selected
  const onChangeStateHandler = (event) => {
    let filterTableData = tableData;
    if (showStates && selectedStates && selectedStates.length) {
      filterTableData = tableData.filter((data) => {
        if (selectedStates.some((state) => state.value === data.address_state)) {
          return true;
        } else {
          return false;
        }
      });
    }
    filterChangeHandler({ search_key: event ? event.search_key : searchText, page_number: true }, filterTableData);
    setCurrentPageNum(0);
  };

  // on click search icon if on device list page
  const onClickSearchIconOnDevice = (event) => {
    dispatch(setSearchTextGlobal(event.search_key));
    if (event && event.clear) {
      // clear validation message
      setIsValidVSGId(true);
    }
    if (event && event.searchType) {
      if (getUnformattedDuid(event.search_key).length <= 23) {
        setIsValidVSGId(false);
        return false;
      } else {
        setIsValidVSGId(true);
      }
    }
    if (event && event.search_key) {
      let foundInTableData = false;
      if (tableData.length) {
        // if we are removing 0000009 it is matching with tabledata but not displaying
        if (
          tableData.some(
            (device) =>
              getFormattedDuid(device?.deviceId) === event.search_key ||
              getFormattedDuid(device?.deviceId) === getFormattedDuid(event.search_key) ||
              device?.deviceId === getUnformattedDuid(event.search_key)
          )
        ) {
          foundInTableData = true;
          filterChangeHandler({ search_key: event.search_key });
          setLocalMatch(true);
        }
      }
      if (!foundInTableData && !selectedOrg && event && event.search_key) {
        additionalApiCallForDevice({ org: null, deviceId: event.search_key });
      } else if (!foundInTableData && selectedOrg && event && event.search_key) {
        additionalApiCallForDevice({ org: selectedOrg, deviceId: event.search_key });
      }
    } else {
      if (additionalSearch) {
        reloadComponent(Math.random());
      }
      filterChangeHandler({ search_key: event.search_key });
    }
  };

  // on click search icon if on user list page
  const onClickSearchIconOnUsers = (event) => {
    dispatch(setUserNameSearchText(event.search_key));
    // if no org and search text is selected and user click on search button then load all user based on stack.
    if (event && !event.search_key && event.searchType && !selectedOrg?.value) {
      additionalApiCallForUser({ org: null, username: '' });
    } else {
      // when user paste or type in the search box make local match state to false, so that [tableData] useEffect should apply auto filter to user list
      if (event && !event.searchType) {
        setLocalMatch(false);
      }
      if (event && event.search_key) {
        let foundInTableData = false;
        if (tableData.length) {
          // search in table for exact match of username,
          if (tableData.some((user) => user?.Username.toLowerCase() === event.search_key.toLowerCase())) {
            foundInTableData = true;
            filterChangeHandler({ search_key: event.search_key });
            setLocalMatch(true);
          }
        }
        if (!foundInTableData && !selectedOrg?.value) {
          additionalApiCallForUser({ org: null, username: event.search_key });
        } else if (!foundInTableData && selectedOrg?.value) {
          additionalApiCallForUser({ org: selectedOrg, username: event.search_key });
        }
      } else {
        // fetch users with pre selected organization if last search was performed using API call.
        if (additionalSearch) {
          reloadUserList();
        }
        filterChangeHandler({ search_key: event.search_key });
      }
    }
  };

  const configureCustomHeader = (column) => {
    return {
      ...column,
      onHeaderCell: (record) => {
        const prevOnHeaderCell = column.onHeaderCell ? column.onHeaderCell(record) : {};
        if (!column.dataIndex) return prevOnHeaderCell;

        const style = prevOnHeaderCell.style ? { ...prevOnHeaderCell.style, cursor: 'pointer' } : { cursor: 'pointer' };

        return {
          ...prevOnHeaderCell,
          style,
          handleSort,
          sortIndex: record.dataIndex,
          isSorted: record.dataIndex === transformedData.index,
          sortOrder: transformedData.order,
        };
      },
    };
  };

  const accChangeHandler = (e) => {
    let stackName = getStackTable();
    setStackNames(stackName[e.label.toLowerCase()]);
    optionChangeHandler(e);
  };

  const stackHandler = (e) => {
    stackChangeHandler(e);
  };

  const updatePageSize = (e) => {
    dispatch(setPageSize(e));
    setTotalPageCount(Math.ceil(transformedData.tableData.length / e.value));
    handlePaginate({ selected: 0, pageSize: e });
  };

  // override columns and use existing/mandatory column props to pass in a sortIndex value and local handleSort function
  const sortableColumns = (() => {
    // if child column components are passed convert them to columns
    if (children) {
      return React.Children.map(
        children,
        (child) =>
          child &&
          Object.prototype.hasOwnProperty.call(child.props, 'title') &&
          Object.prototype.hasOwnProperty.call(child.props, 'dataIndex') &&
          configureCustomHeader(child.props)
      );
    }
    return columns.map((column) => configureCustomHeader(column));
  })();

  useEffect(() => {
    const { index, order } = transformedData;
    updateTransformedData({
      ...transformedData,
      tableData: tableColumnSort({ data: tableData, sortIndex: index, order }),
    });
    // To create state dropdown options
    if (showStates && tableData && tableData.length) {
      let arr = tableData.map((data) => {
        return data.address_state;
      });
      let states = [...new Set(arr)].sort();
      let options = states.map((state) => {
        return { label: state, value: state };
      });
      setStateValuesOptions(options);
    }
    if (location.pathname === '/device-operations' && localMatch) {
      filterChangeHandler({ search_key: searchTextDevice });
    } else if (location.pathname === '/users' && localMatch) {
      filterChangeHandler({ search_key: userNameSearchText });
    } else {
      onChangeStateHandler();
    }
  }, [tableData]);

  // to filter the data when states get changed
  useEffect(() => {
    if (selectedStates && showStates) {
      onChangeStateHandler();
    }
  }, [selectedStates]);

  useEffect(() => {
    // default sort by first column
    if (!transformedData.isSorted) {
      const defaultSortIndex = sortableColumns.find((c) => c.dataIndex && c.title).dataIndex;
      handleSort(defaultSortIndex);
    }
  }, [transformedData.isSorted]);

  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: '280px',
      minHeight: 'unset',
      display: 'flex',
      height: '35px',
      borderRadius: 0,
      border: '1px solid lightgrey',
      boxShadow: 'unset',
      '&:hover': {
        borderColor: 'gray',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      overflowY: 'auto',
      fontSize: '14px',
      lineHeight: '16px',
      height: '35px',
      paddingTop: 0,
      paddingBottom: 0,
      top: '-1px',
    }),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', padding: '0 6px 0 0' } : base;
    },
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: '#d6d6d6',
        color: 'black',
      },
    }),
    option: (base, state) => ({
      ...base,
      fontSize: '14px',
      backgroundColor: state.isFocused ? 'gray' : '',
    }),
  };

  const containerStyle = {
    justifyContent: serialDevice ? 'center' : 'space-between',
    gap: serialDevice ? '15px' : '',
    flexDirection: serialDevice ? 'column' : 'row',
    alignItems: serialDevice ? 'center' : 'baseline',
  };

  const tableWidth = {
    width: fixedWidth ? '500px' : '100%',
    overflow: 'auto',
    border: fixedWidth ? '1px solid #e9e9eb' : '',
    margin: fixedWidth ? 'auto' : '',
    paddingBottom: fixedWidth ? '0' : '25px',
    th: {
      padding: fixedWidth ? '15px 10px' : '0 7px 5px 7px',
      textAlign: fixedWidth ? 'center' : '',
    },
  };

  return (
    <PaginationStyles>
      <TableFilters style={containerStyle}>
        <div className="field-wrapper">
          {selectedAccount && !hideEnvDropdown ? (
            <div className="option">
              <div className="show-label">Account: </div>
              <MatrixSelect
                styles={ReactSelectDefaultStyles}
                value={selectedAccount}
                clearable={false}
                searchable={false}
                autosize={true}
                options={envList.filter(
                  (d) => (d.value === 'Dev') & (d.envName === 'Dev') || d.envName === 'Staging' || d.envName === 'Prod'
                )}
                placeholder={'Select'}
                onChange={accChangeHandler}
                isDisabled={isFetchingOrgs}
              />
            </div>
          ) : null}
          {selectedStack && !hideEnvDropdown ? (
            <div className="option">
              <div className="show-label">Stack: </div>
              <MatrixSelect
                styles={ReactSelectDefaultStyles}
                value={selectedStack}
                clearable={false}
                searchable={false}
                autosize={true}
                options={stackNames}
                placeholder={'Select'}
                onChange={stackHandler}
                isStack
                isDisabled={isFetchingOrgs}
              />
            </div>
          ) : null}
          {/* Organization dropdown */}
          {organizationList ? (
            <div className="option">
              <div className="show-label">Organization: </div>
              <Select
                styles={ReactSelectOrgStyles}
                value={selectedOrg}
                isClearable={true}
                isSearchable={true}
                autosize={true}
                options={organizationList}
                placeholder={'Please select organization'}
                onChange={orgChangeHandler}
                isStack
                isLoading={isFetchingOrgs}
              />
            </div>
          ) : null}
          {location.pathname === '/device-operations' ? (
            <Searchbox
              searchText={searchTextDevice}
              searchChangeHandler={searchChangeHandler}
              filterChangeHandler={(e) => {
                onClickSearchIconOnDevice(e);
              }}
              validationMessage={!isValidVSGId ? ERROR_MESSAGES.INVALID_VSG_ID : ''}
              devicePage={true}
              getUnformattedDuid={getUnformattedDuid}
            />
          ) : location.pathname === '/users' ? (
            <Searchbox
              searchText={userNameSearchText}
              searchChangeHandler={searchChangeHandler}
              filterChangeHandler={(e) => {
                onClickSearchIconOnUsers(e);
              }}
              userPage={true}
            />
          ) : (
            <Searchbox
              searchText={searchText}
              searchChangeHandler={searchChangeHandler}
              filterChangeHandler={(e) => {
                onChangeStateHandler(e);
              }}
            />
          )}
          {/* state dropdown */}
          {showStates && (
            <SelectMenu>
              <div className="show-label">Filter by state: </div>
              <Select
                placeholder="Select states..."
                styles={styles}
                value={selectedStates}
                isMulti
                name="states"
                options={stateOptions}
                onChange={(e) => {
                  setSelectedStates(e);
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ MultiValue: ShowStateCount, Option: DisplayCheckBox }}
              />
            </SelectMenu>
          )}
        </div>
        <div className="pagination">
          <div className="page-size-dd">
            <div className="show-label">show</div>
            <PaginationSelect
              styles={ReactPaginationStyle}
              value={pageSize}
              clearable={false}
              searchable={false}
              autosize={true}
              options={PAGINATION_OPTIONS}
              onChange={updatePageSize}
            />
          </div>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPageNum}
            nextClassName={!currentPageData.length ? 'disabled next-page' : 'next-page'}
          />
        </div>
      </TableFilters>
      <Table
        style={tableWidth}
        className={className}
        rowClassName={rowClassName}
        columns={sortableColumns}
        data={currentPageData}
        onRow={onRow}
        rowKey={rowKey}
        scroll={scroll}
        emptyText={emptyText}
        components={{
          ...components,
          header: {
            ...(components && components.header),
            ...{
              cell: (props) => {
                let styling = { ...props.style, ...tableWidth.th };
                return <SortableHeaderCell {...props} style={styling} />;
              },
            },
          },
        }}
      />
      <TableFilters style={containerStyle}>
        <div />
        <div className="pagination">
          <div className="page-size-dd">
            <div className="show-label">show</div>
            <PaginationSelect
              styles={ReactPaginationStyle}
              value={pageSize}
              clearable={false}
              searchable={false}
              autosize={true}
              options={PAGINATION_OPTIONS}
              onChange={updatePageSize}
              menuPlacement={'auto'}
            />
          </div>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPageNum}
            nextClassName={!currentPageData.length ? 'disabled next-page' : 'next-page'}
          />
        </div>
      </TableFilters>
    </PaginationStyles>
  );
};

export default connect((state) => ({
  envList: state.user.envList,
}))(SortableTable);

// display the initial selected 2 states
const ShowStateCount = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <MultiSelectComponent.MultiValue {...props} />
  ) : index === maxToShow ? (
    <StateCountBadge items={overflow} />
  ) : null;
};

// To show state count as badge
const StateCountBadge = ({ items }) => {
  const style = {
    marginLeft: 'auto',
    background: '#e6e6e6',
    borderRadius: '4px',
    fontFamily: 'sans-serif',
    fontSize: '11px',
    padding: '3px',
    order: 99,
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length} state${length !== 1 ? 's' : ''} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

// To render checkbox in dropdown options
const DisplayCheckBox = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#e6e6e6';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
    gap: '10px',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <MultiSelectComponent.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <StyledCheckbox type="checkbox" checked={isSelected} />
      {children}
    </MultiSelectComponent.Option>
  );
};
